export const descriptionForCsp = (pageDetector, customSearch, pageHeader, page) => {
  const description = customSearch.meta_description || ''
  if (!pageDetector.isFirstPage) {
    const title = pageDetector.isSingleCustomSearchPage ? `${customSearch.name}の街コン・出会い` : `${customSearch.name}の街コン（全イベント）`
    return `【${title}-${page}ページ目】${description}`
  }
  if (description && (pageDetector.isAreasCustomSearchPage || pageDetector.isRegionsCustomSearchPage || pageDetector.isAreasAreaCustomSearchPage)) {
    return `【${pageHeader}】${description}`
  }
  return description
}
