<template>
  <div>
    <template v-if="isKonkatsuPage">
      <div class="commonMargin">
        <h2 class="marginT5 marginB2 commonKonkatsuHeading">{{ title }}</h2>
        <div style="height: 45px">
          <div v-if="showPulldownMenu" class="prefectureSelect btn-pulldown-wrap">
            <select v-model="currentPrefectureSlug" class="btn-pulldown" aria-label="ランキングの都道府県" @change="fetchRankingEvents">
              <option v-for="(pref, key) in prefectures" :key="key" :value="pref.slug">
                {{ pref.name }}
              </option>
            </select>
            <icon :size="2" class="btn-pulldown-icon" fas="fa-caret-down" />
          </div>
        </div>
      </div>
    </template>
    <h2 v-else class="commonHeadingBlack paddingB3">
      {{ title }}
      <div v-if="showPulldownMenu" class="prefectureSelect btn-pulldown-wrap">
        <select v-model="currentPrefectureSlug" class="btn-pulldown" aria-label="ランキングの都道府県" @change="fetchRankingEvents">
          <option v-for="(pref, key) in prefectures" :key="key" :value="pref.slug">
            {{ pref.name }}
          </option>
        </select>
        <icon :size="2" class="btn-pulldown-icon" fas="fa-caret-down" />
      </div>
    </h2>
    <div class="contentBox" :class="{ commonMargin: isKonkatsuPage }">
      <div v-if="isLoadingRanking" class="loadingContainer alignCenter">
        <icon :size="4" fas="fa-spinner" class="fa-spin" />
      </div>
      <template v-else-if="rankingEvents && rankingEvents.length > 0">
        <div class="eventRankingList">
          <div v-for="(event, index) in rankingEvents.slice(0, 3)" :key="index" class="eventRankingList-item" @click="opixTopSpRanking(event)">
            <div>
              <client-only>
                <img v-if="index < 3" :src="rankTopIcon(event)" alt="Machicon Ranking" />
              </client-only>
              <span class="eventRankingList-item-rankingLabel">{{ event.rank }}位</span>
            </div>
            <event-list-item :event="event" :is-first-event="index === 0" />
          </div>
        </div>
        <custom-nuxt-link v-if="!$store.isPC || isKonkatsuPage" :to="moreRankingPath" class="commonButton marginT4 btn-secondary-40">
          <span class="marginR2">4位〜20位を見る</span>
        </custom-nuxt-link>
      </template>
      <p v-else class="paddingT4">該当エリアのランキングがありません。</p>
    </div>
    <custom-nuxt-link v-if="$store.isPC && !isKonkatsuPage" :to="moreRankingPath" class="commonButton marginT4 btn-secondary-40">
      <span class="marginR2">4位〜20位を見る</span>
    </custom-nuxt-link>
  </div>
</template>
<script>
import { buildQueryString } from '@/utils/url-action'
import { timeConverter, numberToCurrency } from '@/utils/converter'
import MjSelectMixin from '@/utils/mixins/MjSelectMixin'
import TicketMixin from '@/utils/mixins/TicketMixin'
import { listPrefectures } from '@/models/places'
const EventListItem = defineAsyncComponent(() => import('@/components/search/EventListItem.vue'))

const DEFAULT_PREFECTURE_SLUG = 'tokyo'
const DEFAULT_LIMIT_EVENT = 3

export default {
  components: { EventListItem },
  mixins: [MjSelectMixin, TicketMixin],
  props: {
    prefecture: { type: Object, default: null, required: false },
    area: { type: Object, default: null },
    isKonkatsuPage: { type: Boolean, default: false, required: false },
    category: {
      type: String,
      default: 'konkatsu',
      validator(value) {
        return ['konkatsu', 'party'].includes(value)
      }
    }
  },
  emits: ['click-top-sp-ranking'],
  data() {
    return {
      currentPrefectureSlug: null,
      rankingEvents: [],
      isLoadingRanking: true,
      pageDetector: null
    }
  },
  computed: {
    moreRankingPath() {
      switch (this.pageType) {
        case 'konkatsu':
          return {
            name: 'prefecture-rankings-categories',
            params: {
              prefecture: this.currentPrefectureSlug,
              category: 'konkatsu'
            }
          }
        case 'area-category':
          if (this.area) {
            return {
              name: 'area-rankings-categories',
              params: {
                area: this.area.slug,
                category: this.category
              }
            }
          } else {
            return {
              name: 'prefecture-rankings-categories',
              params: {
                prefecture: this.prefectureSlug,
                category: this.category
              }
            }
          }
        default:
          return {
            name: 'prefecture_rankings-prefecture',
            params: {
              prefecture: this.currentPrefectureSlug
            }
          }
      }
    },
    prefectures() {
      return listPrefectures().filter(prefecture => prefecture.slug !== 'other')
    },
    rankingFourthEvent() {
      return this.rankingEvents[3]
    },
    pageType() {
      if (this.isKonkatsuPage) return 'konkatsu'
      if (this.pageDetector.isAreasCategoriesPage) return 'area-category'
      return 'top'
    },
    title() {
      switch (this.pageType) {
        case 'konkatsu':
          return '婚活パーティー人気ランキング'

        case 'area-category':
          return `街コンイベントランキング（${this.area?.name || this.prefectureName}）`

        default: {
          if (!this.$store.isLoggedIn) return 'イベントランキング'
          return `${this.prefectureName}のイベントランキング`
        }
      }
    },
    showPulldownMenu() {
      switch (this.pageType) {
        case 'area-category':
          return false
        default:
          return !this.$store.isLoggedIn
      }
    },
    prefectureSlug() {
      return this.prefecture?.slug || this.prefecture?.prefecture_slug || DEFAULT_PREFECTURE_SLUG
    },
    prefectureName() {
      return this.prefecture?.name || this.prefecture?.prefecture_name || this.prefectures.find(v => v.slug === DEFAULT_PREFECTURE_SLUG).name
    }
  },
  created() {
    this.currentPrefectureSlug = this.prefectureSlug
    this.pageDetector = new PageDetector(this.$route)

    this.fetchRankingEvents()
  },
  methods: {
    numberToCurrency,
    fetchRankingEvents() {
      this.fetchRankingEventsWithLimit(DEFAULT_LIMIT_EVENT)
    },
    rankTopIcon(event) {
      return new URL(`/assets/images/svg/rank-${event.rank}.svg`, import.meta.url).href
    },
    startAtFormat(startAt) {
      const timeConverterObj = timeConverter(startAt)
      return `${timeConverterObj.fullDateTimeSingleHourWithoutYear}〜`
    },
    femaleTicket(event) {
      return event.tickets && event.tickets.female
    },
    maleTicket(event) {
      return event.tickets && event.tickets.male
    },
    areaName(event) {
      return event.area ? event.area.name : ''
    },
    eventLasts(events) {
      const last6Events = []
      for (let i = 4; i < events.length; i += 2) {
        last6Events.push(events.slice(i, i + 2))
      }
      return last6Events
    },
    eventItemClassName(length, index) {
      if (length <= 1) return 'singleEvent'
      if (index === 0) return 'evenEvent'
      return 'oddEvent'
    },
    opixTopSpRanking(event) {
      const data = {
        path: `/events/${event.code}/`,
        showedBlock: 'top sp ranking'
      }
      this.$emit('click-top-sp-ranking', data)
    },
    fetchRankingEventsWithLimit(limit) {
      const query = {
        limit: limit
      }
      switch (this.pageType) {
        case 'konkatsu':
          query.event_type = 'konkatsu'
          break
        case 'area-category':
          query.event_type = this.category
          break
      }

      let apiUrl = ''
      switch (this.pageType) {
        case 'area-category':
          apiUrl = this.area
            ? `ranking/areas/${this.area.slug}/${buildQueryString(query)}`
            : `ranking/prefectures/${this.prefectureSlug}/${buildQueryString(query)}`
          break
        default:
          apiUrl = `ranking/prefectures/${this.currentPrefectureSlug}/${buildQueryString(query)}`
      }

      this.$store
        .dispatch('apiGetV2', apiUrl)
        .then(response => {
          this.rankingEvents = response.data.ranking
        })
        .catch(e => {
          const errorCode = parseInt(e.response && e.response.status)
          if (errorCode === 404) return []
        })
        .finally(() => {
          this.isLoadingRanking = false
        })
    },
    minPriceTicket(ticket) {
      if (parseInt(ticket.min_price_recommend_events) >= 0) {
        return `${numberToCurrency(ticket.min_price_recommend_events)}${ticket.has_ticket_price_from ? '〜' : ''}`
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.contentBox {
  background: $white;
  padding: 1.6rem 1rem;
  border-radius: 3px;

  @include pc {
    margin: 1.6rem;
  }

  .loadingContainer {
    padding-top: 25%;
    height: 600px;
  }
}

.eventRankingList {
  @include pc {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &-item {
    margin-bottom: 1.5rem;

    @include pc {
      margin-bottom: 0;
      width: calc((100% - 3rem) / 3);
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-c6;

      @include pc {
        border: 0;
      }
    }

    &:last-child {
      margin-bottom: -0.5rem;
    }

    &.itemLast {
      border-bottom: 1px solid $gray-c6;
      margin-bottom: 1rem;
    }

    &-img {
      max-height: 180px;
      border: 1px solid $gray-c6;

      &.fixedDimension {
        width: 100%;
        height: auto;
        object-fit: scale-down;
      }
    }

    &-rankingLabel {
      position: relative;
      top: -3px;
      color: $black-1a;
      font-size: 1.6rem;
      font-weight: bold;
    }

    &-area {
      @include textEllipsis;
      display: block;
      float: left;
      min-width: 60px;
      max-width: 100px;
      padding: 0.1rem 0.6rem;
      margin-right: 0.6rem;
      border-radius: 3px;
      color: $white;
      font-size: 1.2rem;
      text-align: center;
      background-color: $gray-55;
    }

    &-datetime {
      font-weight: bold;
      font-size: 1.2rem;
      color: $black-1a;
    }

    :deep(.eventItem) {
      box-shadow: 0 0;
      padding: 0;

      .eventItem-head {
        padding: 0 1rem;
      }
    }
  }
}

.prefectureSelect {
  float: right;
  position: relative;
}

.commonButton {
  width: 47rem;
  margin-left: auto;
  margin-right: auto;
}

.commonMargin {
  margin-left: $spCommonMargin;
  margin-right: $spCommonMargin;
}
</style>
