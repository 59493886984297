export const CACHE_COMMON_FOOTER = {
  name: 'CommonFooter',
  time: 24 * 60 * 60 * 1000 // 24h
}

export const CACHE_COMMON_BOTTOM_LINK = {
  name: 'CommonBottomLinks',
  time: 24 * 60 * 60 * 1000 // 24h
}

export const CACHE_COUPLINK_MOBILE_APP = {
  name: 'CouplinkMobileApps',
  time: 24 * 60 * 60 * 1000 // 24h
}

export const CACHE_FOOTER_TAB = {
  name: 'FooterTab',
  time: 1 * 60 * 60 * 1000 // 1 hour
}

export const CACHE_MACHICON_DESCRIPTION = {
  name: 'MachiconDescription',
  time: 24 * 60 * 60 * 1000 // 24 hours
}

export const BREADCRUMB = {
  name: 'Breadcrumb',
  time: 60 * 60 * 1000 // 1 hours
}

export const PUBLIC_RELATIONS = {
  name: 'PublicRelations',
  time: 60 * 60 * 1000 // 1 hours
}

export const CATEGORY_AND_TAG_LIST = {
  name: 'CategoryAndTagList',
  time: 12 * 60 * 60 * 1000 // 12 hours
}
