export default {
  methods: {
    status(ticket) {
      return ticket.status ?? ticket.sale_status // JSON ticket sale status can be only status too. Check _ticket.json.jbuilder
    },
    ticketStatusIcon(ticket) {
      return this.status(ticket) === 'adjusting' ? 'fa-envelope' : '' // Use switch case for multiple cases
    },
    ticketStatus(ticket) {
      return this.status(ticket)
    },
    ticketStatusClass(ticket) {
      return this.status(ticket) === '1-49' ? 'highlighted' : '' // Use switch case for multiple cases
    }
  }
}
