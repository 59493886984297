const isPastDateRedirect = (year, month, day, currentDate) => {
  if (!year) return false

  month = month || 12
  day = day || new Date(year, month, 0).getDate()

  const targetDate = new Date(year, month - 1, day)
  targetDate.setHours(0, 0, 0, 0)
  currentDate.setHours(0, 0, 0, 0)
  return targetDate < currentDate
}

export default defineNuxtRouteMiddleware(to => {
  const currentDate = new Date()

  if (isPastDateRedirect(to.params.year, to.params.month, to.params.day, currentDate)) {
    navigateTo(import.meta.env.ROOT_URL, { redirectCode: 301, external: true })
  }
})
