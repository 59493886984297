export const AREAS = [
  {
    id: 1,
    name: '札幌駅',
    slug: 'sapporo-sta',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 2,
    name: 'すすきの',
    slug: 'susukino',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 3,
    name: '札幌市内その他',
    slug: 'sapporocity-other',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 227,
    name: '札幌市東区',
    slug: 'higashi',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 228,
    name: '札幌市南区',
    slug: 'minami',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 229,
    name: '白石区',
    slug: 'shiroishi',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0101'
  },
  {
    id: 4,
    name: '函館市',
    slug: 'hakodate',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0103'
  },
  {
    id: 5,
    name: '旭川市',
    slug: 'asahikawa',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0102'
  },
  {
    id: 6,
    name: '釧路市',
    slug: 'kushiro',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 7,
    name: '帯広市',
    slug: 'obihiro',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 8,
    name: '北海道その他',
    slug: 'hokkaido-other',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 230,
    name: '北見市',
    slug: 'kitami-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 231,
    name: '苫小牧市',
    slug: 'tomakomai-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 232,
    name: '室蘭市',
    slug: 'muroran-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 233,
    name: '千歳市',
    slug: 'chitose-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 234,
    name: '岩見沢市',
    slug: 'iwamizawa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 235,
    name: '小樽市',
    slug: 'otaru-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 236,
    name: '恵庭市',
    slug: 'eniwa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 237,
    name: '稚内市',
    slug: 'wakkanai-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 238,
    name: '網走市',
    slug: 'abashiri-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 239,
    name: '滝川市',
    slug: 'takikawa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 240,
    name: '江別市',
    slug: 'ebetsu-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 241,
    name: '伊達市',
    slug: 'date-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 242,
    name: '中標津町',
    slug: 'nakashibetsu-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 243,
    name: '名寄市',
    slug: 'nayoro-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 244,
    name: '富良野市',
    slug: 'furano-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 245,
    name: '北広島市',
    slug: 'kitahiroshima-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 246,
    name: '広尾町',
    slug: 'hiro-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 247,
    name: '紋別市',
    slug: 'mombetsu-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 248,
    name: '根室市',
    slug: 'nemuro-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 249,
    name: '登別市',
    slug: 'noboribetsu-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 250,
    name: '留萌市',
    slug: 'rumoi-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 251,
    name: '倶知安町',
    slug: 'kutchan-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 252,
    name: '岩内町',
    slug: 'iwanai-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 253,
    name: '美幌町',
    slug: 'bihoro-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 254,
    name: 'ニセコ町',
    slug: 'niseko-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 255,
    name: '砂川市',
    slug: 'sunagawa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 256,
    name: '石狩市',
    slug: 'ishikari-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 257,
    name: '美唄市',
    slug: 'bibai-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 258,
    name: '白老町',
    slug: 'shiraoi-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 259,
    name: '士別市',
    slug: 'shibetsu-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 260,
    name: '深川市',
    slug: 'fukagawa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 261,
    name: '芦別市',
    slug: 'ashibetsu-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 262,
    name: '美瑛町',
    slug: 'biei-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 263,
    name: '八雲町',
    slug: 'yakumo-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 264,
    name: '清水町',
    slug: 'shimizu-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 265,
    name: '洞爺湖町',
    slug: 'toyako-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 266,
    name: '北斗市',
    slug: 'hokuto-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 267,
    name: '三笠市',
    slug: 'mikasa-city',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 268,
    name: '新ひだか町',
    slug: 'shinhidaka-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 269,
    name: '知内町',
    slug: 'shiriuchi-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 270,
    name: '由仁町',
    slug: 'yuni-town',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 271,
    name: '二股駅',
    slug: 'futamata-sta',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido',
    area_group_slug: 'ag0199'
  },
  {
    id: 15,
    name: '仙台市',
    slug: 'sendai',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0401'
  },
  {
    id: 16,
    name: '石巻市',
    slug: 'ishinomaki',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 17,
    name: '宮城県その他',
    slug: 'miyagi-other',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 294,
    name: '大崎市',
    slug: 'osaki-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 295,
    name: '気仙沼市',
    slug: 'kesennuma-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 296,
    name: '登米市',
    slug: 'tome-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 297,
    name: '東松島市',
    slug: 'higashimatsushima-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 298,
    name: '栗原市',
    slug: 'kurihara-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 299,
    name: '名取市',
    slug: 'natori-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 300,
    name: '角田市',
    slug: 'kakuda-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 301,
    name: '岩沼市',
    slug: 'iwanuma-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 302,
    name: '多賀城市',
    slug: 'tagajo-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 303,
    name: '松島町',
    slug: 'matsushima-town',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 304,
    name: '白石市',
    slug: 'shiroishi-city',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 305,
    name: '亘理町',
    slug: 'watari-town',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 306,
    name: '涌谷町',
    slug: 'wakuya-town',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 307,
    name: '丸森町',
    slug: 'marumori-town',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 308,
    name: '女川町',
    slug: 'onagawa-town',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi',
    area_group_slug: 'ag0499'
  },
  {
    id: 21,
    name: '山形市',
    slug: 'yamagata',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 22,
    name: '鶴岡市',
    slug: 'tsuruoka',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 23,
    name: '山形県その他',
    slug: 'yamagata-other',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 317,
    name: '朝日町',
    slug: 'asahi-town',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 318,
    name: '酒田市',
    slug: 'sakata-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 319,
    name: '米沢市',
    slug: 'yonezawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 320,
    name: '庄内町',
    slug: 'shonai-town',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 321,
    name: '新庄市',
    slug: 'shinjo-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 322,
    name: '天童市',
    slug: 'tendo-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 323,
    name: '寒河江市',
    slug: 'sagae-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 324,
    name: '東根市',
    slug: 'higashine-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 325,
    name: '長井市',
    slug: 'nagai-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 326,
    name: '南陽市',
    slug: 'nanyo-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 327,
    name: '小国町',
    slug: 'oguni-town',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 328,
    name: '高畠町',
    slug: 'takahata-town',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 329,
    name: '尾花沢市',
    slug: 'obanazawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 330,
    name: '上山市',
    slug: 'kaminoyama-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 331,
    name: '村山市',
    slug: 'murayama-city',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 332,
    name: '河北町',
    slug: 'kahoku-town',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata',
    area_group_slug: 'ag0600'
  },
  {
    id: 12,
    name: '盛岡市',
    slug: 'morioka',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 13,
    name: '一関市',
    slug: 'ichinoseki',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 14,
    name: '岩手県その他',
    slug: 'iwate-other',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 279,
    name: '北上市',
    slug: 'kitakami-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 280,
    name: '宮古市',
    slug: 'miyako-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 281,
    name: '奥州市',
    slug: 'oshu-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 282,
    name: '花巻市',
    slug: 'hanamaki-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 283,
    name: '大船渡市',
    slug: 'ofunato-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 284,
    name: '釜石市',
    slug: 'kamaishi-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 285,
    name: '久慈市',
    slug: 'kuji-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 286,
    name: '山田町',
    slug: 'yamada-town',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 287,
    name: '二戸市',
    slug: 'ninohe-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 288,
    name: '遠野市',
    slug: 'tono-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 289,
    name: '八幡平市',
    slug: 'hachimantai-city',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 290,
    name: '平泉町',
    slug: 'hiraizumi-town',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 291,
    name: '紫波町',
    slug: 'shiwa-town',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 292,
    name: '軽米町',
    slug: 'karumai-town',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 293,
    name: '滝沢駅',
    slug: 'takizawa-sta',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate',
    area_group_slug: 'ag0300'
  },
  {
    id: 24,
    name: '郡山市',
    slug: 'koriyama',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 25,
    name: 'いわき市',
    slug: 'iwaki',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 26,
    name: '福島県その他',
    slug: 'fukushima-other',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 333,
    name: '福島市',
    slug: 'fukushima-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 334,
    name: '会津若松市',
    slug: 'aizuwakamatsu-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 335,
    name: '白河市',
    slug: 'shirakawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 336,
    name: '相馬市',
    slug: 'soma-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 337,
    name: '南相馬市',
    slug: 'minamisoma-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 338,
    name: '須賀川市',
    slug: 'sukagawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 339,
    name: '二本松市',
    slug: 'nihommatsu-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 340,
    name: '喜多方市',
    slug: 'kitakata-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 341,
    name: '本宮市',
    slug: 'motomiya-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 342,
    name: '田村市',
    slug: 'tamura-city',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 343,
    name: '会津美里町',
    slug: 'aizumisato-town',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima',
    area_group_slug: 'ag0700'
  },
  {
    id: 18,
    name: '秋田市',
    slug: 'akita',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 19,
    name: '横手市',
    slug: 'yokote',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 20,
    name: '秋田県その他',
    slug: 'akita-other',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 309,
    name: '大館市',
    slug: 'odate-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 310,
    name: '大仙市',
    slug: 'daisen-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 311,
    name: '能代市',
    slug: 'noshiro-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 312,
    name: '湯沢市',
    slug: 'yuzawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 313,
    name: '鹿角市',
    slug: 'kazuno-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 314,
    name: '由利本荘市',
    slug: 'yurihonjo-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 315,
    name: '北秋田市',
    slug: 'kitaakita-city',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 316,
    name: '美郷町',
    slug: 'misato-town',
    region_slug: 'tohoku',
    prefecture_slug: 'akita',
    area_group_slug: 'ag0500'
  },
  {
    id: 9,
    name: '青森市',
    slug: 'aomori',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 10,
    name: '八戸市',
    slug: 'hachinohe',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 11,
    name: '青森県その他',
    slug: 'aomori-other',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 226,
    name: '弘前市',
    slug: 'hirosaki',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 272,
    name: 'むつ市',
    slug: 'mutsu-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 273,
    name: '十和田市',
    slug: 'towada-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 274,
    name: '三沢市',
    slug: 'misawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 275,
    name: '五所川原市',
    slug: 'goshogawara-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 276,
    name: 'おいらせ町',
    slug: 'oirase-town',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 277,
    name: 'つがる市',
    slug: 'tsugaru-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 278,
    name: '平川市',
    slug: 'hirakawa-city',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori',
    area_group_slug: 'ag0200'
  },
  {
    id: 46,
    name: '丸の内',
    slug: 'marunouchi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1301'
  },
  {
    id: 47,
    name: '大手町',
    slug: 'otemachi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1301'
  },
  {
    id: 52,
    name: '日本橋',
    slug: 'nihonbashi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1301'
  },
  {
    id: 53,
    name: '八重洲/東京駅',
    slug: 'yaesu',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1301'
  },
  {
    id: 48,
    name: '有楽町',
    slug: 'yurakucho',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1302'
  },
  {
    id: 54,
    name: '銀座',
    slug: 'ginza',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1302'
  },
  {
    id: 49,
    name: '飯田橋',
    slug: 'iidabashi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1310'
  },
  {
    id: 71,
    name: '神楽坂',
    slug: 'kagurazaka',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1310'
  },
  {
    id: 50,
    name: '秋葉原',
    slug: 'akihabara',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1312'
  },
  {
    id: 51,
    name: '神田',
    slug: 'kanda',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1312'
  },
  {
    id: 55,
    name: '六本木',
    slug: 'roppongi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1306'
  },
  {
    id: 56,
    name: '赤坂',
    slug: 'akasaka',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1306'
  },
  {
    id: 57,
    name: '青山',
    slug: 'aoyama',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1308'
  },
  {
    id: 66,
    name: '表参道',
    slug: 'omotesando',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1308'
  },
  {
    id: 67,
    name: '原宿',
    slug: 'harajuku',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1308'
  },
  {
    id: 58,
    name: '新宿',
    slug: 'shinjuku',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1304'
  },
  {
    id: 59,
    name: '上野',
    slug: 'ueno',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1311'
  },
  {
    id: 60,
    name: '浅草',
    slug: 'asakusa',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1311'
  },
  {
    id: 61,
    name: '品川区',
    slug: 'shinagawa',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1307'
  },
  {
    id: 62,
    name: '目黒区',
    slug: 'meguro',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1307'
  },
  {
    id: 63,
    name: '中目黒',
    slug: 'nakameguro',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1309'
  },
  {
    id: 64,
    name: '自由が丘',
    slug: 'jiyugaoka',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1309'
  },
  {
    id: 68,
    name: '恵比寿',
    slug: 'ebisu',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1309'
  },
  {
    id: 69,
    name: '代官山',
    slug: 'daikanyama',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1309'
  },
  {
    id: 65,
    name: '渋谷区',
    slug: 'shibuya',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1305'
  },
  {
    id: 70,
    name: '池袋',
    slug: 'ikebukuro',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1303'
  },
  {
    id: 72,
    name: '八王子',
    slug: 'hachioji',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1315'
  },
  {
    id: 73,
    name: '立川',
    slug: 'tachikawa',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1315'
  },
  {
    id: 74,
    name: '吉祥寺',
    slug: 'kichijoji',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1313'
  },
  {
    id: 75,
    name: '町田',
    slug: 'machida',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1314'
  },
  {
    id: 76,
    name: '東京都その他',
    slug: 'tokyo-other',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 492,
    name: '北区',
    slug: 'kita-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 493,
    name: '大田区',
    slug: 'ota-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 494,
    name: '中野区',
    slug: 'nakano-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 495,
    name: '調布市',
    slug: 'chofu-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 496,
    name: '板橋区',
    slug: 'itabashi-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 497,
    name: '足立区',
    slug: 'adachi-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 498,
    name: '練馬区',
    slug: 'nerima-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 499,
    name: '府中市',
    slug: 'fuchu-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 500,
    name: '世田谷区',
    slug: 'setagaya-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 501,
    name: '国分寺市',
    slug: 'kokubunji-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 502,
    name: '江戸川区',
    slug: 'edogawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 503,
    name: '葛飾区',
    slug: 'katsushika-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 504,
    name: '青梅市',
    slug: 'ome-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 505,
    name: '杉並区',
    slug: 'suginami-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 506,
    name: '三鷹市',
    slug: 'mitaka-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 507,
    name: '国立市',
    slug: 'kunitachi-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 508,
    name: '錦糸町',
    slug: 'sumida-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 509,
    name: '小平市',
    slug: 'kodaira-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 510,
    name: '日野市',
    slug: 'hino-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 511,
    name: '江東区',
    slug: 'koto-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 512,
    name: '文京区',
    slug: 'bunkyo-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 513,
    name: '東村山市',
    slug: 'higashimurayama-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 514,
    name: 'あきる野市',
    slug: 'akiruno-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 515,
    name: '東大和市',
    slug: 'higashiyamato-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 516,
    name: '清瀬市',
    slug: 'kiyose-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 517,
    name: '昭島市',
    slug: 'akishima-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 518,
    name: '荒川区',
    slug: 'arakawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 519,
    name: '千代田区',
    slug: 'chiyoda-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 520,
    name: '武蔵野市',
    slug: 'musashino-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 521,
    name: '多摩市',
    slug: 'tama-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 522,
    name: '福生市',
    slug: 'fussa-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 523,
    name: '西東京市',
    slug: 'nishitokyo-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 524,
    name: '東久留米市',
    slug: 'higashikurume-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 525,
    name: '小金井市',
    slug: 'koganei-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 526,
    name: '羽村市',
    slug: 'hamura-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 527,
    name: '狛江市',
    slug: 'komae-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 528,
    name: '豊島区',
    slug: 'toshima-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 529,
    name: '台東区',
    slug: 'taito-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 530,
    name: '稲城市',
    slug: 'inagi-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 531,
    name: '中央区',
    slug: 'chuo-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 532,
    name: '奥多摩町',
    slug: 'okutama-town',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 533,
    name: '武蔵村山市',
    slug: 'musashimurayama-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 534,
    name: '瑞穂町',
    slug: 'mizuho-town',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 535,
    name: '蒲田駅',
    slug: 'kamata-sta',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 1087,
    name: '下北沢',
    slug: 'shimokitazawa',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 1089,
    name: '港区',
    slug: 'minato-city',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 1090,
    name: '浜松町',
    slug: 'hamamatsutyo',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 1091,
    name: '麻布十番',
    slug: 'azabujuban',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 1092,
    name: '五反田',
    slug: 'gotanda',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1307'
  },
  {
    id: 1093,
    name: '代々木',
    slug: 'yoyogi',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo',
    area_group_slug: 'ag1399'
  },
  {
    id: 77,
    name: '横浜駅周辺',
    slug: 'yokohama-sta',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1401'
  },
  {
    id: 78,
    name: '関内・桜木町・みなとみらい',
    slug: 'minatomirai',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1401'
  },
  {
    id: 79,
    name: '元町・中華街・石川町',
    slug: 'motomachi',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1401'
  },
  {
    id: 80,
    name: '横浜市内その他',
    slug: 'yokohamacity-other',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1401'
  },
  {
    id: 81,
    name: '川崎市',
    slug: 'kawasaki',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1402'
  },
  {
    id: 82,
    name: '相模原市',
    slug: 'sagamihara',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1403'
  },
  {
    id: 83,
    name: '鎌倉市',
    slug: 'kamakura',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1404'
  },
  {
    id: 84,
    name: '藤沢市',
    slug: 'fujisawa',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1404'
  },
  {
    id: 85,
    name: '小田原市',
    slug: 'odawara',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1405'
  },
  {
    id: 86,
    name: '神奈川県その他',
    slug: 'kanagawa-other',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 536,
    name: '大井町',
    slug: 'oimachi',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 537,
    name: '横須賀市',
    slug: 'yokosuka-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 538,
    name: '厚木市',
    slug: 'atsugi-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 539,
    name: '平塚市',
    slug: 'hiratsuka-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 540,
    name: '海老名市',
    slug: 'ebina-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 541,
    name: '茅ヶ崎市',
    slug: 'chigasaki-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 542,
    name: '秦野市',
    slug: 'hadano-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 543,
    name: '伊勢原市',
    slug: 'isehara-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 544,
    name: '座間市',
    slug: 'zama-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 545,
    name: '箱根町',
    slug: 'hakone-town',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 546,
    name: '南足柄市',
    slug: 'minamiashigara-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 547,
    name: '三浦市',
    slug: 'miura-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 548,
    name: '綾瀬市',
    slug: 'ayase-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 549,
    name: '逗子市',
    slug: 'zushi-city',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 550,
    name: '葉山町',
    slug: 'hayama-town',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 551,
    name: '湯河原町',
    slug: 'yugawara-town',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 552,
    name: '愛川町',
    slug: 'aikawa-town',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa',
    area_group_slug: 'ag1499'
  },
  {
    id: 41,
    name: '千葉市',
    slug: 'chiba',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1201'
  },
  {
    id: 42,
    name: '幕張',
    slug: 'makuhari',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1201'
  },
  {
    id: 43,
    name: '船橋市',
    slug: 'funabashi',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 44,
    name: '柏市',
    slug: 'kashiwa',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 45,
    name: '千葉県その他',
    slug: 'chiba-other',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 225,
    name: '成田市',
    slug: 'narita',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 457,
    name: '木更津市',
    slug: 'kisarazu-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 458,
    name: '松戸市',
    slug: 'matsudo-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 459,
    name: '市原市',
    slug: 'ichihara-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 460,
    name: '茂原市',
    slug: 'mobara-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 461,
    name: '市川市',
    slug: 'ichikawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 462,
    name: '浦安市',
    slug: 'urayasu-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 463,
    name: '銚子市',
    slug: 'choshi-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 464,
    name: '君津市',
    slug: 'kimitsu-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 465,
    name: '鴨川市',
    slug: 'kamogawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 466,
    name: '佐倉市',
    slug: 'sakura_city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 467,
    name: '館山市',
    slug: 'tateyama-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 468,
    name: '八千代市',
    slug: 'yachiyo-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 469,
    name: '野田市',
    slug: 'noda-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 470,
    name: '我孫子市',
    slug: 'abiko-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 471,
    name: '南房総市',
    slug: 'minamiboso-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 472,
    name: '香取市',
    slug: 'katori-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 473,
    name: '流山市',
    slug: 'nagareyama-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 474,
    name: '印西市',
    slug: 'inzai-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 475,
    name: '東金市',
    slug: 'togane-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 476,
    name: '習志野市',
    slug: 'narashino-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 477,
    name: '八街市',
    slug: 'yachimata-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 478,
    name: '匝瑳市',
    slug: 'sosa-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 479,
    name: '富里市',
    slug: 'tomisato-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 480,
    name: '山武市',
    slug: 'sammu-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 481,
    name: '四街道市',
    slug: 'yotsukaido-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 482,
    name: 'いすみ市',
    slug: 'isumi-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 483,
    name: '勝浦市',
    slug: 'katsura-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 484,
    name: '富津市',
    slug: 'futtsu-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 485,
    name: '白井市',
    slug: 'shiroi-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 486,
    name: '長南町',
    slug: 'chonam-town',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 487,
    name: '袖ケ浦市',
    slug: 'sodegaura-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 488,
    name: '神崎町',
    slug: 'kozaki-town',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 489,
    name: '東庄町',
    slug: 'tonosho-town',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 490,
    name: '鎌ケ谷市',
    slug: 'kamagaya-city',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 491,
    name: '鋸南町',
    slug: 'kyonam-town',
    region_slug: 'kanto',
    prefecture_slug: 'chiba',
    area_group_slug: 'ag1299'
  },
  {
    id: 36,
    name: '大宮区',
    slug: 'omiya',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1101'
  },
  {
    id: 37,
    name: '浦和区',
    slug: 'urawa',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1101'
  },
  {
    id: 38,
    name: 'さいたま市内その他',
    slug: 'saitamacity-other',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1101'
  },
  {
    id: 39,
    name: '川越市',
    slug: 'kawagoe',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 40,
    name: '埼玉県その他',
    slug: 'saitama-other',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 224,
    name: '熊谷市',
    slug: 'kumagaya',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 409,
    name: '和光市',
    slug: 'wako-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 410,
    name: '所沢市',
    slug: 'tokorozawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 411,
    name: '越谷市',
    slug: 'koshigaya-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 412,
    name: '深谷市',
    slug: 'fukaya-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 413,
    name: '本庄市',
    slug: 'honjo-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 414,
    name: '久喜市',
    slug: 'kuki-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 415,
    name: '秩父市',
    slug: 'chichibu-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 416,
    name: '春日部市',
    slug: 'kasukabe-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 417,
    name: '川口市',
    slug: 'kawaguchi-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 418,
    name: '草加市',
    slug: 'soka-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 419,
    name: '入間市',
    slug: 'iruma-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 420,
    name: '上尾市',
    slug: 'ageo-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 421,
    name: '鴻巣市',
    slug: 'konosu-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 422,
    name: '加須市',
    slug: 'kazo-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 423,
    name: '東松山市',
    slug: 'higashimatsuyama-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 424,
    name: '行田市',
    slug: 'gyoda-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 425,
    name: '飯能市',
    slug: 'hanno-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 426,
    name: '坂戸市',
    slug: 'sakado-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 427,
    name: '羽生市',
    slug: 'hanyu-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 428,
    name: '狭山市',
    slug: 'sayama-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 429,
    name: '八潮市',
    slug: 'yashio-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 430,
    name: '日高市',
    slug: 'hidaka-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 431,
    name: '朝霞市',
    slug: 'asaka-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 432,
    name: '蕨市',
    slug: 'warabi-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 433,
    name: '三郷市',
    slug: 'misato-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 434,
    name: '新座市',
    slug: 'niiza-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 435,
    name: '戸田市',
    slug: 'toda-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 436,
    name: 'ふじみ野市',
    slug: 'fujimino-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 437,
    name: '桶川市',
    slug: 'okegawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 438,
    name: '北本市',
    slug: 'kitamoto-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 439,
    name: '富士見市',
    slug: 'fujimi-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 440,
    name: '鶴ヶ島市',
    slug: 'tsurugashima-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 441,
    name: '志木市',
    slug: 'shiki-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 442,
    name: '蓮田市',
    slug: 'hasuda-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 443,
    name: '幸手市',
    slug: 'satte-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 444,
    name: '嵐山町',
    slug: 'ranzam-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 445,
    name: '小川町',
    slug: 'ogawa-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 446,
    name: '吉川市',
    slug: 'yoshikawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 447,
    name: '白岡市',
    slug: 'shiraoka-city',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 448,
    name: '寄居町',
    slug: 'yorii-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 449,
    name: 'ときがわ町',
    slug: 'tokigawa-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 450,
    name: '横瀬町',
    slug: 'yokoze-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 451,
    name: '小鹿野町',
    slug: 'ogano-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 452,
    name: '宮代町',
    slug: 'miyashiro-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 453,
    name: '毛呂山町',
    slug: 'moroyama-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 454,
    name: '川島町',
    slug: 'kawajima-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 455,
    name: '松伏町',
    slug: 'matsubushi-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 456,
    name: '伊奈町',
    slug: 'ina-town',
    region_slug: 'kanto',
    prefecture_slug: 'saitama',
    area_group_slug: 'ag1199'
  },
  {
    id: 33,
    name: '前橋市',
    slug: 'maebashi',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 34,
    name: '高崎市',
    slug: 'takasaki',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 35,
    name: '群馬県その他',
    slug: 'gunma-other',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 223,
    name: '太田市',
    slug: 'ota',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 393,
    name: 'みどり市',
    slug: 'midori-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 394,
    name: '伊勢崎市',
    slug: 'isesaki-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 395,
    name: '三河町',
    slug: 'mikawa-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 396,
    name: '館林市',
    slug: 'tatebayashi-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 397,
    name: '桐生市',
    slug: 'kiryu-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 398,
    name: '沼田市',
    slug: 'numata-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 399,
    name: '渋川市',
    slug: 'shibukawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 400,
    name: '大泉町',
    slug: 'oizumi-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 401,
    name: '藤岡市',
    slug: 'fujioka-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 402,
    name: '富岡市',
    slug: 'tomioka-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 403,
    name: '安中市',
    slug: 'annaka-city',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 404,
    name: '中之条町',
    slug: 'nakanojo-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 405,
    name: '千代田町',
    slug: 'chiyoda-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 406,
    name: '玉村町',
    slug: 'tamamura-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 407,
    name: '邑楽町',
    slug: 'ora-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 408,
    name: '明和町',
    slug: 'meiwa-town',
    region_slug: 'kanto',
    prefecture_slug: 'gunma',
    area_group_slug: 'ag1000'
  },
  {
    id: 30,
    name: '宇都宮市',
    slug: 'utsunomiya',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 31,
    name: '小山市',
    slug: 'oyama',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 32,
    name: '栃木県その他',
    slug: 'tochigi-other',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 376,
    name: 'さくら市',
    slug: 'sakura',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 377,
    name: '那須町',
    slug: 'nasu-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 378,
    name: '足利市',
    slug: 'ashikaga-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 379,
    name: '佐野市',
    slug: 'sano-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 380,
    name: '大田原市',
    slug: 'otawara-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 381,
    name: '那須塩原市',
    slug: 'nasushiobara-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 382,
    name: '真岡市',
    slug: 'moka-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 383,
    name: '日光市',
    slug: 'nikko-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 384,
    name: '鹿沼市',
    slug: 'kanuma-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 385,
    name: '矢板市',
    slug: 'yaita-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 386,
    name: '下野市',
    slug: 'shimotsuke-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 387,
    name: '壬生町',
    slug: 'mibu-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 388,
    name: '芳賀町',
    slug: 'haga-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 389,
    name: '茂木町',
    slug: 'motegi-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 390,
    name: '高根沢町',
    slug: 'takanezawa-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 391,
    name: '益子町',
    slug: 'mashiko-town',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 392,
    name: '那須烏山市',
    slug: 'nasukarasuyama-city',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi',
    area_group_slug: 'ag0900'
  },
  {
    id: 27,
    name: '水戸市',
    slug: 'mito',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 28,
    name: '日立市',
    slug: 'hitachi',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 29,
    name: '茨城県その他',
    slug: 'ibaraki-other',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 220,
    name: 'つくば市',
    slug: 'tsukuba',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 344,
    name: 'かすみがうら市',
    slug: 'kasumigaura-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 345,
    name: '神栖市',
    slug: 'kamisu-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 346,
    name: '古河市',
    slug: 'koga',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 347,
    name: '土浦市',
    slug: 'tsuchiura-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 348,
    name: 'ひたちなか市',
    slug: 'hitachinaka-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 349,
    name: '鹿嶋市',
    slug: 'kashima-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 350,
    name: '守谷市',
    slug: 'moriya-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 351,
    name: '取手市',
    slug: 'toride-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 352,
    name: '北茨城市',
    slug: 'kitaibaraki-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 353,
    name: '筑西市',
    slug: 'chikusei-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 354,
    name: '牛久市',
    slug: 'ushiku-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 355,
    name: '結城市',
    slug: 'yuki-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 356,
    name: '笠間市',
    slug: 'kasama-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 357,
    name: '石岡市',
    slug: 'ishioka-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 358,
    name: '潮来市',
    slug: 'itako-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 359,
    name: '下妻市',
    slug: 'shimotsuma-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 360,
    name: '鉾田市',
    slug: 'hokota-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 361,
    name: '坂東市',
    slug: 'bando-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 362,
    name: '常陸太田市',
    slug: 'hitachiota-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 363,
    name: '高萩市',
    slug: 'takahagi-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 364,
    name: '常陸大宮市',
    slug: 'hitachiomiya-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 365,
    name: '稲敷市',
    slug: 'inashiki-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 366,
    name: '那珂市',
    slug: 'naka-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 367,
    name: '常総市',
    slug: 'joso-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 368,
    name: '龍ヶ崎市',
    slug: 'ryugasaki-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 369,
    name: '小美玉市',
    slug: 'omitama-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 370,
    name: 'つくばみらい市',
    slug: 'tsukubamirai-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 371,
    name: '行方市',
    slug: 'namegata-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 372,
    name: '利根町',
    slug: 'tone-town',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 373,
    name: '大子町',
    slug: 'daigo-town',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 374,
    name: '桜川市',
    slug: 'sakuragawa-city',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 375,
    name: '阿見町',
    slug: 'ami-town',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki',
    area_group_slug: 'ag0800'
  },
  {
    id: 90,
    name: '富山市',
    slug: 'toyama',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 91,
    name: '高岡市',
    slug: 'takaoka',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 92,
    name: '富山県その他',
    slug: 'toyama-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 572,
    name: '砺波市',
    slug: 'tonami-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 573,
    name: '南砺市',
    slug: 'nanto-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 574,
    name: '射水市',
    slug: 'imizu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 575,
    name: '黒部市',
    slug: 'kurobe-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 576,
    name: '氷見市',
    slug: 'himi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 577,
    name: '魚津市',
    slug: 'uozu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 578,
    name: '入善町',
    slug: 'nyuzem-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 579,
    name: '小矢部市',
    slug: 'oyabe-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 580,
    name: '滑川市',
    slug: 'namerikawa-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 581,
    name: '立山町',
    slug: 'tateyama-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama',
    area_group_slug: 'ag1600'
  },
  {
    id: 99,
    name: '甲府市',
    slug: 'kofu',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 100,
    name: '甲斐市',
    slug: 'kai',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 101,
    name: '山梨県その他',
    slug: 'yamanashi-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 603,
    name: '富士吉田市',
    slug: 'fujiyoshida-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 604,
    name: '南アルプス市',
    slug: 'minamiarupusu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 605,
    name: '北杜市',
    slug: 'hokuto',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 606,
    name: '韮崎市',
    slug: 'nirasaki-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 607,
    name: '都留市',
    slug: 'tsuru-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 608,
    name: '笛吹市',
    slug: 'fuefuki-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 609,
    name: '上野原市',
    slug: 'uenohara-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 610,
    name: '富士川町',
    slug: 'fujikawa-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 612,
    name: '甲州市',
    slug: 'koshu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi',
    area_group_slug: 'ag1900'
  },
  {
    id: 87,
    name: '新潟市',
    slug: 'niigata',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 88,
    name: '長岡市',
    slug: 'nagaoka',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 89,
    name: '新潟県その他',
    slug: 'niigata-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 553,
    name: '上越市',
    slug: 'joetsu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 554,
    name: '三条市',
    slug: 'sanjo-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 555,
    name: '燕市',
    slug: 'tsubame-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 556,
    name: '柏崎市',
    slug: 'kashiwazaki-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 557,
    name: '佐渡市',
    slug: 'sado-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 558,
    name: '新発田市',
    slug: 'shibata-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 559,
    name: '十日町市',
    slug: 'tokamachi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 560,
    name: '小千谷市',
    slug: 'ojiya-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 561,
    name: '南魚沼市',
    slug: 'minamiuonuma-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 562,
    name: '村上市',
    slug: 'murakami-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 563,
    name: '糸魚川市',
    slug: 'itoigawa-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 564,
    name: '魚沼市',
    slug: 'uonuma-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 565,
    name: '見附市',
    slug: 'mitsuke-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 566,
    name: '妙高市',
    slug: 'myoko-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 567,
    name: '胎内市',
    slug: 'tainai-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 568,
    name: '阿賀野市',
    slug: 'agano-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 569,
    name: '五泉市',
    slug: 'gosen-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 570,
    name: '田上町',
    slug: 'tagami-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 571,
    name: '聖籠町',
    slug: 'seiro-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata',
    area_group_slug: 'ag1500'
  },
  {
    id: 93,
    name: '金沢市',
    slug: 'kanazawa',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 94,
    name: '白山市',
    slug: 'hakusan',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 95,
    name: '石川県その他',
    slug: 'ishikawa-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 582,
    name: '小松市',
    slug: 'komatsu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 583,
    name: '七尾市',
    slug: 'nanao-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 584,
    name: '羽咋市',
    slug: 'hakui-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 585,
    name: '加賀市',
    slug: 'kaga-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 586,
    name: '野々市市',
    slug: 'nonoichi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 587,
    name: '輪島市',
    slug: 'wajima-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 589,
    name: '能登町',
    slug: 'noto-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 590,
    name: 'かほく市',
    slug: 'kahoku-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 591,
    name: '能美市',
    slug: 'nomi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 592,
    name: '志賀町',
    slug: 'shika-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 593,
    name: '珠洲市',
    slug: 'suzu-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa',
    area_group_slug: 'ag1700'
  },
  {
    id: 96,
    name: '福井市',
    slug: 'fukui',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 97,
    name: '越前市',
    slug: 'echizen',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 98,
    name: '福井県その他',
    slug: 'fukui-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 595,
    name: '敦賀市',
    slug: 'tsuruga-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 596,
    name: '小浜市',
    slug: 'obama-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 597,
    name: '鯖江市',
    slug: 'sabae-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 598,
    name: '大野市',
    slug: 'ono-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 599,
    name: '坂井市',
    slug: 'sakai-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 600,
    name: '勝山市',
    slug: 'katsuyama-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 601,
    name: 'あわら市',
    slug: 'awara-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 602,
    name: '若狭町',
    slug: 'wakasa-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui',
    area_group_slug: 'ag1800'
  },
  {
    id: 102,
    name: '長野市',
    slug: 'nagano',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 103,
    name: '松本市',
    slug: 'matsumoto',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 104,
    name: '上田市',
    slug: 'ueda',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 105,
    name: '長野県その他',
    slug: 'nagano-other',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 613,
    name: '飯田市',
    slug: 'iida-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 614,
    name: '佐久市',
    slug: 'saku-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 615,
    name: '諏訪市',
    slug: 'suwa-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 616,
    name: '伊那市',
    slug: 'ina-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 617,
    name: '軽井沢町',
    slug: 'karuizawa-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 618,
    name: '安曇野市',
    slug: 'azumino-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 619,
    name: '駒ヶ根市',
    slug: 'komagane-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 620,
    name: '塩尻市',
    slug: 'shiojiri-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 621,
    name: '茅野市',
    slug: 'chino-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 622,
    name: '小諸市',
    slug: 'komoro-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 623,
    name: '岡谷市',
    slug: 'okaya-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 624,
    name: '須坂市',
    slug: 'suzaka-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 625,
    name: '千曲市',
    slug: 'chikuma-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 626,
    name: '大町市',
    slug: 'omachi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 627,
    name: '東御市',
    slug: 'tomi-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 628,
    name: '中野市',
    slug: 'nakano',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 629,
    name: '飯山市',
    slug: 'iiyama-city',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 630,
    name: '飯島町',
    slug: 'iijima-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 631,
    name: '高森町',
    slug: 'takamori-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 632,
    name: '箕輪町',
    slug: 'minowa-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 633,
    name: '辰野町',
    slug: 'tatsuno-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 634,
    name: '木曽町',
    slug: 'kiso-town',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano',
    area_group_slug: 'ag2000'
  },
  {
    id: 113,
    name: '名駅',
    slug: 'nagoya-sta',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 114,
    name: '金山',
    slug: 'kanayama',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 115,
    name: '栄',
    slug: 'sakae',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 116,
    name: '名古屋市内その他',
    slug: 'nagoyacity-other',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 678,
    name: '守山区',
    slug: 'nagoyashimoriyama-ward',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 679,
    name: '大須',
    slug: 'osu',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 680,
    name: '名東区',
    slug: 'nagoyashimeito-ward',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 681,
    name: '天白区',
    slug: 'nagoyashitempaku-ward',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2301'
  },
  {
    id: 117,
    name: '豊橋市',
    slug: 'toyohashi',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2302'
  },
  {
    id: 118,
    name: '岡崎市',
    slug: 'okazaki',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2302'
  },
  {
    id: 120,
    name: '豊田市',
    slug: 'toyota',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2302'
  },
  {
    id: 119,
    name: '一宮市',
    slug: 'ichinomiya',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2303'
  },
  {
    id: 121,
    name: '犬山市',
    slug: 'inuyama',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2303'
  },
  {
    id: 122,
    name: '愛知県その他',
    slug: 'aichi-other',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 222,
    name: '刈谷市',
    slug: 'kariya',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 682,
    name: 'あま市',
    slug: 'ama-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 683,
    name: '春日井市',
    slug: 'kasugai-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 684,
    name: '豊川市',
    slug: 'toyokawa-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 685,
    name: '半田市',
    slug: 'handa-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 686,
    name: '安城市',
    slug: 'anjo-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 687,
    name: '蒲郡市',
    slug: 'gamagori-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 688,
    name: '小牧市',
    slug: 'komaki-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 689,
    name: '西尾市',
    slug: 'nishio-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 690,
    name: '稲沢市',
    slug: 'inazawa-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 691,
    name: '田原市',
    slug: 'tahara-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 692,
    name: '瀬戸市',
    slug: 'seto-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 693,
    name: '新城市',
    slug: 'shinshiro-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 694,
    name: '碧南市',
    slug: 'hekinan-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 695,
    name: '知立市',
    slug: 'chiryu-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 696,
    name: '大府市',
    slug: 'obu-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 697,
    name: '常滑市',
    slug: 'tokoname-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 698,
    name: '尾張旭市',
    slug: 'owariasahi-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 699,
    name: '日進市',
    slug: 'nisshin-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 700,
    name: '知多市',
    slug: 'chita-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 701,
    name: '北名古屋市',
    slug: 'kitanagoya-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 702,
    name: '津島市',
    slug: 'tsushima-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 703,
    name: '江南市',
    slug: 'konan-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 704,
    name: '長久手市',
    slug: 'nagakute-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 705,
    name: '豊明市',
    slug: 'toyoake-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 706,
    name: '清須市',
    slug: 'kiyosu-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 707,
    name: 'みよし市',
    slug: 'miyoshi-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 708,
    name: '東浦町',
    slug: 'higashiura-town',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 709,
    name: '岩倉市',
    slug: 'iwakura-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 710,
    name: '弥富市',
    slug: 'yatomi-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 711,
    name: '愛西市',
    slug: 'aisai-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 712,
    name: '高浜市',
    slug: 'takahama-city',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 713,
    name: '阿久比町',
    slug: 'agui-town',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 714,
    name: '幸田町',
    slug: 'kota-town',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 715,
    name: '蟹江町',
    slug: 'kanie-town',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 716,
    name: '東郷町',
    slug: 'togo-town',
    region_slug: 'tokai',
    prefecture_slug: 'aichi',
    area_group_slug: 'ag2399'
  },
  {
    id: 109,
    name: '静岡市',
    slug: 'shizuoka',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2201'
  },
  {
    id: 110,
    name: '浜松市',
    slug: 'hamamatsu',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2202'
  },
  {
    id: 111,
    name: '沼津市',
    slug: 'numazu',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 112,
    name: '静岡県その他',
    slug: 'shizuoka-other',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 655,
    name: '富士市',
    slug: 'fuji-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 656,
    name: '三島市',
    slug: 'mishima-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 657,
    name: '藤枝市',
    slug: 'fujieda-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 658,
    name: '磐田市',
    slug: 'iwata-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 659,
    name: '掛川市',
    slug: 'kakegawa-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 660,
    name: '袋井市',
    slug: 'fukuroi-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 661,
    name: '御殿場市',
    slug: 'gotemba-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 662,
    name: '富士宮市',
    slug: 'fujinomiya-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 663,
    name: '伊豆市',
    slug: 'izu-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 664,
    name: '島田市',
    slug: 'shimada-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 665,
    name: '焼津市',
    slug: 'yaizu-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 666,
    name: '熱海市',
    slug: 'atami-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 667,
    name: '菊川市',
    slug: 'kikugawa-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 668,
    name: '湖西市',
    slug: 'kosai-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 669,
    name: '御前崎市',
    slug: 'omaezaki-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 670,
    name: '伊豆の国市',
    slug: 'izunokuni-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 671,
    name: '伊東市',
    slug: 'ito-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 672,
    name: '裾野市',
    slug: 'susono-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 673,
    name: '下田市',
    slug: 'shimoda-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 674,
    name: '牧之原市',
    slug: 'makinohara-city',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 675,
    name: '吉田町',
    slug: 'yoshida-town',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 676,
    name: '長泉町',
    slug: 'nagaizumi-town',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 677,
    name: '函南町',
    slug: 'kannami-town',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka',
    area_group_slug: 'ag2299'
  },
  {
    id: 123,
    name: '津市',
    slug: 'tsu',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 124,
    name: '四日市市',
    slug: 'yokkaichi',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 125,
    name: '三重県その他',
    slug: 'mie-other',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 717,
    name: '伊勢市',
    slug: 'ise-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 718,
    name: '松阪市',
    slug: 'matsusaka-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 719,
    name: '鈴鹿市',
    slug: 'suzuka-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 720,
    name: '伊賀市',
    slug: 'iga-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 721,
    name: '名張市',
    slug: 'nabari-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 722,
    name: '桑名市',
    slug: 'kuwana-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 723,
    name: 'いなべ市',
    slug: 'inabe-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 724,
    name: '志摩市',
    slug: 'shima-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 725,
    name: '亀山市',
    slug: 'kameyama-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 726,
    name: '熊野市',
    slug: 'kumano-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 727,
    name: '尾鷲市',
    slug: 'owase-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 728,
    name: '鳥羽市',
    slug: 'toba-city',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 729,
    name: '紀宝町',
    slug: 'kiho-town',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 730,
    name: '大台町',
    slug: 'odai-town',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 731,
    name: '菰野町',
    slug: 'komono-town',
    region_slug: 'tokai',
    prefecture_slug: 'mie',
    area_group_slug: 'ag2400'
  },
  {
    id: 106,
    name: '岐阜市',
    slug: 'gifu',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 107,
    name: '大垣市',
    slug: 'ogaki',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 108,
    name: '岐阜県その他',
    slug: 'gifu-other',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 635,
    name: '多治見市',
    slug: 'tajimi-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 636,
    name: '高山市',
    slug: 'takayama-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 637,
    name: '中津川市',
    slug: 'nakatsugawa-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 638,
    name: '土岐市',
    slug: 'toki-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 639,
    name: '恵那市',
    slug: 'ena-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 640,
    name: '可児市',
    slug: 'kani-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 641,
    name: '各務原市',
    slug: 'kakamigahara-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 642,
    name: '瑞浪市',
    slug: 'mizunami-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 643,
    name: '美濃加茂市',
    slug: 'minokamo-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 644,
    name: '下呂市',
    slug: 'gero-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 645,
    name: '郡上市',
    slug: 'gujo-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 646,
    name: '羽島市',
    slug: 'hashima-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 647,
    name: '飛騨市',
    slug: 'hida-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 648,
    name: '美濃市',
    slug: 'mino-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 649,
    name: '本巣市',
    slug: 'motosu-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 650,
    name: '瑞穂市',
    slug: 'mizuho-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 651,
    name: '岐南町',
    slug: 'ginan-town',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 652,
    name: '海津市',
    slug: 'kaizu-city',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 653,
    name: '養老町',
    slug: 'yoro-town',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 654,
    name: '川辺町',
    slug: 'kawabe-town',
    region_slug: 'tokai',
    prefecture_slug: 'gifu',
    area_group_slug: 'ag2100'
  },
  {
    id: 134,
    name: '梅田',
    slug: 'umeda',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2701'
  },
  {
    id: 135,
    name: '茶屋町',
    slug: 'chayamachi',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2701'
  },
  {
    id: 136,
    name: '堂島',
    slug: 'dojima',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2701'
  },
  {
    id: 137,
    name: '天満',
    slug: 'tenma',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2701'
  },
  {
    id: 138,
    name: '福島区',
    slug: 'fukushima',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2701'
  },
  {
    id: 139,
    name: '心斎橋',
    slug: 'shinsaibashi',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2702'
  },
  {
    id: 140,
    name: '難波',
    slug: 'namba',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2702'
  },
  {
    id: 141,
    name: '堀江',
    slug: 'horie',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2702'
  },
  {
    id: 142,
    name: '本町',
    slug: 'honmachi',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2702'
  },
  {
    id: 143,
    name: '天王寺区',
    slug: 'tennoji',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2702'
  },
  {
    id: 144,
    name: '新大阪',
    slug: 'shinosaka',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2703'
  },
  {
    id: 146,
    name: '大阪府北部その他',
    slug: 'osakanorth-other',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2703'
  },
  {
    id: 145,
    name: '堺市',
    slug: 'sakai',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2704'
  },
  {
    id: 147,
    name: '大阪府南部その他',
    slug: 'osakasouth-other',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2704'
  },
  {
    id: 148,
    name: '大阪府その他',
    slug: 'osaka-other',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 759,
    name: '枚方市',
    slug: 'hirakata-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 760,
    name: '高槻市',
    slug: 'takatsuki-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 761,
    name: '泉佐野市',
    slug: 'izumisano-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 762,
    name: '岸和田市',
    slug: 'kishiwada-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 763,
    name: '東大阪市',
    slug: 'higashiosaka-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 764,
    name: '和泉市',
    slug: 'izumi-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 765,
    name: '茨木市',
    slug: 'ibaraki-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 766,
    name: '八尾市',
    slug: 'yao-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 767,
    name: '豊中市',
    slug: 'toyonaka-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 768,
    name: '吹田市',
    slug: 'suita-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 769,
    name: '河内長野市',
    slug: 'kawachinagano-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 770,
    name: '富田林市',
    slug: 'tondabayashi-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 771,
    name: '泉南市',
    slug: 'sennan-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 772,
    name: '寝屋川市',
    slug: 'neyagawa-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 773,
    name: '池田市',
    slug: 'ikeda-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 774,
    name: '松原市',
    slug: 'matsubara-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 775,
    name: '箕面市',
    slug: 'mino',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 776,
    name: '門真市',
    slug: 'kadoma-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 777,
    name: '守口市',
    slug: 'moriguchi-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 778,
    name: '羽曳野市',
    slug: 'habikino-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 779,
    name: '貝塚市',
    slug: 'kaizuka-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 780,
    name: '藤井寺市',
    slug: 'fujiidera-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 781,
    name: '交野市',
    slug: 'katano-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 782,
    name: '高石市',
    slug: 'takaishi-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 783,
    name: '泉大津市',
    slug: 'izumiotsu-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 784,
    name: '柏原市',
    slug: 'kashiwara-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 785,
    name: '阪南市',
    slug: 'hannan-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 786,
    name: '大東市',
    slug: 'daito-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 787,
    name: '大阪市北区',
    slug: 'osakashikitaku',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 788,
    name: '大阪狭山市',
    slug: 'osakasayama-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 789,
    name: '摂津市',
    slug: 'settsu-city',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 790,
    name: '堺市北区',
    slug: 'sakaishikitaku',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 791,
    name: '太子町',
    slug: 'taishi-town',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 792,
    name: '泉北郡',
    slug: 'semboku',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 1088,
    name: '大阪市内その他',
    slug: 'osakacity-other',
    region_slug: 'kinki',
    prefecture_slug: 'osaka',
    area_group_slug: 'ag2799'
  },
  {
    id: 149,
    name: '三宮・元町',
    slug: 'sannomiya',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2801'
  },
  {
    id: 150,
    name: '神戸市内その他',
    slug: 'kobecity-other',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2801'
  },
  {
    id: 793,
    name: '神戸市北区',
    slug: 'kobeshikitaku',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2801'
  },
  {
    id: 794,
    name: '神戸市西区',
    slug: 'kobeshinishiku',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2801'
  },
  {
    id: 151,
    name: '姫路市',
    slug: 'himeji',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2803'
  },
  {
    id: 152,
    name: '尼崎市',
    slug: 'amagasaki',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2802'
  },
  {
    id: 153,
    name: '西宮市',
    slug: 'nishinomiya',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2802'
  },
  {
    id: 154,
    name: '兵庫県その他',
    slug: 'hyogo-other',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 795,
    name: '豊岡市',
    slug: 'toyoka-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 796,
    name: '加古川市',
    slug: 'kakogawa-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 797,
    name: '明石市',
    slug: 'akashi-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 798,
    name: '三田市',
    slug: 'sanda-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 799,
    name: '丹波市',
    slug: 'tamba-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 800,
    name: '宝塚市',
    slug: 'takarazuka-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 801,
    name: '三木市',
    slug: 'miki-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 802,
    name: '伊丹市',
    slug: 'itami-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 803,
    name: 'たつの市',
    slug: 'tatsuno-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 804,
    name: '小野市',
    slug: 'ono',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 805,
    name: '淡路市',
    slug: 'awaji-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 806,
    name: '丹波篠山市',
    slug: 'tambasasayama-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 807,
    name: '芦屋市',
    slug: 'ashiya-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 808,
    name: '高砂市',
    slug: 'takasago-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 809,
    name: '朝来市',
    slug: 'asago-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 810,
    name: '赤穂市',
    slug: 'ako-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 811,
    name: '洲本市',
    slug: 'sumoto-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 812,
    name: '川西市',
    slug: 'kawanishi-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 813,
    name: '加西市',
    slug: 'kasai-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 814,
    name: '西脇市',
    slug: 'nishiwaki-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 815,
    name: '養父市',
    slug: 'yabu-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 816,
    name: '南あわじ市',
    slug: 'minamiawaji-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 817,
    name: '加東市',
    slug: 'kato-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 818,
    name: '宍粟市',
    slug: 'shiso-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 819,
    name: '相生市',
    slug: 'aioi-city',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 820,
    name: '播磨町',
    slug: 'harima-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 821,
    name: '福崎町',
    slug: 'fukusaki-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 822,
    name: '佐用町',
    slug: 'sayo-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 823,
    name: '猪名川町',
    slug: 'inagawa-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 824,
    name: '神戸市東灘区',
    slug: 'kobeshihigashinadaku',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 825,
    name: '上郡町',
    slug: 'kamigori-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 826,
    name: '稲美町',
    slug: 'inami-town',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo',
    area_group_slug: 'ag2899'
  },
  {
    id: 129,
    name: '河原町',
    slug: 'kawaramachi',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2601'
  },
  {
    id: 130,
    name: '烏丸',
    slug: 'karasuma',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2601'
  },
  {
    id: 131,
    name: '京都駅周辺',
    slug: 'kyoto-sta',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2601'
  },
  {
    id: 132,
    name: '京都市内その他',
    slug: 'kyotocity-other',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2601'
  },
  {
    id: 133,
    name: '京都府その他',
    slug: 'kyoto-other',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 742,
    name: '福知山市',
    slug: 'fukuchiyama-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 743,
    name: '舞鶴市',
    slug: 'maizuru-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 744,
    name: '京丹後市',
    slug: 'kyotango-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 745,
    name: '亀岡市',
    slug: 'kameoka-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 746,
    name: '綾部市',
    slug: 'ayabe-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 747,
    name: '宇治市',
    slug: 'uji-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 748,
    name: '八幡市',
    slug: 'yawata-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 749,
    name: '長岡京市',
    slug: 'nagaokakyo-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 750,
    name: '城陽市',
    slug: 'joyo-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 751,
    name: '宮津市',
    slug: 'miyazu-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 752,
    name: '京田辺市',
    slug: 'kyotanabe-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 753,
    name: '南丹市',
    slug: 'nantan-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 754,
    name: '木津川市',
    slug: 'kizugawa-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 755,
    name: '向日市',
    slug: 'muko-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 756,
    name: '精華町',
    slug: 'seika-town',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 757,
    name: '京北町',
    slug: 'keihoku',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 758,
    name: '京都東山市',
    slug: 'kyotohigashiyama-city',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto',
    area_group_slug: 'ag2699'
  },
  {
    id: 158,
    name: '和歌山市',
    slug: 'wakayama',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 159,
    name: '田辺市',
    slug: 'tanabe',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 160,
    name: '和歌山県その他',
    slug: 'wakayama-other',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 838,
    name: '橋本市',
    slug: 'hashimoto-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 839,
    name: '岩出市',
    slug: 'iwade-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 840,
    name: '新宮市',
    slug: 'shingu-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 841,
    name: '有田市',
    slug: 'arida-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 842,
    name: '海南市',
    slug: 'kainan-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 843,
    name: '御坊市',
    slug: 'gobo-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 844,
    name: '白浜町',
    slug: 'shirahama-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 845,
    name: '紀の川市',
    slug: 'kinokawa-city',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 846,
    name: '有田川町',
    slug: 'aridagawa-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 847,
    name: '串本町',
    slug: 'kushimoto-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 848,
    name: '日高川町',
    slug: 'hidakagawa-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 849,
    name: '広川町',
    slug: 'hirogawa-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 850,
    name: '上富田町',
    slug: 'kamitonda-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 851,
    name: 'みなべ町',
    slug: 'minabe-town',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama',
    area_group_slug: 'ag3000'
  },
  {
    id: 155,
    name: '奈良市',
    slug: 'nara',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 156,
    name: '橿原市',
    slug: 'kashihara',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 157,
    name: '奈良県その他',
    slug: 'nara-other',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 827,
    name: '生駒市',
    slug: 'ikoma-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 828,
    name: '天理市',
    slug: 'tenri-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 829,
    name: '香芝市',
    slug: 'kashiba-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 830,
    name: '大和高田市',
    slug: 'yamatotakada-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 831,
    name: '王寺町',
    slug: 'oji-town',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 832,
    name: '御所市',
    slug: 'gose-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 833,
    name: '宇陀市',
    slug: 'uda-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 834,
    name: '五條市',
    slug: 'gojo-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 835,
    name: '葛城市',
    slug: 'katsuragi-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 836,
    name: '大和郡山市',
    slug: 'yamatokoriyama-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 837,
    name: '桜井市',
    slug: 'sakurai-city',
    region_slug: 'kinki',
    prefecture_slug: 'nara',
    area_group_slug: 'ag2900'
  },
  {
    id: 126,
    name: '大津市',
    slug: 'otsu',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 127,
    name: '草津市',
    slug: 'kusatsu',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 128,
    name: '滋賀県その他',
    slug: 'shiga-other',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 732,
    name: '彦根市',
    slug: 'hikone-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 733,
    name: '長浜市',
    slug: 'nagahama-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 734,
    name: '東近江市',
    slug: 'higashiomi-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 735,
    name: '近江八幡市',
    slug: 'omihachiman-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 736,
    name: '米原市',
    slug: 'maibara-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 737,
    name: '栗東市',
    slug: 'ritto-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 738,
    name: '高島市',
    slug: 'takashima-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 739,
    name: '甲賀市',
    slug: 'koka-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 740,
    name: '湖南市',
    slug: 'konan',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 741,
    name: '野洲市',
    slug: 'yasu-city',
    region_slug: 'kinki',
    prefecture_slug: 'shiga',
    area_group_slug: 'ag2500'
  },
  {
    id: 177,
    name: '下関市',
    slug: 'shimonoseki',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 178,
    name: '山口市',
    slug: 'yamaguchi',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 179,
    name: '山口県その他',
    slug: 'yamaguchi-other',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 894,
    name: '周南市',
    slug: 'shunan-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 895,
    name: '宇部市',
    slug: 'ube-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 896,
    name: '岩国市',
    slug: 'iwakuni-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 897,
    name: '防府市',
    slug: 'hofu-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 898,
    name: '下松市',
    slug: 'kudamatsu-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 899,
    name: '萩市',
    slug: 'hagi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 900,
    name: '柳井市',
    slug: 'yanai-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 901,
    name: '長門市',
    slug: 'nagato-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 902,
    name: '山陽小野田市',
    slug: 'sanyoonoda-city',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi',
    area_group_slug: 'ag3500'
  },
  {
    id: 167,
    name: '岡山駅周辺',
    slug: 'okayama-sta',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3301'
  },
  {
    id: 168,
    name: '表町・田町',
    slug: 'omotecho',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3301'
  },
  {
    id: 169,
    name: '岡山市内その他',
    slug: 'okayamacity-other',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3301'
  },
  {
    id: 170,
    name: '倉敷市',
    slug: 'kurashiki',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3302'
  },
  {
    id: 171,
    name: '岡山県その他',
    slug: 'okayama-other',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 862,
    name: '津山市',
    slug: 'tsuyama-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 863,
    name: '笠岡市',
    slug: 'kasaoka-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 864,
    name: '総社市',
    slug: 'soja-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 865,
    name: '玉野市',
    slug: 'tamano-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 866,
    name: '新見市',
    slug: 'niimi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 867,
    name: '美作市',
    slug: 'mimasaka-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 868,
    name: '真庭市',
    slug: 'maniwa-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 869,
    name: '高梁市',
    slug: 'takahashi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 870,
    name: '備前市',
    slug: 'bizen-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 871,
    name: '井原市',
    slug: 'ibara-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 872,
    name: '瀬戸内市',
    slug: 'setouchi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 873,
    name: '浅口市',
    slug: 'asakuchi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 874,
    name: '赤磐市',
    slug: 'akaiwa-city',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 875,
    name: '奈義町',
    slug: 'nagi-town',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 876,
    name: '矢掛町',
    slug: 'yakage-town',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 877,
    name: '里庄町',
    slug: 'satosho-town',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 878,
    name: '和気町',
    slug: 'wake-town',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama',
    area_group_slug: 'ag3399'
  },
  {
    id: 164,
    name: '松江市',
    slug: 'matsue',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 165,
    name: '出雲市',
    slug: 'izumo',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 166,
    name: '島根県その他',
    slug: 'shimane-other',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 856,
    name: '浜田市',
    slug: 'hamada-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 857,
    name: '益田市',
    slug: 'masuda-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 858,
    name: '江津市',
    slug: 'gotsu-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 859,
    name: '安来市',
    slug: 'yasugi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 860,
    name: '大田市',
    slug: 'oda-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 861,
    name: '雲南市',
    slug: 'unnan-city',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane',
    area_group_slug: 'ag3200'
  },
  {
    id: 172,
    name: '八丁堀・紙屋町',
    slug: 'hacchobori',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3401'
  },
  {
    id: 173,
    name: '広島駅周辺',
    slug: 'hiroshima-sta',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3401'
  },
  {
    id: 174,
    name: '広島市内その他',
    slug: 'hiroshimacity-other',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3401'
  },
  {
    id: 175,
    name: '福山市',
    slug: 'fukuyama',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3402'
  },
  {
    id: 176,
    name: '広島県その他',
    slug: 'hiroshima-other',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 879,
    name: '廿日市市',
    slug: 'hatsukaichi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 880,
    name: '大竹市',
    slug: 'otake-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 881,
    name: '東広島市',
    slug: 'higashihiroshima-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 882,
    name: '呉市',
    slug: 'kure-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 883,
    name: '尾道市',
    slug: 'onomichi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 884,
    name: '三原市',
    slug: 'mihara-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 885,
    name: '三次市',
    slug: 'miyoshi',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 886,
    name: '竹原市',
    slug: 'takehara-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 887,
    name: '庄原市',
    slug: 'shobara-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 888,
    name: '佐伯区',
    slug: 'osakikamijima-town',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 889,
    name: '大崎上島町',
    slug: 'hiroshimashinakaku',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 890,
    name: '安芸高田市',
    slug: 'akitakata-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 891,
    name: '江田島市',
    slug: 'etajima-city',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 892,
    name: '北広島町',
    slug: 'kitahiroshima-town',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 893,
    name: '神石高原町',
    slug: 'jinsekikogen-town',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima',
    area_group_slug: 'ag3403'
  },
  {
    id: 161,
    name: '鳥取市',
    slug: 'tottori',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 162,
    name: '米子市',
    slug: 'yonago',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 163,
    name: '鳥取県その他',
    slug: 'tottori-other',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 852,
    name: '倉吉市',
    slug: 'kurayoshi-city',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 853,
    name: '大山町',
    slug: 'daisen-town',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 854,
    name: '境港市',
    slug: 'sakaiminato-city',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 855,
    name: '智頭町',
    slug: 'chizu-town',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori',
    area_group_slug: 'ag3100'
  },
  {
    id: 180,
    name: '徳島市',
    slug: 'tokushima',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 181,
    name: '阿南市',
    slug: 'anan',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 182,
    name: '徳島県その他',
    slug: 'tokushima-other',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 903,
    name: '鳴門市',
    slug: 'naruto-city',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 904,
    name: '三好市',
    slug: 'miyoshi_city',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 905,
    name: '阿波市',
    slug: 'awa-city',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 906,
    name: '美馬市',
    slug: 'mima-city',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 907,
    name: '吉野川市',
    slug: 'yoshinogawa-city',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 908,
    name: '石井町',
    slug: 'ishii-town',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 909,
    name: '海部駅',
    slug: 'kaifu-sta',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima',
    area_group_slug: 'ag3600'
  },
  {
    id: 186,
    name: '松山市',
    slug: 'matsuyama',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 187,
    name: '今治市',
    slug: 'imabari',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 188,
    name: '愛媛県その他',
    slug: 'ehime-other',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 921,
    name: '新居浜市',
    slug: 'niihama-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 922,
    name: '四国中央市',
    slug: 'shikokuchuo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 923,
    name: '西条市',
    slug: 'saijo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 924,
    name: '宇和島市',
    slug: 'uwajima-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 925,
    name: '大洲市',
    slug: 'ozu-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 926,
    name: '八幡浜市',
    slug: 'yawatahama-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 927,
    name: '西予市',
    slug: 'seiyo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 928,
    name: '伊予市',
    slug: 'iyo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 929,
    name: '愛南町',
    slug: 'ainan-town',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 930,
    name: '東温市',
    slug: 'toon-city',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime',
    area_group_slug: 'ag3800'
  },
  {
    id: 183,
    name: '高松市',
    slug: 'takamatsu',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 184,
    name: '丸亀市',
    slug: 'marugame',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 185,
    name: '香川県その他',
    slug: 'kagawa-other',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 910,
    name: '観音寺市',
    slug: 'kanonji-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 911,
    name: '宇多津町',
    slug: 'utazu-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 912,
    name: '三豊市',
    slug: 'mitoyo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 913,
    name: '小豆島町',
    slug: 'shodoshima-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 914,
    name: '善通寺市',
    slug: 'zentsuji-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 915,
    name: '坂出市',
    slug: 'sakaide-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 916,
    name: '東かがわ市',
    slug: 'higashikagawa-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 917,
    name: '多度津町',
    slug: 'tadotsu-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 918,
    name: '直島町',
    slug: 'naoshima-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 919,
    name: 'さぬき市',
    slug: 'sanuki-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 920,
    name: '琴平町',
    slug: 'kotohira-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa',
    area_group_slug: 'ag3700'
  },
  {
    id: 189,
    name: '高知市',
    slug: 'kochi',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 190,
    name: '南国市',
    slug: 'nankoku',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 191,
    name: '高知県その他',
    slug: 'kochi-other',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 931,
    name: '四万十市',
    slug: 'shimanto-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 932,
    name: '宿毛市',
    slug: 'sukumo-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 934,
    name: '須崎市',
    slug: 'susaki-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 935,
    name: '香美市',
    slug: 'kami-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 936,
    name: '香南市',
    slug: 'konan_city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 937,
    name: '黒潮町',
    slug: 'kuroshio-town',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 938,
    name: '土佐市',
    slug: 'tosa-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 939,
    name: '土佐清水市',
    slug: 'tosashimizu-city',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi',
    area_group_slug: 'ag3900'
  },
  {
    id: 192,
    name: '北九州市',
    slug: 'kitakyushu',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4004'
  },
  {
    id: 221,
    name: '小倉区',
    slug: 'kokura',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4004'
  },
  {
    id: 940,
    name: '小倉北区',
    slug: 'kitakyushushikokurakita-ward',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4004'
  },
  {
    id: 941,
    name: '八幡西区',
    slug: 'kitakyushushiyahatanishi-ward',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4004'
  },
  {
    id: 942,
    name: '小倉南区',
    slug: 'kitakyushushikokuraminami-ward',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4004'
  },
  {
    id: 193,
    name: '博多区',
    slug: 'hakata',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4001'
  },
  {
    id: 194,
    name: '天神',
    slug: 'tenjin',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4001'
  },
  {
    id: 195,
    name: '福岡市内その他',
    slug: 'fukuokacity-other',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4002'
  },
  {
    id: 196,
    name: '久留米市',
    slug: 'kurume',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4003'
  },
  {
    id: 197,
    name: '福岡県その他',
    slug: 'fukuoka-other',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 943,
    name: '大牟田市',
    slug: 'omuta-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 944,
    name: '筑後市',
    slug: 'chikugo-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 945,
    name: '宗像市',
    slug: 'munakata-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 946,
    name: '飯塚市',
    slug: 'iizuka-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 947,
    name: '八女市',
    slug: 'yame-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 948,
    name: '糸島市',
    slug: 'itoshima-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 949,
    name: '行橋市',
    slug: 'yukuhashi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 950,
    name: '柳川市',
    slug: 'yanagawa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 951,
    name: '筑紫野市',
    slug: 'chikushino-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 952,
    name: '朝倉市',
    slug: 'asakura-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 953,
    name: '豊前市',
    slug: 'buzen-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 954,
    name: '春日市',
    slug: 'kasuga-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 955,
    name: '太宰府市',
    slug: 'dazaifu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 956,
    name: '小郡市',
    slug: 'ogori-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 957,
    name: '大川市',
    slug: 'okawa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 958,
    name: '直方市',
    slug: 'nogata-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 959,
    name: '大野城市',
    slug: 'onojo-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 960,
    name: 'うきは市',
    slug: 'ukiha-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 961,
    name: '田川市',
    slug: 'tagawa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 962,
    name: '中間市',
    slug: 'nakama-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 963,
    name: '福津市',
    slug: 'fukutsu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 964,
    name: '宮若市',
    slug: 'miyawaka-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 965,
    name: 'みやま市',
    slug: 'miyama-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 966,
    name: '古賀市',
    slug: 'koga_city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 967,
    name: '嘉麻市',
    slug: 'kama-city',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 968,
    name: '遠賀町',
    slug: 'onga-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 969,
    name: '戸畑区',
    slug: 'oki-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 970,
    name: '岡垣町',
    slug: 'okagaki-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 971,
    name: '水巻町',
    slug: 'mizumaki-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 972,
    name: '篠栗町',
    slug: 'sasaguri-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 973,
    name: '筑前町',
    slug: 'chikuzem-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 974,
    name: '鞍手町',
    slug: 'kurate-town',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka',
    area_group_slug: 'ag4099'
  },
  {
    id: 201,
    name: '長崎市',
    slug: 'nagasaki',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 202,
    name: '佐世保市',
    slug: 'sasebo',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 203,
    name: '長崎県その他',
    slug: 'nagasaki-other',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 984,
    name: '五島市',
    slug: 'goto-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 985,
    name: '諫早市',
    slug: 'isahaya-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 986,
    name: '大村市',
    slug: 'omura-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 987,
    name: '島原市',
    slug: 'shimabara-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 988,
    name: '対馬市',
    slug: 'tsushima',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 989,
    name: '壱岐市',
    slug: 'iki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 990,
    name: '松浦市',
    slug: 'matsura-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 991,
    name: '平戸市',
    slug: 'hirado-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 992,
    name: '南島原市',
    slug: 'minamishimabara-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 993,
    name: '長与町',
    slug: 'nagayo-town',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 994,
    name: '雲仙市',
    slug: 'unzen-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 995,
    name: '西海市',
    slug: 'saikai-city',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki',
    area_group_slug: 'ag4200'
  },
  {
    id: 198,
    name: '佐賀市',
    slug: 'saga',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 199,
    name: '鳥栖市',
    slug: 'tosu',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 200,
    name: '佐賀県その他',
    slug: 'saga-other',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 975,
    name: '唐津市',
    slug: 'karatsu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 976,
    name: '伊万里市',
    slug: 'imari-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 977,
    name: '武雄市',
    slug: 'takeo-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 978,
    name: '鹿島市',
    slug: 'kashima',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 979,
    name: '嬉野市',
    slug: 'ureshino-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 980,
    name: '小城市',
    slug: 'ogi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 981,
    name: '神埼市',
    slug: 'kanzaki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 982,
    name: '有田町',
    slug: 'arita-town',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 983,
    name: '多久市',
    slug: 'taku-city',
    region_slug: 'kyushu',
    prefecture_slug: 'saga',
    area_group_slug: 'ag4100'
  },
  {
    id: 207,
    name: '大分市',
    slug: 'oita',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 208,
    name: '別府市',
    slug: 'beppu',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 209,
    name: '大分県その他',
    slug: 'oita-other',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1011,
    name: '中津市',
    slug: 'nakatsu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1012,
    name: '日田市',
    slug: 'hita-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1013,
    name: '宇佐市',
    slug: 'usa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1014,
    name: '豊後高田市',
    slug: 'bungotakada-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1015,
    name: '佐伯市',
    slug: 'saiki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1016,
    name: '臼杵市',
    slug: 'usuki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1017,
    name: '国東市',
    slug: 'kunisaki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1018,
    name: '杵築市',
    slug: 'kitsuki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1019,
    name: '日出町',
    slug: 'hiji-town',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1020,
    name: '竹田市',
    slug: 'taketa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1021,
    name: '津久見市',
    slug: 'tsukumi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 1022,
    name: '豊後大野市',
    slug: 'bungoono-city',
    region_slug: 'kyushu',
    prefecture_slug: 'oita',
    area_group_slug: 'ag4400'
  },
  {
    id: 210,
    name: '宮崎市',
    slug: 'miyazaki',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 211,
    name: '都城市',
    slug: 'miyakonojo',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 212,
    name: '宮崎県その他',
    slug: 'miyazaki-other',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1023,
    name: '延岡市',
    slug: 'nobeoka-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1024,
    name: '日向市',
    slug: 'hyuga-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1025,
    name: '小林市',
    slug: 'kobayashi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1026,
    name: '高千穂町',
    slug: 'takachiho-town',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1027,
    name: '串間市',
    slug: 'kushima-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1028,
    name: 'えびの市',
    slug: 'ebino-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1029,
    name: '日南市',
    slug: 'nichinan-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1030,
    name: '西都市',
    slug: 'saito-city',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1031,
    name: '三股町',
    slug: 'mimata-town',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1032,
    name: '都農町',
    slug: 'tsuno-town',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 1033,
    name: '綾町',
    slug: 'aya-town',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki',
    area_group_slug: 'ag4500'
  },
  {
    id: 204,
    name: '熊本市',
    slug: 'kumamoto',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 205,
    name: '八代市',
    slug: 'yatsushiro',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 206,
    name: '熊本県その他',
    slug: 'kumamoto-other',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 996,
    name: '天草市',
    slug: 'amakusa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 997,
    name: '玉名市',
    slug: 'tamana-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 998,
    name: '人吉市',
    slug: 'hitoyoshi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 999,
    name: '荒尾市',
    slug: 'arao-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1000,
    name: '宇城市',
    slug: 'uki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1001,
    name: '山鹿市',
    slug: 'yamaga-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1002,
    name: '菊池市',
    slug: 'kikuchi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1003,
    name: '阿蘇市',
    slug: 'aso-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1004,
    name: '水俣市',
    slug: 'minamata-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1005,
    name: '宇土市',
    slug: 'uto-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1006,
    name: '上天草市',
    slug: 'kamiamakusa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1007,
    name: '長洲町',
    slug: 'nagasu-town',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1008,
    name: '合志市',
    slug: 'koshi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1009,
    name: '芦北町',
    slug: 'ashikita-town',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 1010,
    name: '益城町',
    slug: 'mashiki-town',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto',
    area_group_slug: 'ag4300'
  },
  {
    id: 213,
    name: '鹿児島市',
    slug: 'kagoshima',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 214,
    name: '霧島市',
    slug: 'kirishima',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 215,
    name: '鹿児島県その他',
    slug: 'kagoshima-other',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1034,
    name: '鹿屋市',
    slug: 'kanoya-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1035,
    name: '薩摩川内市',
    slug: 'satsumasendai-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1036,
    name: '出水市',
    slug: 'izumi',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1037,
    name: '奄美市',
    slug: 'amami-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1038,
    name: '姶良市',
    slug: 'aira-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1039,
    name: '屋久島町',
    slug: 'yakushima-town',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1040,
    name: '志布志市',
    slug: 'shibushi-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1041,
    name: '南さつま市',
    slug: 'minamisatsuma-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1042,
    name: '指宿市',
    slug: 'ibusuki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1043,
    name: '伊佐市',
    slug: 'isa-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1044,
    name: '曽於市',
    slug: 'so-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1045,
    name: '日置市',
    slug: 'hioki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1046,
    name: '阿久根市',
    slug: 'akune-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1047,
    name: '枕崎市',
    slug: 'makurazaki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1048,
    name: 'いちき串木野市',
    slug: 'ichikikushikino-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1049,
    name: '南九州市',
    slug: 'minamikyushu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1050,
    name: '西之表市',
    slug: 'nishinomote-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 1051,
    name: '垂水市',
    slug: 'tarumizu-city',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima',
    area_group_slug: 'ag4600'
  },
  {
    id: 216,
    name: '那覇市',
    slug: 'naha',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 217,
    name: '沖縄',
    slug: 'okinawa',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 218,
    name: '沖縄県その他',
    slug: 'okinawa-other',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1052,
    name: 'うるま市',
    slug: 'uruma-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1053,
    name: '南城市',
    slug: 'nanjo-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1054,
    name: '宮古島市',
    slug: 'miyakojima-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1055,
    name: '名護市',
    slug: 'nago-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1056,
    name: '石垣市',
    slug: 'ishigaki-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1057,
    name: '宜野湾市',
    slug: 'ginowan-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1058,
    name: '北谷町',
    slug: 'chatan-town',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1059,
    name: '糸満市',
    slug: 'itoman-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 1060,
    name: '浦添市',
    slug: 'urasoe-city',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa',
    area_group_slug: 'ag4700'
  },
  {
    id: 219,
    name: 'その他',
    slug: 'other',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1061,
    name: 'アメリカ',
    slug: 'america',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1062,
    name: 'イギリス',
    slug: 'england',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1063,
    name: 'イタリア',
    slug: 'italy',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1064,
    name: 'インド',
    slug: 'india',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1065,
    name: 'インドネシア',
    slug: 'indonesia',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1066,
    name: 'ウクライナ',
    slug: 'ukraine',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1067,
    name: 'オーストラリア',
    slug: 'australia',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1068,
    name: 'カナダ',
    slug: 'canada',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1069,
    name: 'シンガポール',
    slug: 'singapore',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1070,
    name: 'タイ',
    slug: 'thailand',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1071,
    name: 'デンマーク',
    slug: 'denmark',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1072,
    name: 'ドイツ',
    slug: 'germany',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1073,
    name: 'トルコ',
    slug: 'turkey',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1074,
    name: 'ニュージーランド',
    slug: 'newzealand',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1075,
    name: 'フィリピン',
    slug: 'philippines',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1076,
    name: 'フィンランド',
    slug: 'finland',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1077,
    name: 'フランス',
    slug: 'france',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1078,
    name: 'ベトナム',
    slug: 'vietnam',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1079,
    name: 'ポーランド',
    slug: 'poland',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1080,
    name: 'マレーシア',
    slug: 'malaysia',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1081,
    name: 'ラオス',
    slug: 'laos',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1082,
    name: 'ロシア',
    slug: 'russia',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1083,
    name: '韓国',
    slug: 'korea',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1084,
    name: '台湾',
    slug: 'taiwan',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1085,
    name: '中国',
    slug: 'china',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  },
  {
    id: 1086,
    name: '北朝鮮',
    slug: 'northkorea',
    region_slug: 'other',
    prefecture_slug: 'other',
    area_group_slug: 'ag9900'
  }
]
