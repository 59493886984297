<template>
  <div>
    <flash-message
      v-if="stateFlashMessage"
      :type="stateFlashMessage.type"
      :message="stateFlashMessage.message"
      :messages="stateFlashMessage.messages"
      class="marginY3"
    />
    <flash-message v-for="(v, index) in flashMessagesByParams" :key="index" :type="v.type" :message="v.message" :class="{ marginY3: !noMargin }" />
  </div>
</template>

<script>
import { flashMessagesByQueryParams } from '@/utils/flashMessageHandler'
const FlashMessage = defineAsyncComponent(() => import('@/components/FlashMessage.vue'))
import { appsflyerCallback } from '@/utils/NativeCallback'

// This component to display flash message that display as GET params, or which sent from other page (message that stored in vuex store)
export default {
  components: { FlashMessage },
  props: {
    noMargin: { type: Boolean, default: false, required: false }
  },
  computed: {
    stateFlashMessage() {
      return this.$store.$state.flash_message
    },
    flashMessagesByParams() {
      return flashMessagesByQueryParams(this.$route.query)
    }
  },
  unmounted() {
    if (this.flashMessage) this.$store.FLASH_MESSAGE(null)
  },
  created() {
    if (this.$route.query?.['new_register'] === 'true' && this.$store.isLoggedIn) appsflyerCallback(`af_start_registration_${this.$store.myGender}`)
  }
}
</script>
