// Environment Settings
export const NODE_ENV = import.meta.env.NODE_ENV
export const BASE_API_URL = import.meta.env.BASE_API_URL
export const ROOT_URL = import.meta.env.ROOT_URL
export const LINKBAL_ID_CLIENT_ID = import.meta.env.LINKBAL_ID_CLIENT_ID
export const LINKBAL_ID_IDP_URL = import.meta.env.LINKBAL_ID_IDP_URL
export const CHOCOM_SHOP_ID_CC = import.meta.env.CHOCOM_SHOP_ID_CC
export const CHOCOM_GET_TOKEN_LINK = import.meta.env.CHOCOM_GET_TOKEN_LINK
export const MARRIAGE_STYLE_URL = import.meta.env.MARRIAGE_STYLE_URL
export const AMAZON_PAY_CLIENT_ID = import.meta.env.AMAZON_PAY_CLIENT_ID
export const AMAZON_PAY_MERCHANT_ID = import.meta.env.AMAZON_PAY_MERCHANT_ID
export const AMAZON_PAY_PUBLIC_KEY_ID = import.meta.env.AMAZON_PAY_PUBLIC_KEY_ID
export const AMAZON_PAY_CHECKOUT_JS_URL = import.meta.env.AMAZON_PAY_CHECKOUT_JS_URL
export const FIREBASE_API_KEY = import.meta.env.FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = import.meta.env.FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = import.meta.env.FIREBASE_PROJECT_ID
export const FIREBASE_MESSAGING_SENDER_ID = import.meta.env.FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = import.meta.env.FIREBASE_APP_ID
export const FIREBASE_VAPID_KEY = import.meta.env.FIREBASE_VAPID_KEY
export const STRIPE_PUBLIC_KEY = import.meta.env.STRIPE_PUBLIC_KEY
export const settings = {
  NODE_ENV,
  BASE_API_URL,
  ROOT_URL,
  LINKBAL_ID_IDP_URL,
  LINKBAL_ID_CLIENT_ID
}

export const mapSettings = array => {
  const map = {}
  array.forEach(key => {
    map[key] = () => settings[key]
  })
  return map
}
