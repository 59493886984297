import moment from 'moment-timezone'

// Set default locale for moment
moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  weekdays: '日_月_火_水_木_金_土'.split('_'),
  weekdaysMin: '日_月_火_水_木_金_土'.split('_'),
  weekdaysShort: '日_月_火_水_木_金_土'.split('_'),
  longDateFormat: {
    l: 'YYYY/M/D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日(dddd) HH:mm',
    LTS: 'HH:mm:ss',
    LT: 'HH:mm'
  }
})
moment.tz.setDefault('Asia/Tokyo')

export default moment
