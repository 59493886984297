import globalMixins from '@/utils/mixins/GlobalMixins'

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      useI18: globalMixins.methods.useI18,
      sanitizedXssHtml: globalMixins.methods.sanitizedXssHtml
    }
  }
})
