const newDate = new Date()
const holidayNext3Years = [`${newDate.getFullYear()}`, `${newDate.getFullYear() + 1}`, `${newDate.getFullYear() + 2}`]
// Issue 5396: If you change this list, then sync the changes to the HOLIDAY_LIST list on the API
export const HOLIDAY_LIST = [
  '2022-01-01',
  '2022-01-10',
  '2022-02-11',
  '2022-02-23',
  '2022-03-21',
  '2022-04-29',
  '2022-05-03',
  '2022-05-04',
  '2022-05-05',
  '2022-07-18',
  '2022-08-11',
  '2022-09-19',
  '2022-09-23',
  '2022-10-10',
  '2022-11-03',
  '2022-11-23',
  '2023-01-01',
  '2023-01-02',
  '2023-01-09',
  '2023-02-11',
  '2023-02-23',
  '2023-03-21',
  '2023-04-29',
  '2023-05-03',
  '2023-05-04',
  '2023-05-05',
  '2023-07-17',
  '2023-08-11',
  '2023-09-18',
  '2023-09-23',
  '2023-10-09',
  '2023-11-03',
  '2023-11-23',
  '2024-01-01',
  '2024-01-08',
  '2024-02-11',
  '2024-02-12',
  '2024-02-23',
  '2024-03-20',
  '2024-04-29',
  '2024-05-03',
  '2024-05-04',
  '2024-05-05',
  '2024-05-06',
  '2024-07-15',
  '2024-08-11',
  '2024-08-12',
  '2024-09-16',
  '2024-09-22',
  '2024-09-23',
  '2024-10-14',
  '2024-11-03',
  '2024-11-04',
  '2024-11-23',
  '2025-01-01',
  '2025-01-13',
  '2025-02-11',
  '2025-02-23',
  '2025-02-24',
  '2025-03-20',
  '2025-04-29',
  '2025-05-03',
  '2025-05-04',
  '2025-05-05',
  '2025-05-06',
  '2025-07-21',
  '2025-08-11',
  '2025-09-15',
  '2025-09-23',
  '2025-10-13',
  '2025-11-03',
  '2025-11-23',
  '2025-11-24',
  '2026-01-01',
  '2026-01-12',
  '2026-02-11',
  '2026-02-23',
  '2026-03-20',
  '2026-04-29',
  '2026-05-03',
  '2026-05-04',
  '2026-05-05',
  '2026-05-06',
  '2026-07-20',
  '2026-08-11',
  '2026-09-21',
  '2026-09-22',
  '2026-09-23',
  '2026-10-12',
  '2026-11-03',
  '2026-11-23',
  '2027-01-01',
  '2027-01-11',
  '2027-02-11',
  '2027-02-23',
  '2027-03-21',
  '2027-03-22',
  '2027-04-29',
  '2027-05-03',
  '2027-05-04',
  '2027-05-05',
  '2027-07-19',
  '2027-08-11',
  '2027-09-20',
  '2027-09-23',
  '2027-10-11',
  '2027-11-03',
  '2027-11-23',
  '2028-01-01',
  '2028-01-10',
  '2028-02-11',
  '2028-02-23',
  '2028-03-20',
  '2028-04-29',
  '2028-05-03',
  '2028-05-04',
  '2028-05-05',
  '2028-07-17',
  '2028-08-11',
  '2028-09-18',
  '2028-09-22',
  '2028-10-09',
  '2028-11-03',
  '2028-11-23',
  '2029-01-01',
  '2029-01-08',
  '2029-02-11',
  '2029-02-12',
  '2029-02-23',
  '2029-03-20',
  '2029-04-29',
  '2029-04-30',
  '2029-05-03',
  '2029-05-04',
  '2029-05-05',
  '2029-07-16',
  '2029-08-11',
  '2029-09-17',
  '2029-09-23',
  '2029-09-24',
  '2029-10-08',
  '2029-11-03',
  '2029-11-23',
  '2030-01-01',
  '2030-01-14',
  '2030-02-11',
  '2030-02-23',
  '2030-03-20',
  '2030-04-29',
  '2030-05-03',
  '2030-05-04',
  '2030-05-05',
  '2030-05-06',
  '2030-07-15',
  '2030-08-11',
  '2030-08-12',
  '2030-09-16',
  '2030-09-23',
  '2030-10-14',
  '2030-11-03',
  '2030-11-04',
  '2030-11-23',
  '2031-01-01',
  '2031-01-13',
  '2031-02-11',
  '2031-02-23',
  '2031-02-24',
  '2031-03-21',
  '2031-04-29',
  '2031-05-03',
  '2031-05-04',
  '2031-05-05',
  '2031-05-06',
  '2031-07-21',
  '2031-08-11',
  '2031-09-15',
  '2031-09-23',
  '2031-10-13',
  '2031-11-03',
  '2031-11-23',
  '2031-11-24',
  '2032-01-01',
  '2032-01-12',
  '2032-02-11',
  '2032-02-23',
  '2032-03-20',
  '2032-04-29',
  '2032-05-03',
  '2032-05-04',
  '2032-05-05',
  '2032-07-19',
  '2032-08-11',
  '2032-09-20',
  '2032-09-21',
  '2032-09-22',
  '2032-10-11',
  '2032-11-03',
  '2032-11-23',
  '2033-01-01',
  '2033-01-10',
  '2033-02-11',
  '2033-02-23',
  '2033-03-20',
  '2033-03-21',
  '2033-04-29',
  '2033-05-03',
  '2033-05-04',
  '2033-05-05',
  '2033-07-18',
  '2033-08-11',
  '2033-09-19',
  '2033-09-23',
  '2033-10-10',
  '2033-11-03',
  '2033-11-23',
  '2034-01-01',
  '2034-01-02',
  '2034-01-09',
  '2034-02-11',
  '2034-02-23',
  '2034-03-20',
  '2034-04-29',
  '2034-05-03',
  '2034-05-04',
  '2034-05-05',
  '2034-07-17',
  '2034-08-11',
  '2034-09-18',
  '2034-09-23',
  '2034-10-09',
  '2034-11-03',
  '2034-11-23',
  '2035-01-01',
  '2035-01-08',
  '2035-02-11',
  '2035-02-12',
  '2035-02-23',
  '2035-03-21',
  '2035-04-29',
  '2035-04-30',
  '2035-05-03',
  '2035-05-04',
  '2035-05-05',
  '2035-07-16',
  '2035-08-11',
  '2035-09-17',
  '2035-09-23',
  '2035-09-24',
  '2035-10-08',
  '2035-11-03',
  '2035-11-23',
  '2036-01-01',
  '2036-01-14',
  '2036-02-11',
  '2036-02-23',
  '2036-03-20',
  '2036-04-29',
  '2036-05-03',
  '2036-05-04',
  '2036-05-05',
  '2036-05-06',
  '2036-07-21',
  '2036-08-11',
  '2036-09-15',
  '2036-09-22',
  '2036-10-13',
  '2036-11-03',
  '2036-11-23',
  '2036-11-24',
  '2037-01-01',
  '2037-01-12',
  '2037-02-11',
  '2037-02-23',
  '2037-03-20',
  '2037-04-29',
  '2037-05-03',
  '2037-05-04',
  '2037-05-05',
  '2037-05-06',
  '2037-07-20',
  '2037-08-11',
  '2037-09-21',
  '2037-09-22',
  '2037-09-23',
  '2037-10-12',
  '2037-11-03',
  '2037-11-23',
  '2038-01-01',
  '2038-01-11',
  '2038-02-11',
  '2038-02-23',
  '2038-03-20',
  '2038-04-29',
  '2038-05-03',
  '2038-05-04',
  '2038-05-05',
  '2038-07-19',
  '2038-08-11',
  '2038-09-20',
  '2038-09-23',
  '2038-10-11',
  '2038-11-03',
  '2038-11-23',
  '2039-01-01',
  '2039-01-10',
  '2039-02-11',
  '2039-02-23',
  '2039-03-21',
  '2039-04-29',
  '2039-05-03',
  '2039-05-04',
  '2039-05-05',
  '2039-07-18',
  '2039-08-11',
  '2039-09-19',
  '2039-09-23',
  '2039-10-10',
  '2039-11-03',
  '2039-11-23',
  '2040-01-01',
  '2040-01-02',
  '2040-01-09',
  '2040-02-11',
  '2040-02-23',
  '2040-03-20',
  '2040-04-29',
  '2040-04-30',
  '2040-05-03',
  '2040-05-04',
  '2040-05-05',
  '2040-07-16',
  '2040-08-11',
  '2040-09-17',
  '2040-09-22',
  '2040-10-08',
  '2040-11-03',
  '2040-11-23',
  '2041-01-01',
  '2041-01-14',
  '2041-02-11',
  '2041-02-23',
  '2041-03-20',
  '2041-04-29',
  '2041-05-03',
  '2041-05-04',
  '2041-05-05',
  '2041-05-06',
  '2041-07-15',
  '2041-08-11',
  '2041-08-12',
  '2041-09-16',
  '2041-09-23',
  '2041-10-14',
  '2041-11-03',
  '2041-11-04',
  '2041-11-23',
  '2042-01-01',
  '2042-01-13',
  '2042-02-11',
  '2042-02-23',
  '2042-02-24',
  '2042-03-20',
  '2042-04-29',
  '2042-05-03',
  '2042-05-04',
  '2042-05-05',
  '2042-05-06',
  '2042-07-21',
  '2042-08-11',
  '2042-09-15',
  '2042-09-23',
  '2042-10-13',
  '2042-11-03',
  '2042-11-23',
  '2042-11-24',
  '2043-01-01',
  '2043-01-12',
  '2043-02-11',
  '2043-02-23',
  '2043-03-21',
  '2043-04-29',
  '2043-05-03',
  '2043-05-04',
  '2043-05-05',
  '2043-05-06',
  '2043-07-20',
  '2043-08-11',
  '2043-09-21',
  '2043-09-22',
  '2043-09-23',
  '2043-10-12',
  '2043-11-03',
  '2043-11-23',
  '2044-01-01',
  '2044-01-11',
  '2044-02-11',
  '2044-02-23',
  '2044-03-20',
  '2044-03-21',
  '2044-04-29',
  '2044-05-03',
  '2044-05-04',
  '2044-05-05',
  '2044-07-18',
  '2044-08-11',
  '2044-09-19',
  '2044-09-22',
  '2044-10-10',
  '2044-11-03',
  '2044-11-23',
  '2045-01-01',
  '2045-01-02',
  '2045-01-09',
  '2045-02-11',
  '2045-02-23',
  '2045-03-20',
  '2045-04-29',
  '2045-05-03',
  '2045-05-04',
  '2045-05-05',
  '2045-07-17',
  '2045-08-11',
  '2045-09-18',
  '2045-09-22',
  '2045-10-09',
  '2045-11-03',
  '2045-11-23',
  '2046-01-01',
  '2046-01-08',
  '2046-02-11',
  '2046-02-12',
  '2046-02-23',
  '2046-03-20',
  '2046-04-29',
  '2046-04-30',
  '2046-05-03',
  '2046-05-04',
  '2046-05-05',
  '2046-07-16',
  '2046-08-11',
  '2046-09-17',
  '2046-09-23',
  '2046-09-24',
  '2046-10-08',
  '2046-11-03',
  '2046-11-23',
  '2047-01-01',
  '2047-01-14',
  '2047-02-11',
  '2047-02-23',
  '2047-03-21',
  '2047-04-29',
  '2047-05-03',
  '2047-05-04',
  '2047-05-05',
  '2047-05-06',
  '2047-07-15',
  '2047-08-11',
  '2047-08-12',
  '2047-09-16',
  '2047-09-23',
  '2047-10-14',
  '2047-11-03',
  '2047-11-04',
  '2047-11-23',
  '2048-01-01',
  '2048-01-13',
  '2048-02-11',
  '2048-02-23',
  '2048-02-24',
  '2048-03-20',
  '2048-04-29',
  '2048-05-03',
  '2048-05-04',
  '2048-05-05',
  '2048-05-06',
  '2048-07-20',
  '2048-08-11',
  '2048-09-21',
  '2048-09-22',
  '2048-10-12',
  '2048-11-03',
  '2048-11-23',
  '2049-01-01',
  '2049-01-11',
  '2049-02-11',
  '2049-02-23',
  '2049-03-20',
  '2049-04-29',
  '2049-05-03',
  '2049-05-04',
  '2049-05-05',
  '2049-07-19',
  '2049-08-11',
  '2049-09-20',
  '2049-09-21',
  '2049-09-22',
  '2049-10-11',
  '2049-11-03',
  '2049-11-23',
  '2050-01-01',
  '2050-01-10',
  '2050-02-11',
  '2050-02-23',
  '2050-03-20',
  '2050-03-21',
  '2050-04-29',
  '2050-05-03',
  '2050-05-04',
  '2050-05-05',
  '2050-07-18',
  '2050-08-11',
  '2050-09-19',
  '2050-09-23',
  '2050-10-10',
  '2050-11-03',
  '2050-11-23'
].filter(x => holidayNext3Years.includes(x.split('-')[0]))
