<template>
  <div :class="customClasses" class="flashMessage">
    <div
      v-for="(msg, i) in messageList"
      :key="i"
      :class="{ marginB3: isMembershipConfirm, large: isNotFoundPage || isPurchaseSuccess, foundationAlert: isNoEventOrNotFoundPage }"
      class="flashMessage-title"
    >
      <icon v-if="icon" :fas="icon" class="flashMessage-icon" />
      <span v-html="$sanitizedXssHtml(msg)"></span>
    </div>
    <div
      v-if="flashMessageSubtitle"
      :class="{ foundationAlert: isNoEventOrNotFoundPage, foundationSuccess: isPurchaseSuccess }"
      class="flashMessage-subtitle"
    >
      <span v-html="$sanitizedXssHtml(flashMessageSubtitle)"></span>
    </div>
    <div v-if="flashMessageContent" :class="{ customForNotFound: isNotFoundPage }" class="flashMessage-slot marginT4">
      <span v-for="(message, index) in flashMessageContent" :key="index" class="messageContent" v-html="$sanitizedXssHtml(message)"></span>
    </div>
    <div>
      <custom-nuxt-link v-if="isNoEventOrNotFoundPage" to="/search" class="commonButton marginT4 btn-primary-50">{{
        linkTextSearchPage
      }}</custom-nuxt-link>
    </div>
    <div v-if="isNotFoundPage" class="notFound alignCenter marginT4">
      <custom-nuxt-link to="/" class="linkText">トップページに戻る</custom-nuxt-link>
    </div>
    <div class="flashMessage-slot"><slot /></div>
  </div>
</template>

<script>
const ICON = {
  success: 'fa-check',
  warning: 'fa-exclamation-triangle',
  error: 'fa-ban',
  info: 'fa-info-circle'
}
export default {
  props: {
    type: { type: String, default: '', required: true },
    message: { type: String, default: null, required: false },
    messages: { type: Array, default: null, required: false },
    flashMessageSubtitle: { type: String, default: null, required: false },
    flashMessageContent: { type: Array, default: null, required: false },
    isMembershipConfirm: { type: Boolean, default: false, required: false },
    customDesignForEventList: { type: Boolean, default: false, required: false },
    customDesignForPastEvent: { type: Boolean, default: false, required: false },
    linkTextSearchPage: { type: String, default: '検索条件を変更する', required: false }
  },
  computed: {
    icon() {
      if (this.hasIcon) return ICON[this.type]
    },
    messageList() {
      if (this.messages) return this.messages
      if (this.message) return [this.message]
      return ['']
    },
    hasIcon() {
      return ICON.hasOwnProperty(this.type)
    },
    isNoEventOrNotFoundPage() {
      return this.isNotFoundPage || this.isNoResultPage
    },
    isNotFoundPage() {
      return this.type === 'notFound'
    },
    isPurchaseSuccess() {
      return this.type === 'purchaseSuccess'
    },
    isNoResultPage() {
      return this.type === 'noResult'
    },
    customClasses() {
      const classes = [this.type]
      if (this.customDesignForPastEvent) classes.push('forPastEvent')
      if (this.customDesignForEventList) classes.push('forEventList')
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.flashMessage {
  padding: 1.6rem 1rem;
  position: relative;
  border-radius: 3px;
  &.success,
  &.purchaseSuccess {
    background-color: $foundation-success-light;
    .flashMessage-icon {
      color: $foundation-success;
    }
  }
  &.warning {
    background-color: $foundation-warning-light;
    .flashMessage-icon {
      color: $foundation-warning;
    }
  }
  &.error {
    background-color: $foundation-alert-light;
    border-color: $orange;
    .flashMessage-icon {
      color: $foundation-alert;
    }
  }
  &.info {
    background-color: $foundation-info-light;
    .flashMessage-icon {
      color: $foundation-info;
    }
  }
  &.forEventList {
    padding: 8rem 1rem;
    text-align: center;
    @include sp {
      padding: 5rem 1rem;
    }
  }
  &.forPastEvent,
  &.noResult,
  &.notFound,
  &.purchaseSuccess,
  &.noTicketMessage {
    padding: 2rem 1.6rem;
    text-align: center;
  }
  &.noResult,
  &.notFound {
    background-color: $white !important;
    .linkText {
      font-size: 12px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-icon {
    width: 26px;
    font-size: 1.8rem;
  }
  &-title {
    font-size: 1.6rem;
    font-weight: bold;
    &.large {
      font-size: 2rem;
      &:not(:first-child) {
        margin-top: 0;
        margin-left: 3.8rem !important;
      }
    }
  }
  &-subtitle {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: $foundation-alert;
  }
  .foundationAlert {
    text-align: center;
    color: $foundation-alert;
  }
  .foundationSuccess {
    text-align: center;
    color: $green-dark;
  }
  &-slot {
    font-size: 1.3rem;
    color: $base-light;
    .messageContent {
      display: block;
    }
    @media screen and (max-width: 530px) {
      text-align: left;
    }
  }
  .customForNotFound .messageContent:first-child {
    margin-bottom: 10px;
  }
}
</style>
