import Cookies from 'mj-cookie'
import { COOKIE_PROFILE_FOR_SEARCH_KEYS } from '@/utils/constants/commons'

export default class GlobalCookie {
  constructor(store) {
    this.store = store
  }

  get(key) {
    return import.meta.server ? this.store.ssrCookie[key] : Cookies.get(key)
  }

  get firstTimeAccessPage() {
    return !COOKIE_PROFILE_FOR_SEARCH_KEYS.some(v => this.get(v))
  }
}
