import { useMainStore } from '@/store'
import { buildQueryString } from '@/utils/url-action'
import { apiGet, apiGetV2, apiPost, apiPut, apiPatch, apiDelete, apiPostForm } from './api-core'
import { isDatingEventCacheUseable } from './shared'
import Cookies from 'mj-cookie'

const getBookmarkedEvents = payload => {
  const bookmarkedEventsEndpoint = 'event_bookmarks'
  if (payload.isLoggedIn) {
    return apiGet(`${bookmarkedEventsEndpoint}/${buildQueryString({ page: payload.page, no_pagination: payload.noPagination })}`)
  }
  return apiGet(
    `${bookmarkedEventsEndpoint}/guest${buildQueryString({ 'event_ids[]': payload.eventIds, page: payload.page, no_pagination: payload.noPagination })}`
  )
}

const getGoogleForm = payload => {
  const googleFormUrl = payload.event_id ? `google_forms/${payload.code}?event_id=${payload.event_id}` : `google_forms/${payload.code}`
  return apiGet(googleFormUrl)
}

const getRecommendedEvents = payload => {
  const query = payload ? buildQueryString(payload) : ''
  return apiGet(`ranking_events_for_mypage/${query}`)
}

const getStaticContents = path => {
  const mainStore = useMainStore()
  const query = buildQueryString({ path })
  return apiGetV2(`static_contents/${query}`).then(res => {
    mainStore.static_contents = res.data
  })
}

const bookmarkEvent = payload => {
  return apiPost('event_bookmarks', payload)
}

const mjClCreateProfile = payload => {
  return apiPost('cl_mj_user_profiles/', payload)
}

const removeEventBookmark = eventId => {
  return apiDelete(`event_bookmarks/${eventId}`)
}

const logout = () => {
  return apiDelete('logout')
}

const purchaseNew = payload => {
  const query = { quantity: payload.quantity, ticket_type: payload.ticketType, using_point: payload.usingPoint }
  if (payload.applyCoupon) {
    query.apply_coupon = true
    query.coupon_code = payload.couponCode
  }
  if (payload.redirectPath) query.client_redirect_path = payload.redirectPath
  if (payload.checkoutSessionId) query.checkout_session_id = payload.checkoutSessionId
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/purchases/new/${buildQueryString(query)}`
  return apiGet(apiUri)
}

const purchaseAmazonGuestNew = payload => {
  const query = { quantity: payload.quantity, ticket_type: payload.ticketType, checkout_session_id: payload.checkoutSessionId }
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/amzpay_new/${buildQueryString(query)}`
  return apiGet(apiUri)
}

const postAmazonPurchase = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/amzpay_pre_purchase`
  return apiPost(apiUri, payload.postData)
}

const guestPurchaseNew = payload => {
  const query = { quantity: payload.quantity, ticket_type: payload.ticketType }
  if (payload.redirectPath) query.client_redirect_path = payload.redirectPath
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/guest_new/${buildQueryString(query)}`
  return apiGet(apiUri)
}

const postGuestValidate = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/guest_validate`
  return apiPostForm(apiUri, payload.postData)
}

const verifyOtp = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/verify_otp`
  return apiPost(apiUri, { code: payload.code, stripe_payment_token: payload.stripe_payment_token })
}

const amzpayVerifyOtp = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/amzpay_verify_otp`
  return apiPost(apiUri, { code: payload.code })
}

const fixPurchase = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/fix_purchase`
  return apiPost(apiUri)
}

const amzPayFixPurchase = payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/amzpay_fixed_purchase`
  return apiPost(apiUri)
}

const postPurchase = async payload => {
  const apiUri = `events/${payload.eventCode}/tickets/${payload.ticketId}/purchases`
  const res = await apiPost(apiUri, payload.postData)
  return res.data
}

const postGoogleForm = payload => {
  const googleFormUrl = payload.event_id ? `google_forms/${payload.code}?event_id=${payload.event_id}` : `google_forms/${payload.code}`
  return apiPost(googleFormUrl, JSON.stringify(payload.answers))
}

const createBankAccount = payload => {
  return apiPost('bank_accounts', payload)
}

const updateBankAccount = payload => {
  return apiPut('bank_accounts', payload)
}

const createQuestionnaire = payload => {
  return apiPost('questionnaires', payload)
}

const createSurveyAnswers = payload => {
  const apiUri = `/surveys/${payload.survey_id}/survey_question_answers`
  return apiPost(apiUri, payload)
}

const postBizInquiry = payload => {
  return apiPost('biz_inquiry', payload)
}

const postBizInquiryForm = payload => {
  return apiPostForm('biz_inquiry', payload)
}

const postOrganizerInquiryForm = payload => {
  return apiPostForm('organizer_inquiry', payload)
}

const postOrganizerInquiry = payload => {
  return apiPost('organizer_inquiry', payload)
}

const postRestaurantInquiry = payload => {
  return apiPost('restaurant_inquiry', payload)
}

const postRestaurantInquiryForm = payload => {
  return apiPostForm('restaurant_inquiry', payload)
}

const receiveCheckInTickets = payload => {
  return apiPatch('check_in_tickets/receive/', { token: payload.ticketToken })
}

const postAfbConversion = payload => {
  return apiPost('send_afb_conversion', payload)
}

const postEventRecentCheck = payload => {
  return apiPost('event_recent_checks', payload)
}

const postOTPConfirmation = payload => {
  return apiPost('users/otp_confirmation', { code: payload.code })
}

const getBannerHeader = async () => {
  return apiGet('banner_header/').then(res => {
    const mainStore = useMainStore()
    mainStore.LAYOUT_DATA({
      type: 'banner_header',
      data: {
        last_updated_at: Date.now(),
        data: res.data.banner_header_setting
      }
    })

    return res
  })
}

const getRecommendEventsForPastEvent = payload => {
  const query = payload ? buildQueryString(payload) : ''
  return apiGet(`recommend_events_for_past_event/${query}`, payload)
}

const postRegisterAmazonAccount = payload => {
  return apiPost('users/register_amazon_account', payload)
}

const postEventContact = payload => {
  return apiPost('event_contacts/', payload)
}

const updateEventContact = payload => {
  return apiPut(`event_contacts/${payload.id}`, payload)
}

const postNoIndexPage = payload => {
  return apiPost('sitemaps/sitemap_noindex_cache', payload)
}

const sendNotifyNewMessage = payload => {
  return apiPost('event_contacts/notify_new_message', payload)
}

const createOrUpdateKeywordSuggestion = payload => {
  return apiPost('keyword_suggestions', payload)
}

const createUserDeviceToken = data => {
  return apiPost('user_device_tokens', data)
}

const createUserClickPurchaseEvent = data => {
  return apiPost('user_clicked_purchase_events', data)
}

const questionnaireRemind = () => {
  const mainStore = useMainStore()
  return apiPatch('check_in_tickets/remind_questionnaire').then(res => {
    Cookies.set('questionnaireRemind', 'reminded', { expires: 1 })
    if (res.data.questionnaire_code) mainStore.MODAL({ name: 'QuestionnaireRemindModal', payload: res.data })
  })
}

const noNeedRemind = payload => {
  const code = payload.code
  return apiPatch('check_in_tickets/no_need_remind', { code })
}

const fetchDatingEvents = async () => {
  const mainStore = useMainStore()
  if (isDatingEventCacheUseable()) return mainStore.$state.dating_event_data

  return apiGet(`dating_events`).then(res => {
    const cache = { ...res.data, cached_at: Date.now() }
    mainStore.SET_STATE({ key: 'dating_event_data', value: cache })
    return cache
  })
}

const fetchDatingEvent = async datingEventId => {
  const mainStore = useMainStore()
  const finder = event => event.id === parseInt(datingEventId)
  if (isDatingEventCacheUseable()) {
    return {
      event: mainStore.dating_event_data.events.find(finder),
      has_cl_account: mainStore.dating_event_data.has_cl_account
    }
  } else {
    const cache = await fetchDatingEvents()
    return {
      event: cache.events.find(finder),
      has_cl_account: cache.has_cl_account
    }
  }
}

export {
  getBookmarkedEvents,
  getGoogleForm,
  getRecommendedEvents,
  getStaticContents,
  bookmarkEvent,
  mjClCreateProfile,
  removeEventBookmark,
  logout,
  purchaseNew,
  purchaseAmazonGuestNew,
  postAmazonPurchase,
  guestPurchaseNew,
  postGuestValidate,
  verifyOtp,
  amzpayVerifyOtp,
  fixPurchase,
  amzPayFixPurchase,
  postPurchase,
  postGoogleForm,
  createBankAccount,
  updateBankAccount,
  createQuestionnaire,
  createSurveyAnswers,
  postBizInquiry,
  postBizInquiryForm,
  postOrganizerInquiry,
  postOrganizerInquiryForm,
  postRestaurantInquiry,
  postRestaurantInquiryForm,
  receiveCheckInTickets,
  postAfbConversion,
  postEventRecentCheck,
  postOTPConfirmation,
  getBannerHeader,
  getRecommendEventsForPastEvent,
  postRegisterAmazonAccount,
  postEventContact,
  updateEventContact,
  postNoIndexPage,
  sendNotifyNewMessage,
  createOrUpdateKeywordSuggestion,
  createUserDeviceToken,
  createUserClickPurchaseEvent,
  questionnaireRemind,
  noNeedRemind,
  fetchDatingEvents,
  fetchDatingEvent
}
