export const AREA_GROUPS = [
  {
    id: 1,
    name: '札幌',
    slug: 'ag0101',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido'
  },
  {
    id: 2,
    name: '函館',
    slug: 'ag0103',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido'
  },
  {
    id: 3,
    name: '旭川',
    slug: 'ag0102',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido'
  },
  {
    id: 4,
    name: '北海道その他',
    slug: 'ag0199',
    region_slug: 'hokkaido',
    prefecture_slug: 'hokkaido'
  },
  {
    id: 7,
    name: '仙台',
    slug: 'ag0401',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi'
  },
  {
    id: 8,
    name: '宮城県その他',
    slug: 'ag0499',
    region_slug: 'tohoku',
    prefecture_slug: 'miyagi'
  },
  {
    id: 10,
    name: '山形県全域',
    slug: 'ag0600',
    region_slug: 'tohoku',
    prefecture_slug: 'yamagata'
  },
  {
    id: 6,
    name: '岩手県全域',
    slug: 'ag0300',
    region_slug: 'tohoku',
    prefecture_slug: 'iwate'
  },
  {
    id: 11,
    name: '福島県全域',
    slug: 'ag0700',
    region_slug: 'tohoku',
    prefecture_slug: 'fukushima'
  },
  {
    id: 9,
    name: '秋田県全域',
    slug: 'ag0500',
    region_slug: 'tohoku',
    prefecture_slug: 'akita'
  },
  {
    id: 5,
    name: '青森県全域',
    slug: 'ag0200',
    region_slug: 'tohoku',
    prefecture_slug: 'aomori'
  },
  {
    id: 19,
    name: '丸の内・八重洲/東京駅・大手町・日本橋',
    slug: 'ag1301',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 20,
    name: '銀座・新橋・有楽町',
    slug: 'ag1302',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 21,
    name: '飯田橋・神楽坂',
    slug: 'ag1310',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 22,
    name: '神田・秋葉原',
    slug: 'ag1312',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 23,
    name: '六本木・赤坂',
    slug: 'ag1306',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 24,
    name: '表参道・青山・原宿',
    slug: 'ag1308',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 25,
    name: '新宿',
    slug: 'ag1304',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 26,
    name: '上野・浅草',
    slug: 'ag1311',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 27,
    name: '品川・目黒・五反田',
    slug: 'ag1307',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 28,
    name: '恵比寿・代官山・中目黒・自由が丘',
    slug: 'ag1309',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 29,
    name: '渋谷',
    slug: 'ag1305',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 30,
    name: '池袋',
    slug: 'ag1303',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 31,
    name: '八王子・立川',
    slug: 'ag1315',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 32,
    name: '吉祥寺',
    slug: 'ag1313',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 33,
    name: '町田',
    slug: 'ag1314',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 34,
    name: '東京都その他',
    slug: 'ag1399',
    region_slug: 'kanto',
    prefecture_slug: 'tokyo'
  },
  {
    id: 35,
    name: '横浜',
    slug: 'ag1401',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 36,
    name: '川崎',
    slug: 'ag1402',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 37,
    name: '相模原',
    slug: 'ag1403',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 38,
    name: '湘南（鎌倉・藤沢）',
    slug: 'ag1404',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 39,
    name: '小田原',
    slug: 'ag1405',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 40,
    name: '神奈川県その他',
    slug: 'ag1499',
    region_slug: 'kanto',
    prefecture_slug: 'kanagawa'
  },
  {
    id: 17,
    name: '千葉',
    slug: 'ag1201',
    region_slug: 'kanto',
    prefecture_slug: 'chiba'
  },
  {
    id: 18,
    name: '千葉県その他',
    slug: 'ag1299',
    region_slug: 'kanto',
    prefecture_slug: 'chiba'
  },
  {
    id: 15,
    name: 'さいたま',
    slug: 'ag1101',
    region_slug: 'kanto',
    prefecture_slug: 'saitama'
  },
  {
    id: 16,
    name: '埼玉県その他',
    slug: 'ag1199',
    region_slug: 'kanto',
    prefecture_slug: 'saitama'
  },
  {
    id: 14,
    name: '群馬県全域',
    slug: 'ag1000',
    region_slug: 'kanto',
    prefecture_slug: 'gunma'
  },
  {
    id: 13,
    name: '栃木県全域',
    slug: 'ag0900',
    region_slug: 'kanto',
    prefecture_slug: 'tochigi'
  },
  {
    id: 12,
    name: '茨城県全域',
    slug: 'ag0800',
    region_slug: 'kanto',
    prefecture_slug: 'ibaraki'
  },
  {
    id: 42,
    name: '富山県全域',
    slug: 'ag1600',
    region_slug: 'hokuriku',
    prefecture_slug: 'toyama'
  },
  {
    id: 45,
    name: '山梨県全域',
    slug: 'ag1900',
    region_slug: 'hokuriku',
    prefecture_slug: 'yamanashi'
  },
  {
    id: 41,
    name: '新潟県全域',
    slug: 'ag1500',
    region_slug: 'hokuriku',
    prefecture_slug: 'niigata'
  },
  {
    id: 43,
    name: '石川県全域',
    slug: 'ag1700',
    region_slug: 'hokuriku',
    prefecture_slug: 'ishikawa'
  },
  {
    id: 44,
    name: '福井県全域',
    slug: 'ag1800',
    region_slug: 'hokuriku',
    prefecture_slug: 'fukui'
  },
  {
    id: 46,
    name: '長野県全域',
    slug: 'ag2000',
    region_slug: 'hokuriku',
    prefecture_slug: 'nagano'
  },
  {
    id: 51,
    name: '名古屋',
    slug: 'ag2301',
    region_slug: 'tokai',
    prefecture_slug: 'aichi'
  },
  {
    id: 52,
    name: '豊橋・豊田・岡崎',
    slug: 'ag2302',
    region_slug: 'tokai',
    prefecture_slug: 'aichi'
  },
  {
    id: 53,
    name: '一宮・犬山',
    slug: 'ag2303',
    region_slug: 'tokai',
    prefecture_slug: 'aichi'
  },
  {
    id: 54,
    name: '愛知県その他',
    slug: 'ag2399',
    region_slug: 'tokai',
    prefecture_slug: 'aichi'
  },
  {
    id: 48,
    name: '静岡',
    slug: 'ag2201',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka'
  },
  {
    id: 49,
    name: '浜松',
    slug: 'ag2202',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka'
  },
  {
    id: 50,
    name: '静岡県その他',
    slug: 'ag2299',
    region_slug: 'tokai',
    prefecture_slug: 'shizuoka'
  },
  {
    id: 55,
    name: '三重県全域',
    slug: 'ag2400',
    region_slug: 'tokai',
    prefecture_slug: 'mie'
  },
  {
    id: 47,
    name: '岐阜県全域',
    slug: 'ag2100',
    region_slug: 'tokai',
    prefecture_slug: 'gifu'
  },
  {
    id: 59,
    name: 'キタ',
    slug: 'ag2701',
    region_slug: 'kinki',
    prefecture_slug: 'osaka'
  },
  {
    id: 60,
    name: 'ミナミ',
    slug: 'ag2702',
    region_slug: 'kinki',
    prefecture_slug: 'osaka'
  },
  {
    id: 61,
    name: '大阪府北部',
    slug: 'ag2703',
    region_slug: 'kinki',
    prefecture_slug: 'osaka'
  },
  {
    id: 62,
    name: '大阪府南部',
    slug: 'ag2704',
    region_slug: 'kinki',
    prefecture_slug: 'osaka'
  },
  {
    id: 63,
    name: '大阪府その他',
    slug: 'ag2799',
    region_slug: 'kinki',
    prefecture_slug: 'osaka'
  },
  {
    id: 64,
    name: '神戸',
    slug: 'ag2801',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo'
  },
  {
    id: 65,
    name: '姫路',
    slug: 'ag2803',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo'
  },
  {
    id: 66,
    name: '阪神',
    slug: 'ag2802',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo'
  },
  {
    id: 67,
    name: '兵庫県その他',
    slug: 'ag2899',
    region_slug: 'kinki',
    prefecture_slug: 'hyogo'
  },
  {
    id: 57,
    name: '京都市内',
    slug: 'ag2601',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto'
  },
  {
    id: 58,
    name: '京都府その他',
    slug: 'ag2699',
    region_slug: 'kinki',
    prefecture_slug: 'kyoto'
  },
  {
    id: 69,
    name: '和歌山県全域',
    slug: 'ag3000',
    region_slug: 'kinki',
    prefecture_slug: 'wakayama'
  },
  {
    id: 68,
    name: '奈良県全域',
    slug: 'ag2900',
    region_slug: 'kinki',
    prefecture_slug: 'nara'
  },
  {
    id: 56,
    name: '滋賀県全域',
    slug: 'ag2500',
    region_slug: 'kinki',
    prefecture_slug: 'shiga'
  },
  {
    id: 78,
    name: '山口県全域',
    slug: 'ag3500',
    region_slug: 'chugoku',
    prefecture_slug: 'yamaguchi'
  },
  {
    id: 72,
    name: '岡山',
    slug: 'ag3301',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama'
  },
  {
    id: 73,
    name: '倉敷',
    slug: 'ag3302',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama'
  },
  {
    id: 74,
    name: '岡山県その他',
    slug: 'ag3399',
    region_slug: 'chugoku',
    prefecture_slug: 'okayama'
  },
  {
    id: 71,
    name: '島根県全域',
    slug: 'ag3200',
    region_slug: 'chugoku',
    prefecture_slug: 'shimane'
  },
  {
    id: 75,
    name: '広島',
    slug: 'ag3401',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima'
  },
  {
    id: 76,
    name: '福山',
    slug: 'ag3402',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima'
  },
  {
    id: 77,
    name: '広島県その他',
    slug: 'ag3403',
    region_slug: 'chugoku',
    prefecture_slug: 'hiroshima'
  },
  {
    id: 70,
    name: '鳥取県全域',
    slug: 'ag3100',
    region_slug: 'chugoku',
    prefecture_slug: 'tottori'
  },
  {
    id: 79,
    name: '徳島県全域',
    slug: 'ag3600',
    region_slug: 'shikoku',
    prefecture_slug: 'tokushima'
  },
  {
    id: 81,
    name: '愛媛県全域',
    slug: 'ag3800',
    region_slug: 'shikoku',
    prefecture_slug: 'ehime'
  },
  {
    id: 80,
    name: '香川県全域',
    slug: 'ag3700',
    region_slug: 'shikoku',
    prefecture_slug: 'kagawa'
  },
  {
    id: 82,
    name: '高知県全域',
    slug: 'ag3900',
    region_slug: 'shikoku',
    prefecture_slug: 'kochi'
  },
  {
    id: 83,
    name: '北九州',
    slug: 'ag4004',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka'
  },
  {
    id: 84,
    name: '博多・天神',
    slug: 'ag4001',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka'
  },
  {
    id: 85,
    name: '福岡市内その他',
    slug: 'ag4002',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka'
  },
  {
    id: 86,
    name: '久留米',
    slug: 'ag4003',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka'
  },
  {
    id: 87,
    name: '福岡県その他',
    slug: 'ag4099',
    region_slug: 'kyushu',
    prefecture_slug: 'fukuoka'
  },
  {
    id: 89,
    name: '長崎県全域',
    slug: 'ag4200',
    region_slug: 'kyushu',
    prefecture_slug: 'nagasaki'
  },
  {
    id: 88,
    name: '佐賀県全域',
    slug: 'ag4100',
    region_slug: 'kyushu',
    prefecture_slug: 'saga'
  },
  {
    id: 91,
    name: '大分県全域',
    slug: 'ag4400',
    region_slug: 'kyushu',
    prefecture_slug: 'oita'
  },
  {
    id: 92,
    name: '宮崎県全域',
    slug: 'ag4500',
    region_slug: 'kyushu',
    prefecture_slug: 'miyazaki'
  },
  {
    id: 90,
    name: '熊本県全域',
    slug: 'ag4300',
    region_slug: 'kyushu',
    prefecture_slug: 'kumamoto'
  },
  {
    id: 93,
    name: '鹿児島県全域',
    slug: 'ag4600',
    region_slug: 'kyushu',
    prefecture_slug: 'kagoshima'
  },
  {
    id: 94,
    name: '沖縄県全域',
    slug: 'ag4700',
    region_slug: 'kyushu',
    prefecture_slug: 'okinawa'
  },
  {
    id: 95,
    name: 'その他',
    slug: 'ag9900',
    region_slug: 'other',
    prefecture_slug: 'other'
  }
]
