<template>
  <div v-if="!pageDetector.isSearchDetailPage && recommendPersonalizedNew.length > 0" class="ab-issue5090-ph3">
    <scroll-event-list
      :event-list-title="RECOMMEND_PERSONALIZED"
      :events="recommendPersonalizedNew"
      :recommend-version="recommendVersionPersonalizedNew"
      :showed-block="PERSONALIZED_SHOWED_BLOCK_NEW"
      use-opix="opixAPIRecommendPersonalizedNew"
    />
  </div>
</template>
<script>
import { OPIX_EVENTS_CHECK_POINTS_RECOMMEND_PERSONALIZED } from '@/utils/constants/opixTracking'
import GlobalCookie from '@/utils/GlobalCookie'
import { buildQueryString } from '@/utils/url-action'
import TrackingUserMixin from '@/utils/mixins/TrackingUserMixin'
import PageDetector from '@/utils/PageDetector'
const ScrollEventList = defineAsyncComponent(() => import('@/components/event/ScrollEventList.vue'))
import { RECOMMEND_PERSONALIZED } from '@/utils/constants/commons'

const PERSONALIZED_SHOWED_BLOCK_NEW = 'AIRecommend_personalized_new'
export default {
  components: { ScrollEventList },
  mixins: [TrackingUserMixin],
  props: {
    prefectureSlug: { type: String, required: false, default: null },
    currentEventId: { type: Number, required: false, default: null }
  },
  data() {
    return {
      baseCheckPoints: [...OPIX_EVENTS_CHECK_POINTS_RECOMMEND_PERSONALIZED],
      PERSONALIZED_SHOWED_BLOCK_NEW,
      RECOMMEND_PERSONALIZED,
      recommendPersonalizedNew: [],
      recommendVersionPersonalizedNew: null
    }
  },
  computed: {
    pageDetector() {
      return new PageDetector(this.$route)
    },
    profileUser() {
      return this.$store.$state.my_profile
    }
  },
  mounted() {
    this.getRecommendPersonalized()
  },
  methods: {
    getRecommendPersonalized() {
      const globalCookie = new GlobalCookie(this.$store)
      const prefectureSlug = this.prefectureSlug || globalCookie.get('prefecture') || this.profileUser?.prefecture_slug
      if (!this.$store.isLoggedIn || !prefectureSlug) return

      const query = { prefecture_slug: prefectureSlug }
      if (this.currentEventId) query.current_event_id = this.currentEventId
      this.$store.dispatch('apiGet', `recommend_event_personalized_new/${buildQueryString(query)}`).then(res => {
        this.recommendPersonalizedNew = res.data.events
        this.recommendVersionPersonalizedNew = res.data.recommend_version
      })
    }
  }
}
</script>
