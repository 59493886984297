<template>
  <div>
    <h2 class="commonButton btn-secondary-40" @click="displayedRegions = !displayedRegions">{{ heading }}</h2>
    <transition-group name="accordion">
      <template v-if="displayedRegions">
        <ul v-for="(region, rIdx) in displayRegions" :key="rIdx">
          <li v-if="hasEvents(region.slug, 'region')" class="areaSelectorList-item">
            <span class="areaSelectorList-itemLink areaSelectorList-regionSelector" @click="changeOpeningRegions(region.slug)">{{
              region.name
            }}</span>
            <transition-group name="accordion">
              <template v-if="openingRegions.includes(region.slug)">
                <ul v-for="(pref, pIdx) in listPrefectures(region)" :key="pIdx">
                  <li v-if="hasEvents(pref.slug, 'prefecture')" class="areaSelectorList-item">
                    <custom-nuxt-link :to="eventsSearchLink(pref)" class="areaSelectorList-itemLink areaSelectorList-prefectureSelector">{{
                      prefShortName(pref)
                    }}</custom-nuxt-link>
                  </li>
                </ul>
              </template>
            </transition-group>
          </li>
        </ul>
      </template>
    </transition-group>
    <template v-if="pageDetector.isInterestEventsPage">
      <h2 class="marginT5 interestHeader">趣味コンをジャンルから探す</h2>
      <div class="interestTags">
        <div v-for="(interestCategory, index) in interestList" :key="`category${index}`" class="interestTags-item">
          <h3 class="interestTags-item-category marginB3">{{ interestCategory.name }}</h3>
          <ul class="listTag">
            <li v-for="(interest, i) in interestCategory.children" :key="`interest${i}`" class="tagList-item">
              <custom-nuxt-link :to="interest.link" class="tagList-item-link btn-tag">{{ interest.name }}</custom-nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageDetector from '@/utils/PageDetector'
import { listRegions, getListPrefectureByRegionSlug, getListAreaGroupByPrefectureSlug } from '@/models/places'
const DISPLAY_AREAGROUPS = ['ag0101', 'ag0401', 'ag1401', 'ag2301', 'ag2202', 'ag2801']
export default {
  props: {
    eventType: { type: String, default: null, required: false },
    customSearch: { type: Object, default: () => ({}), required: false },
    featureTag: { type: Object, default: () => ({}), required: false },
    promoter: { type: Object, default: () => ({}), required: false },
    organizer: { type: String, default: null, required: false },
    availableAreas: { type: Array, default: () => [], required: false }
  },
  data() {
    return {
      displayedRegions: false,
      openingRegions: []
    }
  },
  computed: {
    heading() {
      if (this.hasPromoter) return `${this.organizer}のイベントを開催地から探す`
      if (this.hasCustomSearch) return `${this.customSearch.name}のイベントを開催地から探す`
      if (this.hasFeatureTag) return `${this.featureTag.name}を開催地から探す`
      if (this.eventType) return `${this.$useI18(`event.attributes.event_type.${this.eventType}`)}を開催地から探す`
      if (this.pageDetector.isInterestEventsPage) return '趣味コンを開催地から探す'
      if (this.pageDetector.isFixedEventsPage) return '開催確定イベントを開催地から探す'
      return '街コンを開催地から探す'
    },
    hasCustomSearch() {
      return Object.keys(this.customSearch).length > 0
    },
    hasFeatureTag() {
      return Object.keys(this.featureTag).length > 0
    },
    hasPromoter() {
      return Object.keys(this.promoter).length > 0
    },
    displayRegions() {
      return listRegions().filter(v => v.slug !== 'other')
    },
    suffixRoutePath() {
      if (this.hasFeatureTag) return `features/${this.featureTag.slug}`
      if (this.eventType === 'activity') return 'interests/activity/'
      if (this.eventType) return `categories/${this.eventType}`
      if (this.pageDetector.isInterestEventsPage) return 'interests/'
      return ''
    },
    interestList() {
      const sideContents = this.$store.$state.layout_data.side_contents
      const interestList = sideContents && sideContents.contents && sideContents.contents.interest && sideContents.contents.interest.list
      const interests = interestList
        ? interestList
            .filter(interst => interst.parent_slug !== null)
            .reduce(function (rx, x) {
              ;(rx[x.parent_slug] = rx[x.parent_slug] || []).push(x)
              return rx
            }, {})
        : {}
      const interestCategory = []
      Object.keys(interests).forEach(function (key) {
        const interest = {}
        const interestCate = interestList.find(element => element.slug === key)
        if (interestCate) {
          interest['name'] = interestCate.name
          const temp = {
            name: `${interestCate.name}の全て`,
            link: interestCate.link,
            parent_slug: interestCate.slug,
            slug: null
          }
          interests[key].unshift(temp)
          interest['children'] = interests[key]
          interestCategory.push(interest)
        }
      })
      return interestCategory
    }
  },
  created() {
    this.pageDetector = new PageDetector(this.$route)
  },
  methods: {
    listAreaGroups(prefecture) {
      return getListAreaGroupByPrefectureSlug(prefecture.slug)
    },
    listPrefectures(region) {
      return getListPrefectureByRegionSlug(region.slug)
    },
    canDisplayAreaGroup(slug) {
      if (this.pageDetector.isFixedEventsPage) return false
      return DISPLAY_AREAGROUPS.includes(slug)
    },
    prefShortName(pref) {
      return pref.name.replace(/(都|県|府)$/, '')
    },
    hasEvents(slug, type) {
      if (!this.hasCustomSearch && !this.hasFeatureTag && !this.hasPromoter) return true
      return this.availableAreas.find(v => v.slug === slug && v.type === type)
    },
    eventsSearchLink(pref, areaGroup = null) {
      if (this.hasCustomSearch) return this.customSearchLink(pref, areaGroup)
      if (this.pageDetector.isFixedEventsPage) return `/fixed/${pref.slug}/`
      const areaPath = areaGroup ? `/areas/${pref.slug}/${areaGroup.slug}/` : `/areas/${pref.slug}/`
      const defaultPath = `${areaPath}${this.suffixRoutePath}`
      if (this.pageDetector.isPromoterEventsPage && this.promoter && !this.$route.path.includes('/area')) {
        return `${defaultPath}promoters/${this.promoter.slug}/`
      }
      return defaultPath
    },
    customSearchLink(pref, areaGroup = null) {
      const query = { area_city_prefecture_slug_eq: pref.slug }
      if (areaGroup) query.area_city_prefecture_area_group_slug_eq = areaGroup.slug
      return {
        name: 'custom-search-events',
        params: {
          custom_search: this.$route.params.custom_search
        },
        query: query
      }
    },
    changeOpeningRegions(slug) {
      if (this.openingRegions.includes(slug)) this.openingRegions.pop(slug)
      else this.openingRegions.push(slug)
    }
  }
}
</script>

<style lang="scss" scoped>
.areaListContainer {
  border: 1px solid $gray-c6;
  border-radius: 3px;
  color: $black-1a;
  &-header {
    padding: 0.8rem 1rem;
    font-size: 1.4rem;
  }
}

.areaList {
  &-row {
    @include flex;
    justify-content: flex-start;
    border-top: 1px dotted $gray-c6;
    font-size: 1.3rem;
    &-term {
      padding: 0.5rem 1rem;
      min-width: 150px;
      font-weight: bold;
      .icon {
        margin-right: 1rem;
        &.hokkaido {
          color: $hokkaido;
        }
        &.tohoku {
          color: $tohoku;
        }
        &.kanto {
          color: $kanto;
        }
        &.hokuriku {
          color: $hokuriku;
        }
        &.tokai {
          color: $tokai;
        }
        &.kinki {
          color: $kinki;
        }
        &.chugoku {
          color: $chugoku;
        }
        &.shikoku {
          color: $shikoku;
        }
        &.kyushu {
          color: $kyushu;
        }
      }
    }
    &-desc {
      flex: 1;
      padding: 0.5rem 1rem;
    }
  }
}

.prefectures {
  &-item {
    display: inline-block;
    margin-right: 0.5em;
    &-link {
      color: $black-1a;
      &.hokkaido:hover {
        color: $hokkaido;
      }
      &.tohoku:hover {
        color: $tohoku;
      }
      &.kanto:hover {
        color: $kanto;
      }
      &.hokuriku:hover {
        color: $hokuriku;
      }
      &.tokai:hover {
        color: $tokai;
      }
      &.kinki:hover {
        color: $kinki;
      }
      &.chugoku:hover {
        color: $chugoku;
      }
      &.shikoku:hover {
        color: $shikoku;
      }
      &.kyushu:hover {
        color: $kyushu;
      }
    }
    &.inactive {
      color: $gray-c6;
    }
  }
}

%selectorTriangle {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border: 6px inset;
}

.areaSelectorList {
  a {
    text-decoration: none;
    color: $black-1a;
  }
  &-header {
    padding: 0.9rem 1rem;
  }
  &-item {
    width: 100%;
    position: relative;
    border-bottom: 1px solid $gray-55;
    color: $black-1a;
    font-size: 1.4rem;
    .areaSelectorList-item {
      border-top: 1px solid $gray-55;
      border-bottom: 0;
    }
  }
  &-itemLink {
    display: flex;
    position: relative;
    padding: 0.9rem 1rem;
  }
  &-regionSelector {
    padding-left: 2.5rem;
    font-weight: bold;
    &::after {
      @extend %selectorTriangle;
      border-color: $gray-55 transparent transparent;
      border-top-style: solid;
      border-bottom-width: 0;
    }
  }
  &-prefectureSelector {
    padding-left: 4rem;
    font-weight: normal;
    &::after {
      @extend %selectorTriangle;
      border-color: transparent transparent transparent $gray-55;
      border-left-style: solid;
      border-right-width: 0;
    }
  }
  &-areaGroupSelector {
    padding-left: 5.5rem;
    font-weight: normal;
    &::after {
      @extend %selectorTriangle;
      border-color: transparent transparent transparent $gray-55;
      border-left-style: solid;
      border-right-width: 0;
    }
  }
}

.interestHeader {
  font-size: 1.8rem;
}

.interestTags {
  padding: 1rem;
  border: 1px solid $gray-c6;
  border-radius: 3px;
  background-color: $white;
  &-item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &-category {
      font-size: 1.6rem;
    }
  }
}

.tagList {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.commonButton {
  width: 47rem;
  margin: 0 auto;
}
</style>
