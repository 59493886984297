import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_APP_ID } from '@/utils/settings'
import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence } from 'firebase/auth'
import { initializeApp, getApps, getApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'

export default defineNuxtPlugin(nuxtApp => {
  const firebaseConfig = {
    apiKey: `${FIREBASE_API_KEY}`,
    authDomain: `${FIREBASE_AUTH_DOMAIN}`,
    projectId: `${FIREBASE_PROJECT_ID}`,
    messagingSenderId: `${FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${FIREBASE_APP_ID}`
  }

  const app = getApps().length ? getApp() : initializeApp(firebaseConfig)
  const db = initializeFirestore(app, { automaticDataCollectionEnabled: true })
  const auth = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence]
  })

  nuxtApp.vueApp.provide('firebaseApp', app)
  nuxtApp.provide('firebaseApp', app)

  nuxtApp.vueApp.provide('firebaseAuth', auth)
  nuxtApp.provide('firebaseAuth', auth)

  nuxtApp.vueApp.provide('firebaseDb', db)
  nuxtApp.provide('firebaseDb', db)
})
