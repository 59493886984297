export const webkitCallback = (callbackName, message = '') => {
  const ua = window.navigator.userAgent.toLowerCase()
  const isMobileNativeApp = ua.indexOf('machiconjapan') > -1
  const isIos = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1
  if (isMobileNativeApp && isIos && window.webkit && window.webkit.messageHandlers) {
    if (callbackName in window.webkit.messageHandlers) window.webkit.messageHandlers[callbackName].postMessage(message)
  }
}
export const androidCallback = (callbackName, message = '') => {
  if (window.Android && callbackName in window.Android) window.Android[callbackName](message)
}
export const appsflyerCallback = (message = '') => {
  if (typeof window === 'undefined') return
  webkitCallback('callAppsflyerEvent', message)
  androidCallback('callAppsflyerEvent', message)
}
