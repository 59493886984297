import { getCommonOptions, handleError, needForceReload } from './shared'
import { useInternalFetch, useAsyncFetch, useAsyncFetchV2 } from '@/composables/fetch'
import * as apiExtend from './api-extend'

const dispatch = (functionName, urlOrPayload, options = {}) => {
  if (apiExtend[functionName])
    return apiExtend[functionName](urlOrPayload).catch(e => {
      throw e
    })
  switch (functionName) {
    case 'apiGet':
      return apiGet(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiGetV2':
      return apiGetV2(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiGetAll':
      return apiGetAll(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiPost':
      return apiPost(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiPut':
      return apiPut(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiPatch':
      return apiPatch(urlOrPayload, options).catch(e => {
        throw e
      })
    case 'apiDelete':
      return apiDelete(urlOrPayload, options).catch(e => {
        throw e
      })
    default:
      throw new Error('Invalid function name: ' + functionName)
  }
}

const apiGet = async (url, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useInternalFetch(`/api/client/v1/${url}`, 'get', Object.assign(opt, options))
    if (needForceReload(url, response)) window.location.reload(true)
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiGetV2 = async (url, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useAsyncFetchV2(`/api/client/v1/${url}`, 'get', Object.assign(opt, options))
    if (needForceReload(url, response)) window.location.reload(true)
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

// call all of apis parallel
const apiGetAll = async (urls, options = {}) => {
  try {
    const opt = getCommonOptions()
    const responses = await Promise.all(urls.map(url => useInternalFetch(`/api/client/v1/${url}`, 'get', Object.assign(opt, options))))
    return responses
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiPost = async (url, params, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useInternalFetch(`/api/client/v1/${url}`, 'post', params, Object.assign(opt, options))
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiPostForm = async (url, params, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useAsyncFetch(`/api/client/v1/${url}`, 'post', params, Object.assign(opt, options))
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiPut = async (url, params, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useInternalFetch(`/api/client/v1/${url}`, 'put', params, Object.assign(opt, options))
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiPatch = async (url, params, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useInternalFetch(`/api/client/v1/${url}`, 'patch', params, Object.assign(opt, options))
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

const apiDelete = async (url, options = {}) => {
  try {
    const opt = getCommonOptions()
    const response = await useInternalFetch(`/api/client/v1/${url}`, 'delete', Object.assign(opt, options))
    return response
  } catch (e) {
    handleError(e)
    throw e
  }
}

export { dispatch, apiGet, apiGetV2, apiGetAll, apiPost, apiPostForm, apiPut, apiPatch, apiDelete }
