<template>
  <pickup-list :list="listPickupsForSp" :custom-sp-design="customSpDesign" :sync-pc-sp="true" />
</template>

<script>
const PickupList = defineAsyncComponent(() => import('@/components/PickupList.vue'))
export default {
  components: { PickupList },
  props: {
    customSpDesign: { type: Boolean, default: false, required: false }
  },
  data() {
    return {
      list: [
        {
          prefecture: '東京',
          path: '/areas/tokyo/categories/konkatsu/',
          title: '東京都の婚活パーティー',
          desc: '銀座、新宿、池袋エリアなど都内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '名古屋',
          path: '/areas/aichi/ag2301/categories/konkatsu/',
          title: '名古屋市内の婚活パーティー',
          desc: '栄、名駅、金山エリアなど市内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '大阪',
          path: '/areas/osaka/categories/konkatsu/',
          title: '大阪府の婚活パーティー',
          desc: '梅田、心斎橋、エリアなど大阪府内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '福岡',
          path: '/areas/fukuoka/categories/konkatsu/',
          title: '福岡県の婚活パーティー',
          desc: '北九州、久留米、博多エリアなど県内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '札幌',
          path: '/areas/hokkaido/ag0101/categories/konkatsu/',
          title: '札幌市内の婚活パーティー',
          desc: '札幌駅、すすきのエリアなど市内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '横浜',
          path: '/areas/kanagawa/ag1401/categories/konkatsu/',
          title: '横浜市内の婚活パーティー',
          desc: '横浜駅、桜木町、みなとみらいエリアなど市内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '京都',
          path: '/areas/kyoto/categories/konkatsu/',
          title: '京都府の婚活パーティー',
          desc: '京都駅、烏丸、河原町エリアなど京都府内で開催の婚活パーティー・お見合いパーティー'
        },
        {
          prefecture: '広島',
          path: '/areas/hiroshima/categories/konkatsu/',
          title: '広島県の婚活パーティー',
          desc: '広島駅、福山エリアなど県内で開催の婚活パーティー・お見合いパーティー'
        }
      ]
    }
  },
  computed: {
    fullListPickups() {
      return this.list.map(v => {
        return {
          headLarge: v.prefecture,
          headSmall: '婚活パーティー<br>お見合いパーティー',
          title: v.title,
          desc: v.desc,
          path: v.path
        }
      })
    },
    listPickupsForSp() {
      return this.fullListPickups.slice(0, 4)
    }
  }
}
</script>
