<template>
  <div class="container">
    <div class="containerBox">
      <div class="commonMargin">
        <h2 class="marginT5 marginB4 commonGuideHeading">初めての{{ firstPartyGuide.title }}パーティーガイド</h2>
        <div :class="{ marginB4: $store.isPC }" class="content content-guide">
          <custom-nuxt-link :to="firstPartyGuide.url">
            <common-lazy-load-image
              :image-url="firstPartyGuide.banner_url"
              :alt="`初めて${firstPartyGuide.title}パーティーに参加する方へ`"
              class="content-image-guide"
            />
          </custom-nuxt-link>
        </div>
        <h2 class="marginT5 marginB4 commonGuideHeading">街コンジャパンの特徴</h2>
        <div :class="{ marginB4: $store.isPC }" class="content content-guide">
          <custom-nuxt-link to="/beginner/#mj_features">
            <common-lazy-load-image :image-url="bnrMachiconFeatures" alt="machicon JAPANの特徴" class="content-image-guide" />
          </custom-nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import bnrGuideKoikatsu from '/assets/images/bnr_guide_koikatsuparty.jpeg'
import bnrGuideKonkatsu from '/assets/images/bnr_guide_konkatsuparty.jpeg'
import bnrMachiconFeatures from '/assets/images/bnr_machicon_features.png'

const FIRST_KOIKATSU_GUIDE = { title: '街コン・恋活', url: '/beginner/koikatsuparty/', banner_url: bnrGuideKoikatsu }
const FIRST_KONKATSU_GUIDE = { title: '婚活', url: '/beginner/konkatsuparty/', banner_url: bnrGuideKonkatsu }

const props = defineProps({
  partyGuide: { type: String, default: '', required: true }
})

const firstPartyGuide = computed(() => {
  return props.partyGuide === 'konkatsu' ? FIRST_KONKATSU_GUIDE : FIRST_KOIKATSU_GUIDE
})
</script>

<style lang="scss" scoped>
.content {
  display: flex-column;
  gap: 5rem;
  @include pc {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &-image {
    text-align: center;
    height: auto;
    @include sp {
      width: 100%;
    }
    aspect-ratio: 16/5;
    border-radius: 16px;
    box-shadow: $spread-box-shadow;
    &-guide {
      @extend .content-image;
      width: 800px;
      object-fit: cover;
    }
  }
  &-guide {
    @include pc {
      margin-bottom: 20px;
    }

    a {
      width: 100%;
      overflow: hidden;
      text-align: center;
    }
  }
}

.container {
  display: block;
  &Box {
    width: 100%;
  }
}

.commonMargin {
  margin-left: $spCommonMargin;
  margin-right: $spCommonMargin;
}
</style>
