import moment from 'mj-moment'
import { isHoliday } from '@/utils/date'

class TimeConverter {
  constructor(dateTime) {
    this.dateTime = moment(dateTime)
  }

  format(format) {
    return this.dateTime.format(format)
  }

  // Return: YYYY年M月D日(ddd) HH:mm
  get fullDateTimeWithWeekDay() {
    return this.format('llll')
  }

  // Return 2023.2.14(火)23:59まで
  get couponDateFormat() {
    return `${this.format('Y')}.<span class="textXLarge">${this.format('M.D')}(火)${this.timeShort}</span>まで`
  }

  // Return: 年M月D日(ddd) HH:mm
  get fullDateTimeWithoutYear() {
    return `${this.format('M月D日')}(${this.weekDay}) ${this.timeShort}`
  }

  // Return: 年M月D日(ddd) H:mm
  get fullDateTimeSingleHourWithoutYear() {
    return `${this.format('M月D日')}(${this.weekDay}) ${this.timeShortSingleHour}`
  }

  // Return: 年M月D日(ddd)
  get fullDateWithoutYear() {
    return `${this.format('M月D日')}(${this.weekDay})`
  }

  // Return: 年M月D日(ddd・祝) || 年M月D日(ddd)
  get fullDateWithoutYearAndFormatHoliday() {
    return isHoliday(this) ? `${this.format('M月D日')}(${this.weekDay}・祝)` : `${this.format('M月D日')}(${this.weekDay})`
  }

  // Return: YYYY年M月D日 HH:mm
  get fullDateTime() {
    return this.format('lll')
  }

  // Return: YYYY年M月D日(ddd)
  get fullDateWithWeekDay() {
    return `${this.fullDate}(${this.weekDay})`
  }
  // Return: YYYY年M月D日(ddd・祝) || YYYY年M月D日(ddd)
  get fullDateWithWeekDayAndFormatHoliday() {
    return isHoliday(this) ? `${this.fullDate}(${this.weekDay}・祝)` : `${this.fullDate}(${this.weekDay})`
  }

  // Return: M月D日(ddd)
  get shortDateWithWeekDay() {
    return `${this.format(`M月D日(${this.weekDay})`)}`
  }

  // Return: M月D日(ddd・祝) || M月D日(ddd)
  get shortDateWithWeekDayAndFormatHoliday() {
    return isHoliday(this) ? `${this.format(`M月D日(${this.weekDay}・祝)`)}` : this.shortDateWithWeekDay
  }

  // Return: M月D日
  get shortDate() {
    return `${this.format(`M月D日`)}`
  }

  // Return: M月D日(ddd) HH:mm
  get shortDateWithWeekDayAndTime() {
    return `${this.format(`MM月DD日(${this.weekDay}) HH:mm`)}`
  }

  // Return: YYYY年M月D日
  get fullDate() {
    return this.format('ll')
  }

  // Return: YYYY/MM/DD
  get fullDateStandard() {
    return this.format('YYYY/MM/DD')
  }

  // Return: YYYY/MM/DD(ddd)
  get fullDateStandardWithWeekDay() {
    return `${this.format('YYYY/MM/DD')}(${this.weekDay})`
  }

  // Return: YYYYMMDD
  get fullJoinedDate() {
    return this.format('YYYYMMDD')
  }

  // Return: M/D（ddd）
  get shortDateStandardWithWeekDay() {
    return this.format('M/D（ddd）')
  }

  // Return: (ddd・祝) || (ddd)
  get shortDateStandardWithWeekDayAndFormatHoliday() {
    return isHoliday(this) ? `${this.format('M/D（ddd・祝）')}` : this.format('M/D（ddd）')
  }

  get dateShort() {
    return this.format('MM/DD')
  }

  // Return: HH:mm
  get timeShort() {
    return this.format('LT')
  }

  // Return: H:mm
  get timeShortSingleHour() {
    return this.format('H:mm')
  }

  get year() {
    return this.dateTime.year()
  }

  get month() {
    return this.dateTime.month() + 1
  }

  get day() {
    return this.dateTime.date()
  }

  get isoString() {
    return this.dateTime.toISOString()
  }

  // Return weekday text (月,火,...)
  get weekDay() {
    return this.format('dd')
  }

  get weekDayEN() {
    return this.dateTime.locale('en').format('ddd')
  }

  get dateMonth() {
    return this.format('MM.DD')
  }

  get fullDateWithDots() {
    return this.format('YYYY.MM.DD')
  }

  get obj() {
    return this.dateTime
  }
}

export const numberToCurrency = num => {
  if (!num) return '0円'
  if (typeof num === 'string') num = Number(num)
  const toYen = num.toLocaleString('ja-JP')
  return `${toYen}円`
}

export const numberToCurrencyNotUnit = num => {
  if (!num) return '0'
  if (typeof num === 'string') num = Number(num)
  const toYen = num.toLocaleString('ja-JP')
  return `${toYen}`
}

export const timeConverter = dateTime => new TimeConverter(dateTime)
