import type { Link } from '@/types'

export const DEFAULT_LINKS: Link[] = [
  { title: '20代', to: { name: 'custom-search-events', params: { custom_search: '20sonly' } } },
  { title: '30代', to: { name: 'custom-search-events', params: { custom_search: '30sonly' } } },
  { title: 'アラサー', to: { name: 'custom-search-events', params: { custom_search: 'around30' } } },
  { title: '40代', to: { name: 'features-events', params: { feature_tag: 'foties-konkatsu' } } }
]

type Category = 'konkatsu' | 'party'

export function makeChooseByAgeLink(category: Category, prefecture: string, areaGroup: string = '', area: string = ''): Link[] {
  return [
    {
      title: '20代',
      to: {
        name: 'areas-custom-search-categories-events',
        params: { custom_search: '20sonly', prefecture: prefecture, category: category, area_group: areaGroup, area: area }
      }
    },
    {
      title: '30代',
      to: {
        name: 'areas-custom-search-categories-events',
        params: { custom_search: '30sonly', prefecture: prefecture, category: category, area_group: areaGroup, area: area }
      }
    },
    {
      title: 'アラサー',
      to: {
        name: 'areas-custom-search-categories-events',
        params: { custom_search: 'around30', prefecture: prefecture, category: category, area_group: areaGroup, area: area }
      }
    },
    {
      title: '40代',
      to: {
        name: 'areas-features-events',
        params: { feature_tag: 'foties-konkatsu', prefecture: prefecture, area_group: areaGroup, area: area }
      }
    }
  ]
}

export function makeChooseByAgeTitle(category: Category, location: string): string {
  if (category === 'party') {
    return `街コン・恋活パーティーを年代で選ぶ（${location}）`
  }

  return `街コン・婚活パーティーを年代で選ぶ（${location}）`
}

export function makeCustomSearchHeader(category: Category, customSearch: string): string {
  if (category === 'party') {
    return `${customSearch}の恋活パーティー一覧`
  }

  return `${customSearch}の婚活パーティー・お見合いパーティー一覧`
}
