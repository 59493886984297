import { buildQueryString } from '@/utils/url-action'

export default class AdvertisementData {
  constructor(store, params) {
    this.store = store
    this.params = params
  }

  fetchData() {
    const queryString = buildQueryString(this.params)
    const apiUrl = `advservice/${queryString}`
    return this.store
      .dispatch('apiGetV2', apiUrl)
      .then(res => ({ ads: res.data }))
      .catch(e => {
        // throwするとAdBlock系ツールでエラーになる
        return { ads: {} }
      })
  }
}
