import { PURPOSES } from '@/utils/constants/purposes'

const EVENT_TYPES = {
  machicon: 10,
  'petit-con': 20,
  party: 30,
  konkatsu: 40,
  activity: 60,
  interest: 80,
  bar_gourmet: 70,
  jibunmigaki: 50,
  other: 900
}

// 検索用セールスタイプ
const SEARCH_SALE_TYPES = {
  on_sale_female: '女性募集中',
  on_sale_male: '男性募集中'
}
const SEARCH_CONTENT_TYPES = {
  one_store: '1店舗',
  multiple_stores: '複数店舗',
  sit_down: '着席',
  stand_up: '立席',
  both: '着席+立席',
  other_format: 'その他',
  yes_foods: '料理あり',
  no_foods: '料理なし',
  yes_drinks: '飲物あり',
  no_drinks: '飲物なし',
  many_people: 'たくさんの人と話したい',
  konkatsu: 'まじめに婚活したい',
  have_interest: '趣味の合う人と交流',
  for_beginner: '初心者にオススメ',
  single: '一人参加OK'
}
const EVENT_TYPE_PURPOSE = {
  konkatsu: 'marriage_partner',
  party: 'lover',
  interest: 'same_hobbies',
  other: 'friends',
  jibunmigaki: 'jibunmigaki',
  bar_gourmet: 'bar_gourmet',
  // custom event type
  gocon_dinnerplus_sakunomi: 'gocon_dinnerplus_sakunomi',
  tomokatsu: 'tomokatsu'
}

const PURPOSE_EVENT_TYPES = {
  marriage_partner: ['konkatsu'],
  lover: ['party'],
  same_hobbies: ['activity', 'interest'],
  friends: ['other'],
  jibunmigaki: ['jibunmigaki'],
  bar_gourmet: ['bar_gourmet'],
  interest: ['activity', 'interest']
}

const EVENT_TYPE_NAMES = {
  machicon: '街コン',
  'petit-con': 'プチ街コン',
  party: '恋活パーティー',
  konkatsu: '婚活パーティー・お見合いパーティー',
  jibunmigaki: '自分磨き・セミナー',
  activity: '体験コン・アクティビティー',
  interest: '趣味コン',
  bar_gourmet: 'バル・グルメイベント',
  other: 'その他',
  // custom event type
  // gocon_dinnerplus_sakunomi: '合コン/食事会',
  tomokatsu: '友達作り/オフ会/社会人サークル'
}

export default class Event {
  static getEventTypeByValue(val) {
    const eventType = Object.keys(EVENT_TYPES).find(k => EVENT_TYPES[k] === Number(val)) || ''
    return eventType
  }

  static getEventTypeName(key) {
    return EVENT_TYPE_NAMES[key]
  }

  static getPurposeByEventType(key) {
    return EVENT_TYPE_PURPOSE[key]
  }

  static getListPurposes() {
    const listPurposes = []
    PURPOSES.forEach(category => {
      listPurposes.push({ name: category.name, slug: category.slug })
      if (category.subCategories) {
        category.subCategories.forEach(subCat => {
          listPurposes.push({ name: subCat.name, slug: subCat.slug })
          if (subCat.haveSubItemList) {
            subCat.subList.forEach(subList => {
              subList.subItems.forEach(subItem => {
                listPurposes.push({ name: subItem.name, slug: subItem.slug })
              })
            })
          } else {
            subCat.subList.forEach(subItem => {
              listPurposes.push({ name: subItem.name, slug: subItem.slug })
            })
          }
        })
      }
    })
    listPurposes.push({ name: '友達を探す', slug: 'friends' })
    listPurposes.push({ name: 'バル・グルメ', slug: 'bar_gourmet' })
    return listPurposes
  }

  static getEventTypesByPurposes(purposes) {
    return purposes.map(p => Object.keys(EVENT_TYPE_PURPOSE).find(k => EVENT_TYPE_PURPOSE[k] === p)).filter(v => v)
  }

  // same_hobbies: ['activity', 'interest']
  // [友達を探す]においては、趣味コン（アクティビティを含む)を検索するため
  static getEventTypesByPurpose(purpose) {
    return PURPOSE_EVENT_TYPES[purpose]
  }
  static getEventTypeValue(key) {
    return EVENT_TYPES[key]
  }

  static listSaleTypesForSearch() {
    return SEARCH_SALE_TYPES
  }

  static getContentTypeValue(type) {
    return SEARCH_CONTENT_TYPES[type] || ''
  }
}
