import { buildQueryString } from '@/utils/url-action'
export const KEY_AFB_CID = 'ssr_afb_cid' // Main tracking parameter
export default class Afb {
  static setAccessInfo(route, store) {
    const { cid, utm_source, utm_medium, utm_campaign } = route.query
    if (utm_source === 'afb' && utm_medium === 'affiliate' && cid) {
      store.ssrCookie[KEY_AFB_CID] = cid
      const params = { afb_cid: cid, utm_campaign: utm_campaign }
      store.dispatch('apiGet', `supports/generate_cookie_afb/${buildQueryString(params)}`)
    }
  }

  static sendConversionInfo(store, purchase_payment_key) {
    if (store.ssrCookie[KEY_AFB_CID]) {
      store.dispatch('postAfbConversion', { purchase_payment_key: purchase_payment_key })
      delete store.ssrCookie[KEY_AFB_CID]
    }
  }
}
