<template>
  <ul v-if="showPcOrSp" class="recommendList">
    <li v-for="(pickup, index) in list" :key="index" class="recommendList-item groupItem">
      <custom-nuxt-link :to="pickup.path" class="groupItem-head">
        <div class="groupItem-head-name">{{ pickup.headLarge }}</div>
        <div class="groupItem-head-desc" v-html="$sanitizedXssHtml(pickup.headSmall)" />
      </custom-nuxt-link>
      <div class="groupItem-title"><custom-nuxt-link :to="pickup.path" v-html="$sanitizedXssHtml(pickup.title)" /></div>
      <div class="groupItem-desc">
        <custom-nuxt-link :to="pickup.path">{{ pickup.desc }}</custom-nuxt-link>
      </div>
      <div class="groupItem-link"><custom-nuxt-link :to="pickup.path">詳細を見る</custom-nuxt-link></div>
    </li>
  </ul>
  <ul v-else class="buttonList">
    <li v-for="(pickup, index) in list" :key="index" :class="{ forCustomDesign: customSpDesign }" class="buttonList-item">
      <custom-nuxt-link :to="pickup.path" class="commonButton btn-secondary-40">
        {{ pickup.headLarge }}
      </custom-nuxt-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: { type: Array, default: () => [], required: true },
    customSpDesign: { type: Boolean, default: false, required: false },
    syncPcSp: { type: Boolean, default: false, required: false }
  },
  computed: {
    showPcOrSp() {
      if (this.syncPcSp) return false
      return this.$store.isPC
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonList {
  margin: 1rem 0.5rem;
  @include clearAfter;
  &-item {
    display: inline-block;
    margin: 0.5rem;
    font-size: 1.2rem;
    &.forCustomDesign {
      float: left;
      width: 25%;
      margin: 0;
      text-align: center;
      padding: 0 0.5rem;
      a {
        background: $white;
        display: block;
        padding: 1rem 0;
        border: 1px solid $gray-c6;
        color: $blue-pale;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
}

.recommendList {
  &-item {
    display: inline-block;
    width: 23%;
    margin: 1%;
    vertical-align: top;
    img {
      display: block;
    }
    &.button {
      width: auto;
      margin: 1rem 0.5rem;
    }
  }
}

.groupItem {
  font-size: 12px;
  &-head {
    display: block;
    padding: 5px;
    border: 1px solid $gray-c6;
    border-radius: 3px;
    font-weight: bold;
    text-align: center;
    transition: 0.2s;
    &-name {
      font-size: 28px;
      color: $pink;
      transition: 0.2s;
    }
    &-desc {
      font-size: 13px;
      color: $gray-55;
      transition: 0.2s;
    }
    &:hover,
    &:hover * {
      border-color: $pink-dark;
      color: $pink-dark;
    }
  }
  &-title {
    margin: 15px 0;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    a {
      letter-spacing: -0.7px;
      color: $black-1a;
      &:hover {
        color: $gray-55;
      }
    }
  }
  &-desc {
    min-height: 72px;
    a {
      color: $black-1a;
      &:hover {
        color: $gray-55;
      }
    }
  }
  &-link {
    margin: 20px 0;
    font-size: 13px;
    text-align: center;
  }
}
</style>
