import { AREAS } from '@/config/data/areas'
export const listAreas = () => {
  return AREAS
}

export const getListAreasByPrefectureSlug = slug => {
  return AREAS.filter(v => v.prefecture_slug === slug)
}

export const getListAreasByAreaGroup = slug => {
  return AREAS.filter(v => v.area_group_slug === slug)
}

export const findAreaBySlug = slug => {
  return AREAS.find(v => v.slug === slug)
}
