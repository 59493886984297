<template>
  <div>
    <h2 class="commonHeadingBlack paddingB2">{{ title }}</h2>
    <ul class="shortcutList">
      <li v-for="(item, key) in ageItemList" :key="key" class="shortcutList-item">
        <custom-nuxt-link :to="item.to" class="commonButton btn-secondary-40">
          {{ item.title }}
        </custom-nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { DEFAULT_LINKS } from '@/utils/choose-by-age'
import type { Link } from '@/types'

const KONKATSU_FILTER = 'cat[]=40'

const props = defineProps<{ isKonkatsuPage?: boolean; links?: Link[]; title?: string }>()

const itemList = computed(() => props.links || DEFAULT_LINKS)
const title = computed(() => props.title || '年代で選ぶ')

const ageItemList = computed(() => {
  if (!props.isKonkatsuPage) return itemList.value

  return itemList.value.map(item => {
    // @ts-expect-error
    const path = item.to.params.custom_search ? `/csp/${item.to.params.custom_search}` : `/features/${item.to.params.feature_tag}`
    return {
      title: item.title,
      to: `${path}?${KONKATSU_FILTER}`
    }
  })
})
</script>

<style lang="scss" scoped>
.shortcutList {
  @include flex;
  &-item {
    flex: 1;
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      padding: 0.7rem 0;
      @media screen and (max-width: 330px) {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
