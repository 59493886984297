<template>
  <span v-if="icomoon" :class="className" :style="style" />
  <font-awesome-icon v-else-if="far || fas || fab" :icon="faIconName" :pulse="pulse" :style="style" />
</template>

<script>
export default {
  props: {
    size: { type: Number, required: false, default: null },
    color: { type: String, required: false, default: null },
    icomoon: { type: String, required: false, default: null },
    fas: { type: String, required: false, default: null },
    far: { type: String, required: false, default: null },
    fab: { type: String, required: false, default: null },
    pulse: { type: Boolean, required: false, default: false }
  },
  computed: {
    tag() {
      return this.icomoon ? 'span' : 'svg'
    },
    faIconName() {
      if (this.fas) return ['fas', this.fas.replace(/^fa-/, '')]
      if (this.far) return ['far', this.far.replace(/^fa-/, '')]
      if (this.fab) return ['fab', this.fab.replace(/^fa-/, '')]
      return ''
    },
    className() {
      const classes = []
      if (this.icomoon) classes.push(`u-icon-${this.icomoon}`)
      if (this.color) classes.push(this.color)
      return classes
    },
    style() {
      return { fontSize: this.size ? `${this.size}rem` : null }
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  min-width: 1em;
  min-height: 1em;
}

svg {
  display: none;
}

.svg-inline--fa {
  display: inline-block;
}

// color
.blue {
  color: $blue;
}

.pink {
  color: $pink;
}

.blueDark {
  color: $blue-dark;
}

.pinkDark {
  color: #ff64aa;
}

.blueSky {
  color: #46b4ff;
}

// icomoon

@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url('/fonts/icomoon.eot?7n3iuz');
  src:
    url('/fonts/icomoon.eot?7n3iuz#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?7n3iuz') format('truetype'),
    url('/fonts/icomoon.woff?7n3iuz') format('woff'),
    url('/fonts/icomoon.svg?7n3iuz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='u-icon-'],
[class*=' u-icon-'] {
  font-family: 'icomoon', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
}

.fa-female-pair::before {
  // content: $fa-var-female-pair;
}

.fa-male-pair::before {
  // content: $fa-var-male-pair;
}

.u-icon-bar-logo::before {
  content: '\e903';
}

.u-icon-logo-line::before {
  content: '\e904';
}

.u-icon-beginnermark::before {
  content: '\e905';
}

.u-icon-hobby::before {
  content: '\e906';
}

.u-icon-ranking::before {
  content: '\e907';
}

.u-icon-ring::before {
  content: '\e908';
}

.u-icon-glass::before {
  content: '\e909';
}

.u-icon-bust-male::before {
  content: '\e90a';
}

.u-icon-male::before {
  content: '\e90b';
}

.u-icon-male-pair::before {
  content: '\e90c';
}

.u-icon-female::before {
  content: '\e90d';
}

.u-icon-female-pair::before {
  content: '\e90e';
}

.u-icon-office::before {
  content: '\e90f';
}

.u-icon-bust-female::before {
  content: '\e910';
}

.u-icon-logo-yahoo::before {
  content: '\e911';
}

.u-icon-anime::before {
  content: '\e912';
}

.u-icon-bouldering::before {
  content: '\e913';
}

.u-icon-cat::before {
  content: '\e914';
}

.u-icon-cooking::before {
  content: '\e915';
}

.u-icon-futsal::before {
  content: '\e916';
}

.u-icon-nazotoki::before {
  content: '\e917';
}

.u-icon-tennis::before {
  content: '\e918';
}

.u-icon-travel::before {
  content: '\e919';
}

.u-icon-report::before {
  content: '\e91a';
}

.u-icon-user-group::before {
  content: '\e91b';
}

.u-icon-heart::before {
  content: '\e9da';
}

.u-icon-logo-gplus::before {
  content: '\e800';
}

.u-icon-logo-facebook::before {
  content: '\e801';
}

.u-icon-logo-twitter::before {
  content: '\e802';
}

.u-icon-soccerball::before {
  content: '\f1e3';
}

.u-icon-jinro::before {
  content: '\e902';
}

.u-icon-icon_konkatsu-ring::before {
  content: '\e901';
}

.u-icon-icon_mountain::before {
  content: '\e900';
}
</style>
