import moment from 'mj-moment'
import SearchParams from '@/utils/search/SearchParams'

// params to get calendar data
export const buildCalendarParams = (store, currentRoute, options = {}) => {
  const fromDate = options.fromDate || moment().startOf('month').format('YYYY-MM-DD')
  const toDate = options.toDate || moment(fromDate).add(1, 'month').endOf('month').format('YYYY-MM-DD')
  const searchParams = new SearchParams(store, currentRoute).apiSearchParams
  const prefecture = options.prefecture || (store.$state.my_profile && store.$state.my_profile.prefecture_slug) || searchParams['filter[prefecture]']
  const extendParams = {
    region: options.region || searchParams['filter[region]'],
    'prefecture[]': prefecture && prefecture.split(','),
    have_interest: searchParams['filter[have_interest]'],
    feature_tag: searchParams['filter[feature_tag]'],
    event_type: searchParams['filter[event_type]']
  }
  if (!extendParams.region && searchParams['filter[regions]']) extendParams['regions[]'] = searchParams['filter[regions]'].split(',')

  return { ...extendParams, from_date: fromDate, to_date: toDate }
}
