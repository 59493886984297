import coronaIconsThermometer from '/assets/images/svg/corona_icons_thermometer.svg'
import coronaIconsMask from '/assets/images/svg/corona_icons_mask.svg'
import coronaIconsHighFever from '/assets/images/svg/corona_icons_high-fever.svg'
import coronaIconsDisinfection from '/assets/images/svg/corona_icons_disinfection.svg'
import coronaIconsMaleWearingMask from '/assets/images/svg/corona_icons_male-wearing-mask.svg'
import coronaIconsFemaleWearingMask from '/assets/images/svg/corona_icons_female-wearing-mask.svg'

export const APP_VERSION_HEADER = 'x-app-version'

// List paths of event list page
// `/areas/xxx`, `/dates/xxx`,...
// `/search/` & `/search?search_from=events` are search detail form (NOT target page)
// `/features/` is features list page (NOT target page)
export const EVENT_LIST_PATHS = ['areas', 'regions', 'dates', 'categories', 'promoters', 'interests', 'fixed', 'csp', 'search', 'features']
export const HIDE_FEATURE_TAG_IDS = [
  1, 2, 7, 12, 21, 28, 29, 30, 31, 32, 33, 60, 65, 104, 115, 127, 145, 168, 204, 212, 213, 236, 238, 248, 266, 268, 316, 352, 547
]

export const NUM_OF_RECOMMEND_USER = {
  male: 3,
  female: 3
}

// this is params google analytics auto add when click ads link.
export const GOOGLE_ANALYTICS_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign']

export const VBAR_GROUPS = ['vbar_night', 'vbar_day', 'online_konkatsu', 'and_girl']
export const SUPPORT_LANGUAGES = {
  'en-US': 'en',
  'zh-TW': 'zh-TW',
  ko: 'ko'
}
export const DEFAULT_LANGUAGE = 'ja'
export const COVID_19_NOTICE_IN_MAY = [
  {
    text: '来場前の検温とマスクの着用をお願いいたします。※マスクは必ずご持参ください。',
    imageUrls: [coronaIconsThermometer, coronaIconsMask],
    notes: []
  },
  {
    text: '37.5℃以上の発熱や風邪症状がある方はご参加をお控えください。',
    imageUrls: [coronaIconsHighFever],
    notes: []
  },
  {
    text: '受付にてアルコール消毒をお願いいたします。',
    imageUrls: [coronaIconsDisinfection],
    notes: []
  },
  {
    text: 'イベント最中もマスクの着用をお願いいたします。',
    imageUrls: [coronaIconsMaleWearingMask, coronaIconsFemaleWearingMask],
    notes: []
  }
]
export const COVID_19_NOTICE_IN_AUGUST = [
  {
    text: '来場前の検温をお願いいたします。37.5℃以上の発熱や風邪症状がある方はご参加をお控えください。',
    imageUrls: [coronaIconsThermometer, coronaIconsHighFever],
    notes: []
  },
  {
    text: '受付にてアルコール消毒をお願いいたします。',
    imageUrls: [coronaIconsDisinfection],
    notes: []
  },
  {
    text: 'イベント最中もマスクの着用をお願いいたします。',
    imageUrls: [coronaIconsMask],
    notes: ['※マスクは必ずご持参ください']
  }
]
export const COVID_19_NOTICE_IN_MARCH_2023 = [
  {
    text: '来場前の検温をお願いいたします。37.5℃以上の発熱や風邪症状がある方はご参加をお控えください。',
    imageUrls: [coronaIconsThermometer],
    notes: []
  },
  {
    text: '受付にてアルコール消毒をお願いいたします。',
    imageUrls: [coronaIconsDisinfection],
    notes: []
  }
]

// in nuxt.config.js file we have same title but we can't use constant for that because nuxt.config.js is not support ES6 later syntax (export/import)
export const MJ_TOP_TITLE = '街コン・婚活パーティーは街コンジャパン｜日本最大級の出会いイベントポータルサイト(machicon JAPAN)'
export const FIVE_MINUTES_EVENT_TYPES = ['five_minutes', 'five_minutes_global']
export const TIME_FINISH_COMMUNITY_TOPIC = '2020-11-30 15:00'
export const SPECIAL_FEATURES = ['online_konkatsu', 'vbar', 'counseling']
export const ONLINE_EVENT_TYPES = ['online', 'offline']

// for SEO
export const CHRISTMAS_PREFIX = 'クリスマスキャンペーン開催中！'
export const OSHOUGATSU_PREFIX = '新春お正月キャンペーン開催中！'
export const TIME_START_DISPLAY_CHRISTMAS_PREFIX = '2020-12-14 00:00'
export const TIME_START_DISPLAY_OSHOUGATSU_PREFIX = '2020-12-25 12:00'
export const TIME_FINISH_DISPLAY_OSHOUGATSU_PREFIX = '2021-01-08 12:00'
// for display areas_internal_links
export const DISPLAY_AREAS_INTERNAL_LINKS_TARGET_PAGES = ['areas', 'areas-page', 'areas-categories', 'areas-categories-page']
export const EMAIL_REGEX = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const PHONE_NUMBER_REGEX = /^[0-9]{11}$/i
export const MJ_CL_MAX_STEPS = 3
// for display only single ticket
export const DISPLAY_TICKET_NUM_OF_PEOPLE = 1
export const COOKIE_MJ_SESSION = '_mj_session'
export const MODAL_CONTENT = 'MODAL_CONTENT'
export const MODAL_CONTACT = 'MODAL_CONTACT'
export const MODAL_CONFIRM = 'MODAL_CONFIRM'
export const MODAL_FEEDBACK = 'MODAL_FEEDBACK'
export const MODAL_REPORT = 'MODAL_REPORT'
export const MODAL_REPORT_THANKS = 'MODAL_REPORT_THANKS'
export const MODAL_QUESTIONNAIRE_REMINDER_EVENT_DESCRIPTION = 'MODAL_QUESTIONNAIRE_REMINDER_EVENT_DESCRIPTION'
export const MODAL_QUESTIONNAIRE_REMINDER_GUIDE_TICKET_PAGE = 'MODAL_QUESTIONNAIRE_REMINDER_GUIDE_TICKET_PAGE'
export const NEED_OTP_CONFIRMATION_ERROR_CODE = 'need_otp_confirmation'
export const COOKIE_PROFILE_FOR_SEARCH_KEYS = ['age', 'gender', 'prefecture']

// check in tickets token missing in url
export const CHECK_IN_TICKETS_TOKEN_MISSING_ERROR_CODE = 'check_ticket_token_missing'

// firebase send message
export const FIREBASE_SEND_MESSAGE_FAIL = 'メッセージが送信できません。\nページ更新してから、再度ご確認ください。'

export const SORT_OPTIONS = [
  {
    value: 'ai_recommend',
    text: 'おすすめ順'
  },
  {
    value: 'mj_sort',
    text: '開催時間順'
  },
  {
    value: 'rating',
    text: '口コミ評価順'
  }
]

export const DEFAULT_SORT_OPTION = 'ai_recommend'

export const EVENT_LIST_SORT_TYPE = {
  0: 'mj_sort',
  1: 'ai_recommend',
  2: 'rating'
}

// The age that participants can be treated as adulthood: can drink alcohol etc,...
export const ADULTHOOD_AGE = 20

// Scroll event list type
export const RECOMMEND_PERSONALIZED = 'あなたが興味のありそうなイベント'
export const RECOMMEND_FROM_VIEW_PARTICIPATION_HISTORY = '閲覧・参加履歴からのおすすめ'
export const RECOMMEND_FROM_VIEW_HISTORY = '閲覧履歴からのおすすめ'
export const RECOMMEND_FROM_FAVORITE_LIST = 'あなたの気になるリストの傾向から'
export const RECOMMEND_RELATE_THIS_SEARCH = 'この検索に関連するイベント'
export const RECOMMEND_HIGH_SATISFACTION = '満足度の高いおすすめイベント'
export const RECENT_CHECKED_EVENT = '最近見たイベント'
export const RECENT_1ON1_PARTY_EVENT = '直近のイベントはこちら！'

// check is visited to /cl_events/* page
export const VISITED_CL_EVENT_PAGE_COOKIE_KEY = 'isVisitedClEventPage'
