export default class BrowserStorage {
  constructor(type) {
    this.type = type
  }

  get storageAvailable() {
    try {
      this.storage = window[this.type]
      const key = '__storage_test__'
      this.storage.setItem(key, key)
      this.storage.getItem(key)
      this.storage.removeItem(key)
      return true
    } catch (e) {
      return false
    }
  }

  getItem(keyName) {
    return this.storageAvailable ? this.storage.getItem(keyName) : null
  }

  setItem(keyName, keyValue) {
    return this.storageAvailable ? this.storage.setItem(keyName, keyValue) : null
  }

  removeItem(keyName) {
    return this.storageAvailable ? this.storage.removeItem(keyName) : null
  }
}
