import Range from '@/utils/range'
import { formatMonthDate } from '@/utils/formatter'

export const defaultTimeSearchCondition = () => {
  const begin = 9
  const end = 21
  return Range(begin, end).map(hour => {
    return {
      value: `${formatMonthDate(hour)}00`,
      text: `${formatMonthDate(hour)}:00以降`
    }
  })
}
