import SearchParams from '@/utils/search/SearchParams'
import { uniqArray } from '@/utils/array'
import SearchForm from '@/utils/search/SearchForm'
import UserModel from '@/models/users'
import EventModel from '@/models/events'
import Cookies from 'mj-cookie'
import { findPrefectureBySlug, findAreaBySlug } from '@/models/places'
import { timeConverter } from '@/utils/converter'
import SearchLink from '@/utils/search/SearchLink'
import { SPECIAL_FEATURES } from '@/utils/constants/commons'
import PageDetector from '@/utils/PageDetector'
import { useRoute } from 'nuxt/app'

const DEFAULT_MODEL_DATA = {
  start_date: null,
  start_dates: [],
  end_date: null,
  areas: [],
  prefecture_slug: null,
  purposes: [],
  syumiplusPurposes: [],
  interestPurposes: [],
  user_gender: null,
  user_age: null,
  from_time: '',
  num_of_people: null,
  num_of_people_range: [],
  companion_age: null,
  price_from: 0,
  price_to: 0,
  exclude_sold_out: 1,
  keyword: null,
  features: [],
  fixed: null,
  has_stage_discount_tickets: null,
  has_mj_select: null,
  onlineEventTypes: []
}

const MIN_AGE = 18
const MAX_AGE = 80

export default {
  data() {
    return {
      modal: '',
      model: {
        ...DEFAULT_MODEL_DATA
      },
      currentFeature: null
    }
  },
  computed: {
    route() {
      return useRoute()
    },
    modalData() {
      if (!this.modal) return {}
      return {
        prefecture: this.model.prefecture_slug,
        ...this.model
      }
    },
    searchLink() {
      return new SearchLink(this.$store, this.route)
    },
    searchForm() {
      return new SearchForm(this.route.query)
    },
    dateRange() {
      const text = []
      const startDate = this.model.start_date
      const endDate = this.model.end_date
      const fromTime = this.model.from_time
      text.push(startDate && startDate === endDate ? `${timeConverter(startDate).shortDateWithWeekDay} ` : '日付指定なし ')
      text.push(fromTime ? `${fromTime.slice(0, 2)}:${fromTime.slice(2)}以降` : '時間指定なし')
      return text.join('')
    },
    selectedDates() {
      const date = this.model.start_date
      if (this.model.start_dates.length > 0) return this.model.start_dates.map(date => timeConverter(date).shortDateWithWeekDay).join(', ')
      return date ? timeConverter(date).shortDateWithWeekDay : ''
    },
    selectedDateFormatHoliday() {
      if (this.route.query['from-day'] && this.route.query['to-day']) {
        const startDate = new Date(this.model.start_date)
        const endDate = new Date(this.model.end_date)
        return Array.from({ length: (endDate - startDate) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => {
          const date = new Date(startDate)
          date.setDate(startDate.getDate() + i)
          return timeConverter(date).shortDateWithWeekDayAndFormatHoliday
        }).join(', ')
      }
      const date = this.model.start_date
      if (this.model.start_dates.length > 0)
        return this.model.start_dates.map(date => timeConverter(date).shortDateWithWeekDayAndFormatHoliday).join(', ')
      if (!date) return '開催日を選ぶ'
      return date ? timeConverter(date).shortDateWithWeekDayAndFormatHoliday : ''
    },
    listPurposes() {
      return EventModel.getListPurposes()
    },
    selectedPrefectureName() {
      const prefecture = this.model.prefecture_slug ? findPrefectureBySlug(this.model.prefecture_slug) : null
      return prefecture ? prefecture.name : 'エリアを選ぶ'
    },
    selectedAreaName() {
      const areaListName = this.model.areas.map(areaSlug => findAreaBySlug(areaSlug).name)
      return areaListName.join('、')
    },
    selectedFeatureTagName() {
      if (this.model.features.length > 0 && SPECIAL_FEATURES.includes(this.model.features[0])) {
        return this.listPurposes.filter(v => this.model.features.includes(v.slug)).map(v => v.name.replace(/を探す$/g, ''))[0]
      }
      if (!this.currentFeature) return '目的を選ぶ'
      return this.currentFeature.name
    },
    spSelectedFeatureTagName() {
      if (this.model.features.length > 0 && (SPECIAL_FEATURES.includes(this.model.features[0]) || this.model.features[0] === 'counseling')) {
        return this.listPurposes.filter(v => this.model.features.includes(v.slug)).map(v => v.name.replace(/を探す$/g, ''))
      }
      return []
    },
    selectedPurposesName() {
      const result = this.listPurposes
        .filter(v => this.model.purposes.includes(v.slug))
        .map(v => v.name.replace(/を探す$/g, ''))
        .concat(this.spSelectedFeatureTagName)
      if (this.model.syumiplusPurposes && this.model.syumiplusPurposes.length > 0) {
        result.push(this.listPurposes.filter(v => this.model.syumiplusPurposes.includes(v.slug)).map(v => v.name.replace(/を探す$/g, ''))[0])
      }
      if (this.model.features && this.model.features.length > 0) {
        result.push(this.listPurposes.filter(v => this.model.features.includes(v.slug)).map(v => v.name.replace(/を探す$/g, ''))[0])
      }
      if (this.model.interestPurposes && this.model.interestPurposes.length > 0) {
        result.push('趣味コン')
      }
      if (result.length === 0) {
        const pageDetector = new PageDetector(this.route)
        if (pageDetector.isKonkatsuEventsPages || pageDetector.isTopPage) return '未設定'
        return '目的から選ぶ'
      }
      return uniqArray(result).join(', ')
    },
    selectedSubPurposeName() {
      if (this.model.syumiplusPurposes && this.model.syumiplusPurposes.length > 0) {
        return this.listPurposes
          .filter(v => this.model.syumiplusPurposes.includes(v.slug))
          .map(v => v.name.replace(/を探す$/g, ''))
          .slice(1)
          .join('、')
      } else if (this.model.interestPurposes && this.model.interestPurposes.length > 0) {
        return this.listPurposes
          .filter(v => this.model.interestPurposes.includes(v.slug))
          .map(v => v.name.replace(/を探す$/g, ''))
          .join('、')
      }
      return ''
    },
    searchQueryParams() {
      const formData = { ...this.model, prefectures: this.model.prefecture_slug ? [this.model.prefecture_slug] : [] }
      if (this.model.interestPurposes && this.model.interestPurposes.length > 0) {
        formData.purposes.push('interest')
      }
      return this.searchForm.convertFormDataToQueryParams(formData)
    },
    hasInputtedAge() {
      return this.model.user_age
    },
    isSelectedGenderMale() {
      return this.model.user_gender === UserModel.getGenderValue('male')
    },
    isSelectedGenderFemale() {
      return this.model.user_gender === UserModel.getGenderValue('female')
    }
  },
  watch: {
    'model.prefecture_slug'() {
      if (this.model.prefecture_slug) {
        Cookies.set('prefecture', this.model.prefecture_slug)
      } else {
        Cookies.remove('prefecture')
      }
    },
    'route.query'() {
      this.initFormData()
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      const formData = this.searchForm.mapQueryToFormData
      Object.keys(this.model).forEach(key => {
        const value = formData[key] || DEFAULT_MODEL_DATA[key]
        this.model[key] = value
      })

      const searchParams = new SearchParams(this.$store, this.route).apiSearchParams

      const startOn = searchParams['filter[start_at_or_end_at][gte]']
      if (startOn && this.route.name.includes('dates')) {
        this.model.start_date = this.model.end_date = startOn
        this.model.start_dates = [startOn]
      }
      // Don't auto add saved prefecture when init form data
      const prefectureSlug =
        searchParams['filter[prefecture]'] && !searchParams['filter[prefecture]'].includes(',') ? searchParams['filter[prefecture]'] : null
      if (prefectureSlug && findPrefectureBySlug(prefectureSlug)) this.model.prefecture_slug = prefectureSlug

      if (searchParams['user_gender']) this.model.user_gender = UserModel.getGenderValue(searchParams['user_gender'])
      if (searchParams['user_age']) this.model.user_age = searchParams['user_age']

      this.setDefaultPurposes()
      const featureTag = this.searchConditions && this.searchConditions.feature_tag
      if (featureTag && !SPECIAL_FEATURES.includes(featureTag.slug)) {
        this.model.features = [featureTag.slug]
        this.currentFeature = { name: featureTag.short_name ? featureTag.short_name : featureTag.name, slug: featureTag.slug }
      }

      if (searchParams['filter[online_event_type]']) this.model.onlineEventTypes = searchParams['filter[online_event_type]'].split(',')
      if (!this.$route.query.search_form_version) this.model.onlineEventTypes = []
    },
    setDefaultPurposes() {
      this.model.purposes = new SearchParams(this.$store, this.route).getPurposesByParams
    },
    selectGender(gender) {
      Cookies.set('gender', gender)
      this.model.user_gender = UserModel.getGenderValue(gender)
    },
    setFormAge(e) {
      this.isFocusAge = false
      const age = parseInt(e.target.value)
      if (age && age >= MIN_AGE && age <= MAX_AGE) {
        Cookies.set('age', age)
        this.model.user_age = age
      } else {
        Cookies.remove('age')
        this.model.user_age = null
      }
    },
    applyDate(payload) {
      this.model.start_date = payload.startDate
      this.model.start_dates = payload.startDates
      this.model.end_date = payload.endDate
      if (this.autoSubmitSearch) this.submitSearch()
    },
    applyLocation(payload) {
      if (payload.prefecture) {
        this.model.prefecture_slug = payload.prefecture.slug
      } else {
        this.model.prefecture_slug = null
      }
      this.region = payload.region || null
      this.model.areas = payload.areas || []
      this.model.onlineEventTypes = payload.onlineEventTypes || []
      if (this.autoSubmitSearch) this.submitSearch()
    },
    applyPurposes(payload) {
      this.model.purposes = payload.purposes || []
      this.model.syumiplusPurposes = payload.syumiplusPurposes || []
      this.model.interestPurposes = payload.interestPurposes || []
      this.model.features = payload.features || []
      this.model.onlineEventTypes = []
      if (this.autoSubmitSearch) this.submitSearch()
    },
    applyTime() {
      if (this.autoSubmitSearch) this.submitSearch()
    },
    submitSearch() {
      const pageDetector = new PageDetector(this.$route)
      const query = { ...this.searchQueryParams }
      if (Object.keys(query).length === 0) query['exclude-sold-out'] = 1
      if (pageDetector.isSimilarEventsPage) return this.$router.push({ name: 'similar-events', query })
      this.$router.push({ name: 'search', query })
    },
    applyFeature(payload) {
      // If click to feature tag at search modal, not doing somthing
      const features = payload.features || []
      this.model.features = [features.slug]
      // apply feature tag to search by params follow below command
      // if (this.autoSubmitSearch) this.submitSearch()
    }
  }
}
