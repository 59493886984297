const ALLOW_XFRAME_PAGE = 'events-code'

export default defineNuxtRouteMiddleware(to => {
  if (import.meta.client) return
  const event = useRequestEvent()
  event.res.setHeader('Cache-Control', 'no-store')
  event.res.setHeader('Pragma', 'no-cache')
  if (to.name !== ALLOW_XFRAME_PAGE) {
    event.res.setHeader('X-Frame-Options', 'DENY')
  }
})
