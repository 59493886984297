import bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default defineNuxtPlugin(nuxtApp => {
  if (import.meta.env.APP_VERSION_FOR_BUGSNAG) {
    const EnabledReleaseStages = import.meta.env.APP_VERSION_FOR_BUGSNAG ? [import.meta.env.DEPLOY_STAGE] : []
    const bugsnagClient = bugsnag.start({
      appVersion: import.meta.env.APP_VERSION_FOR_BUGSNAG,
      apiKey: import.meta.env.BUGSNAG_CLIENT_KEY,
      releaseStage: import.meta.env.DEPLOY_STAGE,
      enabledReleaseStages: EnabledReleaseStages,
      plugins: [new BugsnagPluginVue()]
    })

    nuxtApp.vueApp.provide('bugsnag', bugsnagClient)
    nuxtApp.provide('bugsnag', bugsnagClient)
  }
})
