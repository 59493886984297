import { timeConverter } from '@/utils/converter'
import {
  findPrefectureByAreaGroupSlug,
  listRegions,
  findAreaGroupByAreaSlug,
  findRegionBySlug,
  findPrefectureBySlug,
  getListPrefectureByRegionSlug,
  findPrefectureByAreaSlug,
  findAreaGroupBySlug,
  findAreaBySlug,
  findRegionByPrefectureSlug
} from '@/models/places'
import BreadCrumbModel from '@/models/breadcrumbs'
import PageDetetor from '@/utils/PageDetector'
import { buildQueryString } from '@/utils/url-action'

const COMMON_BREAD_CRUMBS = {
  root: { label: '街コン・婚活パーティーTOP', path: '/' },
  simplified_root: { label: 'TOP', path: '/' },
  areasTop: { label: '全国', path: '/areas/' },
  userGuide: { label: 'クマに案内をたのむ', path: '/user_guide/' },
  detailSearch: { label: '条件絞込み検索', path: '/search/' },
  features: { label: '街コン・パーティ特集一覧ページ', path: '/features/' },
  simplifiedFeatures: { label: '街コン・パーティ特集一覧', path: '/features/' },
  medium: { label: 'メディア紹介情報', path: '/medium/' },
  informations: { label: 'お知らせ一覧', path: '/information/' },
  market_insights: { label: '調査レポート一覧', path: '/market_insights/' },
  g_questionnaires: { label: 'アンケート一覧', path: '/g_questionnaires/' },
  konkatsuTop: { label: '婚活TOP', path: '/categories/konkatsu/' }
}

// ////////////////////////// EXTEND FUNCTION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const buildAreaPath = (prefecture, areaGroup, area) => {
  const parts = []
  if (prefecture) parts.push(prefecture.slug)
  if (areaGroup) parts.push(areaGroup.slug)
  if (area) parts.push(area.slug)
  return parts.length > 0 ? `/areas/${parts.join('/')}` : '/areas'
}

const buildEventTypePath = options => {
  if (options.pageDetector.isInterestChildPage && options.interest) {
    return `/interests/${options.interest.slug}/`
  }
  if (options.eventType === 'interests') return '/interests/'
  if (options.eventType === 'activity') return `/interests/activity/`
  if (options.activityTheme) return `/interests/activity/${options.activityTheme.slug}/`
  if (options.pageDetector.isFeaturesEventsPage && options.featureTag) return `/features/${options.featureTag.slug}/`
  if (options.eventType) return `/categories/${options.eventType}/`
  return '/'
}

const buildAreaPathBySlugAndType = (areaSlug, areaType) => {
  if (areaType === 'area') {
    const area = findAreaBySlug(areaSlug)
    if (!area) return '/'
    const areaGroup = findAreaGroupByAreaSlug(area.slug)
    const prefecture = findPrefectureByAreaGroupSlug(areaGroup.slug)
    return buildAreaPath(prefecture, areaGroup, area)
  }

  if (areaType === 'areaGroup') {
    const areaGroup = findAreaGroupBySlug(areaSlug)
    if (!areaGroup) return '/'
    const prefecture = findPrefectureByAreaGroupSlug(areaGroup.slug)
    return buildAreaPath(prefecture, areaGroup, null)
  }

  if (areaType === 'prefecture') {
    const prefecture = findPrefectureBySlug(areaSlug)
    if (!prefecture) return '/'
    return buildAreaPath(prefecture, null, null)
  }

  if (areaType === 'region') {
    const region = findRegionBySlug(areaSlug)
    if (!region) return null
    return `/regions/${region.slug}/`
  }

  return '/'
}

const formatBreadCrumbPath = path => path.replace(/\/\/+/gm, '/')

// options: pageDetector, eventType, isGocon, showEventType (to display label name), isDatePage, activityTheme, promoter, organizer, featureTag
const eventAreaCrumb = (prefecture, areaGroup, area, options = {}) => {
  const areaObj = area || areaGroup || prefecture
  const areaName = areaObj.name
  const showEventType = (area && !options.isDatePage) || (!area && options.showEventType)

  if (options.pageDetector.isAreasPageWithPrefecturePromoters || options.pageDetector.isAreasPageWithAreaGroupPromoters) {
    return {
      label: `${areaName}の${options.organizer}主催イベント一覧`,
      path: buildAreaPath(prefecture, areaGroup, area)
    }
  }

  if (options.pageDetector.isAreasPageWithAreaPromoters) {
    const label = showEventType ? `${areaName}の${options.organizer}のイベント一覧` : areaName
    return {
      label,
      path: buildAreaPath(prefecture, areaGroup, area)
    }
  }

  if (!options.eventType) {
    return {
      label: showEventType ? `${areaName}の街コン${options.pageDetector.isSimpleAreaPage ? '・婚活パーティー' : ''}` : areaName,
      path: buildAreaPath(prefecture, areaGroup, area)
    }
  }

  if (options.pageDetector.isInterestChildPage && options.interest) {
    return {
      label: showEventType ? `${areaName}の${BreadCrumbModel.getEventTypeText(options.eventType)}` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/interests/${options.interest.slug}/`
    }
  }

  if (options.eventType === 'interests') {
    return {
      label: showEventType ? `${areaName}の趣味コン` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/interests/`
    }
  }

  if (options.pageDetector.isFeaturesEventsPage && options.featureTag) {
    return {
      label: showEventType ? BreadCrumbModel.getEventTypeText(options.eventType) : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/features/${options.featureTag.slug}/`
    }
  }

  if (options.eventType === 'petit-con' && options.isGocon && area && ['hamamatsu', 'sendai'].includes(area.slug)) {
    return {
      label: showEventType ? `${areaName}の合コン` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/features/gocon/`
    }
  }

  if (
    options.eventType === 'petit-con' &&
    options.isGocon &&
    areaGroup &&
    !area &&
    ['ag0101', 'ag2301', 'ag1401', 'ag2801'].includes(areaGroup.slug)
  ) {
    return {
      label: showEventType ? `${areaName}の合コン` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/features/gocon/`
    }
  }

  if (options.eventType === 'petit-con' && options.isGocon) {
    if (area || areaGroup) return null
    return {
      label: showEventType ? `${areaName}の合コン` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/features/gocon/`
    }
  }

  if (options.eventType === 'activity') {
    return {
      label: showEventType ? `${areaName}の${BreadCrumbModel.getEventTypeText(options.eventType)}` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/interests/activity/`
    }
  }

  if (options.activityTheme) {
    const activityLabel = options.pageDetector.isAreasPageWithAreaInterestsActivityAmuse ? `${areaName}の${options.activityTheme.name}` : areaName
    return {
      label: showEventType ? `${areaName}の${options.activityTheme.name}` : activityLabel,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/interests/activity/${options.activityTheme.slug}/`
    }
  }

  if (options.promoter) {
    const organizerName = options.organizer || ''
    return {
      label: showEventType ? `${areaName}の${organizerName}主催イベント一覧` : areaName,
      path: `${buildAreaPath(prefecture, areaGroup, area)}/promoters/${options.promoter.slug}/`
    }
  }

  return {
    label: showEventType ? `${areaName}の${BreadCrumbModel.getEventTypeText(options.eventType)}` : areaName,
    path: `${buildAreaPath(prefecture, areaGroup, area)}/categories/${options.eventType}/`
  }
}

const areaRankingBreadCrumb = (area, areaType, eventType, showRankingText) => {
  const areaRankingPath = areaType => {
    switch (areaType) {
      case 'prefecture':
        return `/prefecture_rankings/${area.slug}/`
      case 'area_group':
        return `/area_group_rankings/${area.slug}/`
      case 'area':
        return `/area_rankings/${area.slug}/`
    }
  }
  const label = (() => {
    const ranking = 'ランキング'
    const defaultLabel = (() => {
      if (eventType) {
        if (eventType === 'konkatsu') return '婚活パーティー'
        else return `${BreadCrumbModel.getEventTypeText(eventType)}`
      }
      return '街コン・婚活パーティー'
    })()
    return !eventType && areaType === 'prefecture' ? ranking : `${area.name}・${defaultLabel}${ranking}`
  })()
  const path = eventType ? `${areaRankingPath(areaType)}categories/${eventType}/` : areaRankingPath(areaType)

  if (areaType === 'prefecture') {
    const region = findRegionByPrefectureSlug(area.slug)
    return {
      crumb: {
        label,
        path
      },
      parent: {
        page: eventType ? 'region' : 'prefecture',
        options: {
          eventType,
          prefectureSlug: area.slug,
          regionSlug: region.slug
        }
      }
    }
  }

  if (areaType === 'area_group') {
    const prefecture = findPrefectureByAreaGroupSlug(area.slug)
    return {
      crumb: {
        label,
        path
      },
      parent: {
        page: 'prefecture',
        options: {
          eventType,
          prefectureSlug: prefecture.slug
        }
      }
    }
  }
  // const areaGroup = findAreaGroupByAreaSlug(area.slug)
  const prefecture = findPrefectureByAreaSlug(area.slug)
  return {
    crumb: {
      label,
      path
    },
    parent: {
      page: 'prefecture',
      options: {
        eventType,
        prefectureSlug: prefecture.slug
      }
    }
  }
}

// ///////////////////////// MAIN FUNCTIONS \\\\\\\\\\\\\\\\\\\\\\\\\\\\ \\
const BreadCrumbPages = {
  root: options => {
    if (options.pageDetector.isKonkatsuEventsPage) {
      return { crumb: COMMON_BREAD_CRUMBS.simplified_root, parent: null }
    } else if (options.pageDetector.isPartnersPages) {
      return { crumb: COMMON_BREAD_CRUMBS.konkatsuTop, parent: null }
    } else {
      return { crumb: COMMON_BREAD_CRUMBS.root, parent: null }
    }
  },
  areasTop: (_option = {}) => {
    return { crumb: COMMON_BREAD_CRUMBS.areasTop, parent: { page: 'root' } }
  },
  // options: pageDetector, eventTitle, eventCode, eventType, areaSlug, haveInterest, isGocon
  event: options => {
    const crumb = { label: options.eventTitle, path: `/events/${options.eventCode}` }
    const parentOptions = {
      areaSlug: options.areaSlug,
      isGocon: options.isGocon,
      showEventType: true
    }
    if (options.haveInterest) {
      parentOptions['eventType'] = 'interests'
    } else if (options.areaSlug.includes('other')) {
      parentOptions['showEventType'] = false
      parentOptions['eventType'] = options.eventType === 'machicon' ? null : options.eventType
    } else if (options.eventType === 'machicon') {
      parentOptions['isGocon'] = false
    } else {
      parentOptions['eventType'] = options.eventType
    }
    return { crumb, parent: { page: 'area', options: parentOptions } }
  },
  // options: pageDetector, eventTitle, eventCode, eventType, areaSlug, haveInterest, isGocon
  organizerRating: options => {
    return {
      crumb: {
        label: 'ユーザー評価/コメント',
        path: ''
      },
      parent: {
        page: 'event',
        options: options
      }
    }
  },
  // options: pageDetector, areaSlug, eventType, isGocon, showEventType
  area: options => {
    const area = findAreaBySlug(options.areaSlug)
    if (!area) return { crumb: null, parent: null }
    const areaGroup = findAreaGroupByAreaSlug(area.slug)
    const prefecture = findPrefectureByAreaGroupSlug(areaGroup.slug)

    options.isDatePage = options.pageDetector.isDateEventsPage
    const crumb = !options.showEventType ? null : eventAreaCrumb(prefecture, areaGroup, area, options)

    const listAreaGroups = ['札幌', 'さいたま', '横浜', '名古屋', '神戸']
    if (
      (prefecture.slug === 'tokyo' ||
        area.name === areaGroup.name ||
        area.name.includes('その他') ||
        areaGroup.name.includes('全域') ||
        !options.showEventType) &&
      !listAreaGroups.includes(areaGroup.name)
    ) {
      return {
        crumb,
        parent: {
          page: 'prefecture',
          options: {
            ...options,
            prefectureSlug: prefecture.slug,
            showEventType: false
          }
        }
      }
    }
    return {
      crumb,
      parent: {
        page: 'areaGroup',
        options: {
          ...options,
          areaGroupSlug: areaGroup.slug,
          showEventType: false
        }
      }
    }
  },
  // options: pageDetector, areaGroupSlug, eventType, isGocon, showEventType
  areaGroup: options => {
    const areaGroup = findAreaGroupBySlug(options.areaGroupSlug)
    if (!areaGroup) return { crumb: null, parent: null }
    const prefecture = findPrefectureByAreaGroupSlug(areaGroup.slug)
    const crumb = eventAreaCrumb(prefecture, areaGroup, null, options)
    return {
      crumb,
      parent: {
        page: 'prefecture',
        options: {
          ...options,
          prefectureSlug: prefecture.slug,
          eventType: options.eventType,
          showEventType: false,
          isGocon: options.isGocon
        }
      }
    }
  },
  // options: pageDetector, prefectureSlug, eventType, isGocon, showEventType
  prefecture: options => {
    const prefecture = findPrefectureBySlug(options.prefectureSlug)
    if (!prefecture) return { crumb: null, parent: null }
    const crumb = eventAreaCrumb(prefecture, null, null, options)
    if (options.promoter) {
      return {
        crumb,
        parent: { page: 'promoterEvents', options }
      }
    }
    const region = listRegions().find(v => {
      const prefectureSlugs = getListPrefectureByRegionSlug(v.slug).map(v => v.slug)
      return prefectureSlugs.includes(prefecture.slug)
    })
    if (options.pageDetector.isDatesPageWithDayMonthYearAreasFeatures)
      return { crumb, parent: { page: 'region', options: { ...options, regionSlug: region.slug } } }
    if (options.pageDetector.isFeaturesEventsPage) {
      const { label, page, childOptions } = (() => {
        if (options.showEventType) {
          return {
            label: BreadCrumbModel.getEventTypeText(options.eventType),
            page: 'prefecture',
            childOptions: { ...options, showEventType: false }
          }
        }
        return {
          label: prefecture.name,
          page: 'region',
          childOptions: { regionSlug: region.slug }
        }
      })()
      return {
        crumb: { label, path: crumb.path },
        parent: { page, options: childOptions }
      }
    }
    if (
      (options.pageDetector.isPrefectureRankingPage || options.pageDetector.isAreaGroupRankingPage || options.pageDetector.isAreaRankingPage) &&
      !options.eventType
    ) {
      const crumbLabel = options.eventType === 'konkatsu' ? `${prefecture.name}の婚活パーティー` : `${prefecture.name}`
      return {
        crumb: { label: crumbLabel, path: crumb.path },
        parent: { page: 'region', options: { ...options, regionSlug: region.slug } }
      }
    }
    return {
      crumb,
      parent: {
        page: 'region',
        options: { ...options, regionSlug: region.slug }
      }
    }
  },
  // options: pageDetector, regionSlug, eventType, isGocon, showEventType
  region: options => {
    const region = findRegionBySlug(options.regionSlug)
    if (!region) return { crumb: null, parent: null }

    if (options.interest && options.pageDetector.isRegionsPageWithInterestsSportsFutsal) {
      const interest = options.interest
      const parentInterest = { name: interest.parent_name, slug: interest.parent_slug }
      return {
        crumb: { label: region.name, path: `/regions/${region.slug}/interests/${interest.parent_slug}/${interest.slug}` },
        parent: { page: 'interestParent', options: { interest: parentInterest } }
      }
    }

    if (options.pageDetector.isInterestsPageWithActivityAmuse) {
      return {
        crumb: { label: region.name, path: `/regions/${region.slug}/interests/activity/${options.eventType}` },
        parent: {
          page: 'interestActivity',
          options: { eventType: options.eventType, parentEventType: 'activity', activityTheme: options.activityTheme }
        }
      }
    }

    if (options.pageDetector.isInterestChildPage && options.interest) {
      return {
        crumb: { label: region.name, path: `/regions/${region.slug}/interests/${options.interest.slug}/` },
        parent: { page: 'interestChild', options }
      }
    }

    if (options.pageDetector.isFeaturesEventsPage && options.featureTag) {
      const { label, parent } = (() => {
        if (options.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures || options.pageDetector.isDatesPageWithDayMonthYearAreasFeatures) {
          return {
            label: region.name,
            parent: { page: 'root' }
          }
        }
        return {
          label: BreadCrumbModel.getEventTypeText(options.eventType),
          parent: { page: 'region', options: { regionSlug: options.regionSlug } }
        }
      })()
      return {
        crumb: {
          label,
          path: `/regions/${region.slug}/features/${options.featureTag.slug}/`
        },
        parent
      }
    }

    if (options.activityTheme) {
      return {
        crumb: {
          label: region.name,
          path: `/regions/${region.slug}/activity/${options.activityTheme.slug}/`
        },
        parent: { page: 'activity', options }
      }
    }

    if (options.promoter) {
      if (options.pageDetector.isDatesPageWithDayMonthYearRegionsPromoters) {
        return {
          crumb: {
            label: region.name,
            path: `/regions/${region.slug}/`
          },
          parent: { page: 'root' }
        }
      }

      return {
        crumb: {
          label: `${region.name}の${options.organizer}のイベント一覧`,
          path: `/regions/${region.slug}/promoters/${options.promoter.slug}/`
        },
        parent: { page: 'promoterEvents', options }
      }
    }

    if (options.eventType === 'activity' || options.eventType === 'interests') {
      return {
        crumb: { label: region.name, path: `/regions/${region.slug}/interests/` },
        parent: { page: 'eventType', options: { eventType: 'interests' } }
      }
    }

    if (options.eventType) {
      return {
        crumb: {
          label: region.name,
          path: `/regions/${region.slug}/categories/${options.eventType}`
        },
        parent: { page: 'eventType', options }
      }
    }

    if (options.pageDetector.isSimpleRegionPage) {
      return {
        crumb: {
          label: `${region.name}地方の街コン・婚活パーティー`,
          path: `/regions/${region.slug}/`
        },
        parent: { page: 'root' }
      }
    }

    return {
      crumb: {
        label: region.name,
        path: `/regions/${region.slug}/`
      },
      parent: { page: 'root' }
    }
  },
  // options: title, path, parentTitle, parentPath
  staticPage: options => {
    const crumb = { label: options.title, path: options.path }
    if (options.parentPath) {
      return {
        crumb,
        parent: { page: 'staticPage', options: { title: options.parentTitle, path: options.parentPath } }
      }
    }
    return {
      crumb,
      parent: { page: 'root' }
    }
  },
  // options: regionSlug, regionName, eventType, showRankingText
  regionRanking: options => {
    if (!options.regionSlug && !options.regionName) return { crumb: null, parent: null }
    const label = 'ランキング'
    const parent = (() => {
      if (options.eventType) {
        return { page: 'eventType', options: { eventType: options.eventType } }
      } else if (options.isFromRegionRankingPage) {
        return { page: 'root' }
      }
      return { page: 'region', options: { regionSlug: options.regionSlug, isFromRegionRankingPage: true } }
    })()

    return {
      crumb: {
        label,
        path: `/rankings/${options.regionSlug}/`
      },
      parent
    }
  },
  // options: prefectureSlug, eventType, showRankingText
  prefectureRanking: options => {
    const prefecture = findPrefectureBySlug(options.prefectureSlug)
    if (!prefecture) return { crumb: null, parent: null }
    return areaRankingBreadCrumb(prefecture, 'prefecture', options.eventType, options.showRankingText)
  },
  // options: areaGroupSlug, eventType, showRankingText
  areaGroupRanking: options => {
    const areaGroup = findAreaGroupBySlug(options.areaGroupSlug)
    if (!areaGroup) return { crumb: null, parent: null }
    return areaRankingBreadCrumb(areaGroup, 'area_group', options.eventType, options.showRankingText)
  },
  // options: areaSlug, eventType
  areaRanking: options => {
    const area = findAreaBySlug(options.areaSlug)
    if (!area) return { crumb: null, parent: null }
    return areaRankingBreadCrumb(area, 'area', options.eventType, options.showRankingText)
  },
  detailSearch() {
    return {
      crumb: COMMON_BREAD_CRUMBS['detailSearch'],
      parent: { page: 'root' }
    }
  },
  // options: keyword
  searchByKeyword(options) {
    if (!options.keyword) return { crumb: null, parent: null }
    return {
      crumb: { label: `「${options.keyword}」のイベントを含む検索結果`, path: `/?s=${encodeURIComponent(options.keyword)}` },
      parent: { page: 'root' }
    }
  },
  // options: pageTitle
  detailSearchResult: options => {
    return {
      crumb: { label: `${options.pageTitle}の検索結果`, path: `/search/${buildQueryString(options.query)}` },
      parent: { page: 'root' }
    }
  },
  // options: regionSlug, customSearch
  regionsCustomSearch: options => {
    if (!options.customSearch) return { crumb: null, parent: null }
    const region = findRegionBySlug(options.regionSlug)
    if (!region) return { crumb: null, parent: null }

    return {
      crumb: {
        label: options.customSearch.name,
        path: `/regions/${region.slug}/csp/${options.customSearch.slug}/`
      },
      parent: { page: 'customSearch', options }
    }
  },
  // options: prefectureSlug, showEventType, customSearch
  areasCustomSearch: options => {
    if (!options.customSearch) return { crumb: null, parent: null }

    const prefecture = findPrefectureBySlug(options.prefectureSlug)
    if (!prefecture) return { crumb: null, parent: null }

    const region = listRegions().find(v => {
      const prefectureSlugs = getListPrefectureByRegionSlug(v.slug).map(v => v.slug)
      return prefectureSlugs.includes(prefecture.slug)
    })

    const label = `${prefecture.name}の${options.customSearch.name}`
    const parent = { page: 'regionsCustomSearch', options: { ...options, regionSlug: region.slug } }

    return {
      crumb: {
        label,
        path: `/areas/${prefecture.slug}/csp/${options.customSearch.slug}/`
      },
      parent
    }
  },
  // options: areaSlug, customSearch
  areasAreaCustomSearch: options => {
    if (!options.customSearch) return { crumb: null, parent: null }
    const area = findAreaBySlug(options.areaSlug)
    if (!area) return { crumb: null, parent: null }
    const areaGroup = findAreaGroupByAreaSlug(area.slug)
    const prefecture = findPrefectureByAreaGroupSlug(areaGroup.slug)

    return {
      crumb: {
        label: `${area.name}の${options.customSearch.name}`,
        path: `/areas/${prefecture.slug}/${areaGroup.slug}/${area.slug}/csp/${options.customSearch.slug}/`
      },
      parent: {
        page: 'areasCustomSearch',
        options: {
          ...options,
          prefectureSlug: prefecture.slug,
          showEventType: false
        }
      }
    }
  },
  // options: customSearch
  customSearch: options => {
    if (!options.customSearch) return { crumb: null, parent: null }
    const region = findRegionBySlug(options.regionSlug)

    const { label, path } = (() => {
      if (region) {
        return {
          label: region.name,
          path: `/regions/${region.slug}/csp/${options.customSearch.slug}/`
        }
      }
      return {
        label: options.customSearch.name,
        path: `/csp/${options.customSearch.slug}/`
      }
    })()

    return {
      crumb: { label, path },
      parent: { page: 'root' }
    }
  },
  // options: promoter, organizer
  promoterEvents: options => {
    if (!options.promoter || !options.organizer) return { crumb: null, parent: null }
    const parent = options.pageDetector.isDatesPageWithDayMonthYearRegionsPromoters
      ? { page: 'region', options: { ...options, regionSlug: options.areaSlug } }
      : { page: 'root' }
    return {
      crumb: { label: `${options.organizer}のイベント一覧`, path: `/promoters/${options.promoter.slug}/` },
      parent
    }
  },
  // options: date, dateType, eventType, activityTheme, featureTag, interests, areaSlug, areaType (prefecture, region, area_group, area)
  date: options => {
    if (!options.date || !options.dateType) return { crumb: null, parent: null }
    const date = timeConverter(options.date)
    const year = date.year
    const month = `0${date.month}`.slice(-2)
    const day = `0${date.day}`.slice(-2)

    const eventTypeName = () => {
      if (options.interest) return options.interest.name
      if (options.eventType === 'interests' || options.eventType === 'activity_interest') return '趣味コン'
      if (options.activityTheme) return options.activityTheme.name
      if (options.eventType) return BreadCrumbModel.getEventTypeText(options.eventType)
      return '街コン・恋活・婚活パーティー'
    }

    const eventTypePath = buildEventTypePath(options)

    const datePath = () => {
      if (options.dateType === 'day') return `/dates/${year}/${month}/${day}/`
      if (options.dateType === 'month') return `/dates/${year}/${month}/`
      return `/dates/${year}/`
    }

    const dateName = () => {
      if (options.dateType === 'day') return date.fullDate
      if (options.dateType === 'month') return `${year}年${month}月`
      return ''
    }

    const simpleDateName = () => {
      if (options.dateType === 'day') return `${date.day}日`
      if (options.dateType === 'month') return `${date.month}月`
      if (options.dateType === 'year') return `${year}年`
      return ''
    }

    const crumb = () => {
      if (options.areaType === 'area') {
        const area = findAreaBySlug(options.areaSlug)
        if (!area) return { crumb: null, parent: null }
        const label = (() => {
          if (options.pageDetector.isDatesPageWithYearAreaPromoters) {
            return `${area.name}の${options.organizer}主催のイベント一覧`
          } else if (options.pageDetector.isDatesPageWithDayMonthYearAreaPromoters) {
            return `${dateName()}の${area.name}の${options.organizer}のイベント一覧`
          } else if (options.pageDetector.isDatesPageWithDayMonthYearAreasFeatures) {
            return `${simpleDateName()}`
          }
          return `${dateName()}開催の${area.name}の${eventTypeName()}`
        })()
        const areaPath = buildAreaPathBySlugAndType(options.areaSlug, options.areaType)
        return {
          label,
          path: formatBreadCrumbPath(`${datePath()}${areaPath}${eventTypePath}`)
        }
      }
      if (options.areaType === 'areaGroup') {
        const areaGroup = findAreaGroupBySlug(options.areaSlug)
        if (!areaGroup) return { crumb: null, parent: null }
        const areaGroupPath = buildAreaPathBySlugAndType(options.areaSlug, options.areaType)
        return {
          label: `${dateName()}開催の${areaGroup.name}の${eventTypeName()}`,
          path: formatBreadCrumbPath(`${datePath()}${areaGroupPath}${eventTypePath}`)
        }
      }
      if (options.areaType === 'prefecture') {
        const prefecture = findPrefectureBySlug(options.areaSlug)
        if (!prefecture) return { crumb: null, parent: null }
        const label = options.pageDetector.isDatesPageWithYearPrefecturePromoters
          ? `${prefecture.name}の${options.organizer}主催イベント一覧`
          : `${dateName()}開催の${prefecture.name}の${eventTypeName()}`
        const prefPath = buildAreaPathBySlugAndType(options.areaSlug, options.areaType)
        return {
          label,
          path: formatBreadCrumbPath(`${datePath()}${prefPath}${eventTypePath}`)
        }
      }
      if (options.areaType === 'region') {
        const region = findRegionBySlug(options.areaSlug)
        if (!region) return { crumb: null, parent: null }
        const regionPath = `/regions/${region.slug}/`
        if (options.pageDetector.isDatesPageWithDayMonthYearRegionsPromoters) {
          return {
            label: `${dateName()}の${region.name}地方の${options.organizer}のイベント一覧`,
            path: formatBreadCrumbPath(`${datePath()}${regionPath}/promoters/${options.promoter.slug}/`)
          }
        }
        const label = options.pageDetector.isDatesPageWithYearRegionsPromoters
          ? `${region.name}地方の${options.organizer}主催のイベント一覧`
          : `${simpleDateName()}`
        return {
          label,
          path: formatBreadCrumbPath(`${datePath()}${regionPath}${eventTypePath}`)
        }
      }
      if (options.promoter) {
        return {
          label: `${dateName()}の${options.organizer}のイベント一覧`,
          path: formatBreadCrumbPath(`${datePath()}/promoters/${options.promoter.slug}/`)
        }
      }
      return {
        label: `${simpleDateName()}`,
        path: formatBreadCrumbPath(`${datePath()}${eventTypePath}`)
      }
    }

    if (options.dateType === 'day') {
      return {
        crumb: crumb(),
        parent: { page: 'month', options }
      }
    }

    if (options.dateType === 'month') {
      return {
        crumb: crumb(),
        parent: { page: 'year', options }
      }
    }

    return { crumb: crumb(), parent: { page: 'root' } }
  },
  // options: date, eventType, activityTheme, featureTag, areaSlug, areaType (prefecture, region, area_group, area)
  year: options => {
    if (!options.date) return { crumb: null, parent: null }
    const year = timeConverter(options.date).year
    const eventTypePath = buildEventTypePath(options)
    const areaPath = buildAreaPathBySlugAndType(options.areaSlug, options.areaType)
    const crumb = { label: `${year}年`, path: formatBreadCrumbPath(`/dates/${year}${areaPath}${eventTypePath}/`) }

    if (options.pageDetector.isPromoterEventsPage) {
      if (options.pageDetector.isDatesPageWithDayMonthYearAreaPromoters) {
        return {
          crumb,
          parent: { page: 'area', options }
        }
      }
      return {
        crumb,
        parent: { page: 'promoterEvents', options }
      }
    }

    if (options.areaType === 'area') {
      options.showEventType = true
      const parentPage = options.pageDetector.isDatesPageWithDayMonthYearAreasFeatures ? 'eventType' : 'area'
      return {
        crumb,
        parent: {
          page: parentPage,
          options
        }
      }
    }

    if (options.areaType === 'region' && options.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures) {
      options.showEventType = true
      options[`${options.areaType}Slug`] = options.areaSlug
      return {
        crumb,
        parent: {
          page: 'eventType',
          options
        }
      }
    }

    if (options.areaType === 'areaGroup' || options.areaType === 'prefecture' || options.areaType === 'region') {
      options[`${options.areaType}Slug`] = options.areaSlug
      options.showEventType = false
      return {
        crumb,
        parent: {
          page: options.areaType,
          options
        }
      }
    }

    if (options.pageDetector.isInterestChildPage && options.interest) {
      return {
        crumb,
        parent: { page: 'interestChild', options }
      }
    }

    if (options.activityTheme) {
      return {
        crumb,
        parent: { page: 'activityTheme', options }
      }
    }

    if (options.eventType) {
      return {
        crumb,
        parent: { page: 'eventType', options }
      }
    }
    return { crumb, parent: { page: 'root' } }
  },
  // options: date, eventType, activityTheme, featureTag, areaSlug, areaType (prefecture, region, area_group, area)
  month: options => {
    if (!options.date) return { crumb: null, parent: null }
    const year = timeConverter(options.date).year
    const month = timeConverter(options.date).month
    const eventTypePath = buildEventTypePath(options)
    const areaPath = buildAreaPathBySlugAndType(options.areaSlug, options.areaType)
    const crumb = { label: `${month}月`, path: formatBreadCrumbPath(`/dates/${year}/${month}/${areaPath}${eventTypePath}/`) }

    return { crumb, parent: { page: 'year', options } }
  },
  // options: prefectureSlug
  fixedEvent: options => {
    const prefecture = findPrefectureBySlug(options.prefectureSlug)
    const label = '開催確定'
    const path = prefecture ? `/fixed/${prefecture.slug}/` : '/fixed/'
    return {
      crumb: { label, path },
      parent: { page: 'prefecture', options }
    }
  },
  // options: interest
  interestChild: options => {
    if (!options.interest) return { crumb: null, parent: null }
    if (options.interest.parent_slug) {
      const crumb = {
        label: `${BreadCrumbModel.getInterestText(options.interest.slug)}`,
        path: `/interests/${options.interest.parent_slug}/${options.interest.slug}/`
      }
      const parentInterest = { name: options.interest.parent_name, slug: options.interest.parent_slug }
      return { crumb, parent: { page: 'interestParent', options: { interest: parentInterest } } }
    }
    return {
      crumb: { label: `${BreadCrumbModel.getInterestText(options.interest.slug)}`, path: `/interests/${options.interest.slug}/` },
      parent: { page: 'eventType', options: { eventType: 'interests' } }
    }
  },
  // options: interest
  interestParent: options => {
    if (!options.interest) return { crumb: null, parent: null }
    const label = BreadCrumbModel.getInterestText(options.interest.slug)
    return {
      crumb: {
        label: options.pageDetector.isRegionsPageWithInterestsSportsFutsal ? label : label.replace('イベント', ''),
        path: `/interests/${options.interest.slug}/`
      },
      parent: {
        page: 'eventType',
        options: { eventType: 'interests' }
      }
    }
  },
  // options: eventType, featureTag, isGocon
  eventType: options => {
    if (!options.eventType) return { crumb: null, parent: null }
    if (options.pageDetector.isFeaturesEventsPage && options.featureTag) {
      const crumb = { label: BreadCrumbModel.getEventTypeText(options.eventType), path: `/features/${options.featureTag.slug}/` }
      const parent = (() => {
        if (options.pageDetector.isDatesPageWithDayMonthYearFeatures) {
          return { page: 'features', options: { ...options, simple: true } }
        } else if (options.pageDetector.isDatesPageWithDayMonthYearAreasFeatures) {
          return { page: 'area', options }
        } else if (options.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures) {
          return { page: 'region', options }
        }
        return { page: 'features', options }
      })()
      return {
        crumb,
        parent
      }
    }

    const crumb = () => {
      if (options.eventType === 'interests' || options.eventType === 'activity_interest') {
        if (options.regionSlug) {
          const region = findRegionBySlug(options.regionSlug)
          return { label: region.name, path: `/regions/${region.slug}` }
        }
        return { label: '趣味コン', path: '/interests/' }
      }
      if (options.eventType === 'petit-con' && options.isGocon) return { label: '合コン', path: '/features/gocon/' }
      if (options.eventType === 'activity') return { label: BreadCrumbModel.getEventTypeText('activity'), path: '/interests/activity/' }
      return { label: BreadCrumbModel.getEventTypeText(options.eventType), path: `/categories/${options.eventType}/` }
    }
    return { crumb: crumb(), parent: { page: 'root' } }
  },
  interestActivity: options => {
    if (!options.eventType) return { crumb: null, parent: null }
    if (options.pageDetector.isInterestsPageWithActivityAmuse) {
      return {
        crumb: { label: `${options.activityTheme.name}・出会い`, path: `/interests/${options.parentEventType}/${options.eventType}` },
        parent: { page: 'eventType', options: { eventType: options.parentEventType } }
      }
    }
  },
  // options: simple
  features: options => {
    const crumb = options.simple ? COMMON_BREAD_CRUMBS['simplifiedFeatures'] : COMMON_BREAD_CRUMBS['features']
    return {
      crumb,
      parent: { page: 'root' }
    }
  },
  // options: activityTheme
  activityTheme: options => {
    const parentInterest = { name: options.activityTheme.parent_name, slug: options.activityTheme.parent_slug }
    return {
      crumb: { label: `${options.activityTheme.name}・出会い`, path: `/interests/activity/${options.activityTheme.slug}/` },
      parent: { page: 'eventType', options: { eventType: parentInterest } }
    }
  },
  medium() {
    return {
      crumb: COMMON_BREAD_CRUMBS['medium'],
      parent: { page: 'root' }
    }
  },
  informations() {
    return {
      crumb: COMMON_BREAD_CRUMBS['informations'],
      parent: { page: 'root' }
    }
  },
  market_insights() {
    return {
      crumb: COMMON_BREAD_CRUMBS['market_insights'],
      parent: { page: 'root' }
    }
  },
  g_questionnaires() {
    return {
      crumb: COMMON_BREAD_CRUMBS['g_questionnaires'],
      parent: { page: 'root' }
    }
  },
  organizerReview: _option => {
    return {
      crumb: { label: '主催者一覧', path: '/review' },
      parent: { page: 'root' }
    }
  },
  // options: organizer
  organizerReviewDetail: options => {
    if (!options.organizer) return { crumb: null, parent: null }
    return {
      crumb: { label: `口コミ・評判 > ${options.organizer.name}`, path: '' },
      parent: { page: 'organizerReview' }
    }
  },
  // options: information
  informationDetail: options => {
    if (!options.information) return { crumb: null, parent: null }
    return {
      crumb: { label: options.information.title, path: `/information/${options.information.id}/` },
      parent: { page: 'informations' }
    }
  },
  // options: market_insight
  market_insightDetail: options => {
    if (!options.market_insight) return { crumb: null, parent: null }
    return {
      crumb: { label: options.market_insight.title, path: `/market_insights/${options.market_insight.id}/` },
      parent: { page: 'market_insights' }
    }
  },
  // options: label
  default: options => {
    if (!options.label) return { crumb: null, parent: null }
    return {
      crumb: { label: options.label },
      parent: { page: 'root' }
    }
  },
  error404: () => {
    return {
      crumb: { label: '404' },
      parent: { page: 'root' }
    }
  }
}

export default class Breadcrumbs {
  constructor(currentRoute) {
    this.pageDetector = new PageDetetor(currentRoute)
    this.breadCrumbs = []
  }

  generate(page, opt) {
    const options = { ...opt, pageDetector: this.pageDetector }
    if (!Object.keys(BreadCrumbPages).includes(page)) return this
    const { crumb, parent } = BreadCrumbPages[page](options)
    if (crumb) this.breadCrumbs.unshift(crumb)
    if (parent) this.generate(parent.page, parent.options || {})
    return this
  }

  get result() {
    return this.breadCrumbs
  }
}
