import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faAngleRight as solid_faAngleRight,
  faAngleDoubleRight as solid_faAngleDoubleRight,
  faAngleLeft as solid_faAngleLeft,
  faAngleDoubleLeft as solid_faAngleDoubleLeft,
  faBullhorn as solid_faBullhorn,
  faArrowCircleLeft as solid_faArrowCircleLeft,
  faArrowCircleRight as solid_faArrowCircleRight,
  faArrowRight as solid_faArrowRight,
  faSearch as solid_faSearch,
  faCaretUp as solid_faCaretUp,
  faCaretRight as solid_faCaretRight,
  faCaretDown as solid_faCaretDown,
  faComments as solid_faComments,
  faComment as solid_faComment,
  faUserCircle as solid_faUserCircle,
  faPencilAlt as solid_faPencilAlt,
  faHeart as solid_faHeart,
  faCopy as solid_faCopy,
  faChevronCircleRight as solid_faChevronCircleRight,
  faChevronCircleLeft as solid_faChevronCircleLeft,
  faMapMarkerAlt as solid_faMapMarkerAlt,
  faTicketAlt as solid_faTicketAlt,
  faExclamation as solid_faExclamation,
  faKey as solid_faKey,
  faBars as solid_faBars,
  faChevronLeft as solid_faChevronLeft,
  faChevronRight as solid_faChevronRight,
  faChevronDown as solid_faChevronDown,
  faChevronUp as solid_faChevronUp,
  faTimes as solid_faTimes,
  faEnvelope as solid_faEnvelope,
  faExclamationTriangle as solid_faExclamationTriangle,
  faInfoCircle as solid_faInfoCircle,
  faGamepad as solid_faGamepad,
  faAngleUp as solid_faAngleUp,
  faAngleDown as solid_faAngleDown,
  faCircle as solid_faCircle,
  faStar as solid_faStar,
  faShare as solid_faShare,
  faStarHalfAlt as solid_faStarHalfAlt,
  faQuestionCircle as solid_faQuestionCircle,
  faSearchPlus as solid_faSearchPlus,
  faTrophy as solid_faTrophy,
  faUser as solid_faUser,
  faUserFriends as solid_faUserFriends,
  faSpinner as solid_faSpinner,
  faSortDown as solid_faSortDown,
  faSortAmountDown as solid_faSortAmountDown,
  faGem as solid_faGem,
  faBookmark as solid_faBookmark,
  faSync as solid_faSync,
  faCheck as solid_faCheck,
  faGlassMartini as solid_faGlassMartini,
  faBan as solid_faBan,
  faGift as solid_faGift,
  faHandPointRight as solid_faHandPointRight,
  faUmbrella as solid_faUmbrella,
  faCalendarAlt as solid_faCalendarAlt,
  faMale as solid_faMale,
  faFemale as solid_faFemale,
  faPlusSquare as solid_faPlusSquare,
  faBaseballBall as solid_faBaseballBall,
  faHeadphones as solid_faHeadphones,
  faBook as solid_faBook,
  faFutbol as solid_faFutbol,
  faMusic as solid_faMusic,
  faImage as solid_faImage,
  faSubway as solid_faSubway,
  faMicrophone as solid_faMicrophone,
  faCamera as solid_faCamera,
  faChessRook as solid_faChessRook,
  faWalking as solid_faWalking,
  faHome as solid_faHome,
  faGlassCheers as solid_faGlassCheers,
  faUsers as solid_faUsers,
  faHandHoldingHeart as solid_faHandHoldingHeart
} from '@fortawesome/free-solid-svg-icons'
library.add(
  solid_faAngleRight,
  solid_faAngleDoubleRight,
  solid_faAngleLeft,
  solid_faAngleDoubleLeft,
  solid_faBullhorn,
  solid_faArrowCircleLeft,
  solid_faArrowCircleRight,
  solid_faArrowRight,
  solid_faSearch,
  solid_faCaretUp,
  solid_faCaretRight,
  solid_faCaretDown,
  solid_faComments,
  solid_faComment,
  solid_faUserCircle,
  solid_faPencilAlt,
  solid_faHeart,
  solid_faCopy,
  solid_faChevronCircleRight,
  solid_faChevronCircleLeft,
  solid_faMapMarkerAlt,
  solid_faTicketAlt,
  solid_faExclamation,
  solid_faKey,
  solid_faBars,
  solid_faChevronLeft,
  solid_faChevronRight,
  solid_faChevronDown,
  solid_faChevronUp,
  solid_faTimes,
  solid_faEnvelope,
  solid_faExclamationTriangle,
  solid_faInfoCircle,
  solid_faGamepad,
  solid_faAngleUp,
  solid_faAngleDown,
  solid_faCircle,
  solid_faStar,
  solid_faShare,
  solid_faStarHalfAlt,
  solid_faQuestionCircle,
  solid_faSearchPlus,
  solid_faTrophy,
  solid_faUser,
  solid_faUserFriends,
  solid_faSpinner,
  solid_faSortDown,
  solid_faSortAmountDown,
  solid_faGem,
  solid_faBookmark,
  solid_faSync,
  solid_faCheck,
  solid_faGlassMartini,
  solid_faBan,
  solid_faGift,
  solid_faHandPointRight,
  solid_faUmbrella,
  solid_faCalendarAlt,
  solid_faMale,
  solid_faFemale,
  solid_faPlusSquare,
  solid_faBaseballBall,
  solid_faHeadphones,
  solid_faBook,
  solid_faFutbol,
  solid_faMusic,
  solid_faImage,
  solid_faSubway,
  solid_faMicrophone,
  solid_faCamera,
  solid_faChessRook,
  solid_faWalking,
  solid_faHome,
  solid_faGlassCheers,
  solid_faUsers,
  solid_faHandHoldingHeart
)

import {
  faGem as regular_faGem,
  faCalendarAlt as regular_faCalendarAlt,
  faFileAlt as regular_faFileAlt,
  faNewspaper as regular_faNewspaper,
  faStar as regular_faStar,
  faCircle as regular_faCircle,
  faComment as regular_faComment,
  faCommentAlt as regular_faCommentAlt,
  faCalendarTimes as regular_faCalendarTimes,
  faLightbulb as regular_faLightbulb,
  faBell as regular_faBell,
  faEnvelope as regular_faEnvelope,
  faListAlt as regular_faListAlt,
  faClock as regular_faClock,
  faHandPointRight as regular_faHandPointRight,
  faSmile as regular_faSmile,
  faFrown as regular_faFrown
} from '@fortawesome/free-regular-svg-icons'
library.add(
  regular_faGem,
  regular_faCalendarAlt,
  regular_faFileAlt,
  regular_faNewspaper,
  regular_faStar,
  regular_faCircle,
  regular_faComment,
  regular_faCommentAlt,
  regular_faCalendarTimes,
  regular_faLightbulb,
  regular_faBell,
  regular_faEnvelope,
  regular_faListAlt,
  regular_faClock,
  regular_faHandPointRight,
  regular_faSmile,
  regular_faFrown
)

import {
  faFacebook as brand_faFacebook,
  faFacebookSquare as brand_faFacebookSquare,
  faTwitterSquare as brand_faTwitterSquare,
  faLine as brand_faLine
} from '@fortawesome/free-brands-svg-icons'
library.add(brand_faFacebook, brand_faFacebookSquare, brand_faTwitterSquare, brand_faLine)

config.autoAddCss = false

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {})
})
