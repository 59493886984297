import * as apiCore from './api-core'
import * as apiExtend from './api-extend'
import * as stateHandle from './state-handle'
import * as serverInit from './server-init'

export default {
  ...apiCore,
  ...apiExtend,
  ...stateHandle,
  ...serverInit
}
