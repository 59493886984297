// State must be a function to avoiding shared object
export default () => ({
  // device detect
  // @TODO device関連のstateが多すぎる、リリース後に整理する
  pc: null,
  sp: null,
  tablet: null,
  ios: null,
  android: null,
  native_app: null,
  is_webp_support_ua: null,
  ab_test_group: 0,
  android_app_version_over: null,
  iphone_x_series: null,
  app_version: null,
  window: {
    inner_width: 0,
    scroll_top: 0,
    old_scroll_top: 0
  },
  modal: {
    name: null,
    payload: null
  },
  flash_message: null,
  auth_info: null,
  my_profile: null,
  user_firebase_id: null,
  has_new_message: null,
  count_message: null,
  cl_users_recommend: {
    has_couplink_account: null,
    has_recommend_user: false,
    countdown_date_time: null
  },
  bookmark_events: null,
  static_contents: {},
  search_page: {
    path: null,
    events: [],
    ranking_events: [],
    total_count: null,
    count_from: null,
    has_event_dates: [],
    total_event_online_count: null,
    total_event_offline_count: null,
    pr_events: null,
    on_online_event_tab: null,
    base_event: null,
    top_banners: [],
    search_params: {},
    search_conditions: null,
    page_details: null,
    ads: null,
    result_updated_at: null,
    page_details_updated_at: null,
    ads_updated_at: null,
    ranking_events_updated_at: null,
    subareas: [],
    subareas_updated_at: null,
    savedPosition: 0
  },
  layout_data: {
    banner_header: {
      last_updated_at: null,
      data: null
    },
    calendar: null,
    side_contents: null,
    footer: null,
    ads: null // for 404 page
  },
  error_code: null, // for error page
  ssr_cookie: null,
  is_search_page: false,
  criteo_gtm_data: {},
  previous_page_path: null,
  previous_page_before_mjcl_page: null,
  previous_path_to_top_search_mj_page: null,
  is_tab_changed: false,
  dating_event_data: null, // cache for dating event
  dating_event_filter: {},
  is_to_search_page_from_event_page: false,
  event_detail_to_purchase: false,
  event_detail_browser_back_count: 0,
  event_search_modal: null
})
