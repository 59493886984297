export const PREFECTURES = [
  {
    id: 1,
    name: '北海道',
    slug: 'hokkaido',
    region_slug: 'hokkaido',
    marriage_site_url: '/kekkon/spots/pr_1'
  },
  {
    id: 4,
    name: '宮城県',
    slug: 'miyagi',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_4'
  },
  {
    id: 6,
    name: '山形県',
    slug: 'yamagata',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_6'
  },
  {
    id: 3,
    name: '岩手県',
    slug: 'iwate',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_3'
  },
  {
    id: 7,
    name: '福島県',
    slug: 'fukushima',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_7'
  },
  {
    id: 5,
    name: '秋田県',
    slug: 'akita',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_5'
  },
  {
    id: 2,
    name: '青森県',
    slug: 'aomori',
    region_slug: 'tohoku',
    marriage_site_url: '/kekkon/spots/pr_2'
  },
  {
    id: 13,
    name: '東京都',
    slug: 'tokyo',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_13'
  },
  {
    id: 14,
    name: '神奈川県',
    slug: 'kanagawa',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_14'
  },
  {
    id: 12,
    name: '千葉県',
    slug: 'chiba',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_12'
  },
  {
    id: 11,
    name: '埼玉県',
    slug: 'saitama',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_11'
  },
  {
    id: 10,
    name: '群馬県',
    slug: 'gunma',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_10'
  },
  {
    id: 9,
    name: '栃木県',
    slug: 'tochigi',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_9'
  },
  {
    id: 8,
    name: '茨城県',
    slug: 'ibaraki',
    region_slug: 'kanto',
    marriage_site_url: '/kekkon/spots/pr_8'
  },
  {
    id: 16,
    name: '富山県',
    slug: 'toyama',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_16'
  },
  {
    id: 19,
    name: '山梨県',
    slug: 'yamanashi',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_19'
  },
  {
    id: 15,
    name: '新潟県',
    slug: 'niigata',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_15'
  },
  {
    id: 17,
    name: '石川県',
    slug: 'ishikawa',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_17'
  },
  {
    id: 18,
    name: '福井県',
    slug: 'fukui',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_18'
  },
  {
    id: 20,
    name: '長野県',
    slug: 'nagano',
    region_slug: 'hokuriku',
    marriage_site_url: '/kekkon/spots/pr_20'
  },
  {
    id: 23,
    name: '愛知県',
    slug: 'aichi',
    region_slug: 'tokai',
    marriage_site_url: '/kekkon/spots/pr_23'
  },
  {
    id: 22,
    name: '静岡県',
    slug: 'shizuoka',
    region_slug: 'tokai',
    marriage_site_url: '/kekkon/spots/pr_22'
  },
  {
    id: 24,
    name: '三重県',
    slug: 'mie',
    region_slug: 'tokai',
    marriage_site_url: '/kekkon/spots/pr_24'
  },
  {
    id: 21,
    name: '岐阜県',
    slug: 'gifu',
    region_slug: 'tokai',
    marriage_site_url: '/kekkon/spots/pr_21'
  },
  {
    id: 27,
    name: '大阪府',
    slug: 'osaka',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_27'
  },
  {
    id: 28,
    name: '兵庫県',
    slug: 'hyogo',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_28'
  },
  {
    id: 26,
    name: '京都府',
    slug: 'kyoto',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_26'
  },
  {
    id: 30,
    name: '和歌山県',
    slug: 'wakayama',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_30'
  },
  {
    id: 29,
    name: '奈良県',
    slug: 'nara',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_29'
  },
  {
    id: 25,
    name: '滋賀県',
    slug: 'shiga',
    region_slug: 'kinki',
    marriage_site_url: '/kekkon/spots/pr_25'
  },
  {
    id: 35,
    name: '山口県',
    slug: 'yamaguchi',
    region_slug: 'chugoku',
    marriage_site_url: '/kekkon/spots/pr_35'
  },
  {
    id: 33,
    name: '岡山県',
    slug: 'okayama',
    region_slug: 'chugoku',
    marriage_site_url: '/kekkon/spots/pr_33'
  },
  {
    id: 32,
    name: '島根県',
    slug: 'shimane',
    region_slug: 'chugoku',
    marriage_site_url: '/kekkon/spots/pr_32'
  },
  {
    id: 34,
    name: '広島県',
    slug: 'hiroshima',
    region_slug: 'chugoku',
    marriage_site_url: '/kekkon/spots/pr_34'
  },
  {
    id: 31,
    name: '鳥取県',
    slug: 'tottori',
    region_slug: 'chugoku',
    marriage_site_url: '/kekkon/spots/pr_31'
  },
  {
    id: 36,
    name: '徳島県',
    slug: 'tokushima',
    region_slug: 'shikoku',
    marriage_site_url: '/kekkon/spots/pr_36'
  },
  {
    id: 38,
    name: '愛媛県',
    slug: 'ehime',
    region_slug: 'shikoku',
    marriage_site_url: '/kekkon/spots/pr_38'
  },
  {
    id: 37,
    name: '香川県',
    slug: 'kagawa',
    region_slug: 'shikoku',
    marriage_site_url: '/kekkon/spots/pr_37'
  },
  {
    id: 39,
    name: '高知県',
    slug: 'kochi',
    region_slug: 'shikoku',
    marriage_site_url: '/kekkon/spots/pr_39'
  },
  {
    id: 40,
    name: '福岡県',
    slug: 'fukuoka',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_40'
  },
  {
    id: 42,
    name: '長崎県',
    slug: 'nagasaki',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_42'
  },
  {
    id: 41,
    name: '佐賀県',
    slug: 'saga',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_41'
  },
  {
    id: 44,
    name: '大分県',
    slug: 'oita',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_44'
  },
  {
    id: 45,
    name: '宮崎県',
    slug: 'miyazaki',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_45'
  },
  {
    id: 43,
    name: '熊本県',
    slug: 'kumamoto',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_43'
  },
  {
    id: 46,
    name: '鹿児島県',
    slug: 'kagoshima',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_46'
  },
  {
    id: 47,
    name: '沖縄県',
    slug: 'okinawa',
    region_slug: 'kyushu',
    marriage_site_url: '/kekkon/spots/pr_47'
  },
  {
    id: 99,
    name: 'その他（海外等）',
    slug: 'other',
    region_slug: 'other',
    marriage_site_url: '/kekkon/spots/pr_99'
  }
]
