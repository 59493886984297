import { PREFECTURES } from '@/config/data/prefectures'
export const listPrefectures = () => {
  return PREFECTURES
}

export const findPrefectureBySlug = slug => {
  return PREFECTURES.find(v => v.slug === slug)
}

export const getListPrefectureByRegionSlug = slug => {
  return PREFECTURES.filter(v => v.region_slug === slug)
}
