// Formatter: format data type

// format month/date
// Add 0 if less than 10
export const formatMonthDate = input => {
  return `0${input}`.slice(-2)
}

export const formatDateString = dateStr => {
  if (!dateStr) return ''
  return dateStr.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
}
